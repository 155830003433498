import Geocode from 'react-geocode'

class GeocodeApi {
  constructor() {
    const DEFAULT_API_KEY = 'AIzaSyBPUou4nyLbEM6NaC0iNW7rFAuK6rRxonE'
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      : DEFAULT_API_KEY
    Geocode.setApiKey(apiKey)
  }

  getCoordinatesFromAddress = async (address) => {
    return new Promise((resolve, reject) => {
      Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location
          resolve([lng, lat])
        },
        (error) => {
          reject(error)
          console.error(error)
        }
      )
    })
  }

  getAddressFromCoordinates = async (lat, lng) => {
    return new Promise((resolve, reject) => {
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0]
          resolve(address)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}

export const GeocodeApiInstance = new GeocodeApi()
