import 'dotenv/config'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'
// Firebase
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config'
import { firebaseConfig } from './firebaseConfig'

import App from './App'
import * as serviceWorker from './serviceWorker'
import './styles/index.scss'
import store from './store/store'
import ThemeProvider from './submodules/naoo-web-components/Shared/providers/themesProvider'
import { RemoteConfigContext } from './app/shared/contexts/remote-config.context'

require('dotenv').config()

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const remoteConfig = getRemoteConfig(app)

const root = createRoot(document.getElementById('root'))

const googleClientId =
  process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ??
  '576519020470-npc9v2u49d8ku0bqhlfjdbkqk5ggoq6a.apps.googleusercontent.com'

const render = () => {
  root.render(
    <GoogleOAuthProvider clientId={googleClientId}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider>
            <RemoteConfigContext.Provider value={remoteConfig}>
              <App analytics={analytics} />
            </RemoteConfigContext.Provider>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  )
}

fetchAndActivate(remoteConfig).then(render).catch(console.error)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
