import React from 'react'
import classes from './PageTitle.module.scss'

const PageTitle = ({ title, subtitle }) => {
  return (
    <div className={classes.Container}>
      <span className={classes.Title}>{title}</span>
      {subtitle && <span className={classes.SubTitle}>{subtitle}</span>}
    </div>
  )
}

export default PageTitle
