import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {ErrorsBlock, USER_DATA} from "../SignUp";
import {Button} from "../../../Components/Button/Buttons";
import Input from "../../../Components/Input/Input";
import ApiClient from "../../../Api/client";
import Eye from '../../../Shared/UI/assets/Eye.svg'
import EyeOff from '../../../Shared/UI/assets/Eye_off.svg'
import CircleCheckBoxBlack from '../../../Shared/UI/assets/circleCheckBoxBlack.svg'
import CircleCheckBoxGreen from '../../../Shared/UI/assets/circleCheckBoxGreen.svg'
import classes from '../../Auth.module.scss'
import signUpClasses from '../SingUp.module.scss'
import {ROUTES} from "../../../Shared/constants";
import {yupResolver} from "@hookform/resolvers/yup";

const Step3 = ({data, setStep, setData, timer}) => {
    const navigate = useNavigate()
    const api = new ApiClient()
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [signUpError, setSignUpError] = useState({})
    const schema = yup.object().shape({
        password: yup.string().required('Password is a required field').min(8, 'Password must be exactly 8 characters'),
    });

    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema)
    });
    console.log(data)
    const attributeList = []
    attributeList.push({
        Name: 'name',
        Value: data.firstName,
    })
    attributeList.push({
        Name: 'family_name',
        Value: data.lastName,
    })
    if (data.phone) {
        attributeList.push({
            Name: 'phone_number',
            Value: data.phone,
        })
    }
    if (data.email) {
        attributeList.push({
            Name: 'email',
            Value: data.email,
        })
    }
    console.log(attributeList)
    const onSubmit = (formData) => {
        setData((prevState) => {
            return {
                ...prevState,
                ...formData
            }
        })

        api.signUP(data.userId, formData.password, attributeList,
            () => {
                localStorage.setItem(USER_DATA, JSON.stringify({
                    ...data,
                    ...formData
                }))
                timer.start()
                setStep(4)
            },
            (error) => {
                setSignUpError({message: `Email ${data.email} is already in use. Please log in`})
                setTimeout(() => {
                    navigate(ROUTES.AUTH.AUTH, {
                        state: {
                            isRedirected: true,
                            email: data.email
                        }
                    })
                }, 5000)
            })
    }

    const watchPassword = watch('password')

    return (
        <>
            <div className={classes.AuthForm}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <span className={classes.FormTitle}>Choose your password</span>
                    <div className={signUpClasses.InputContainer}>
                        <Input
                            register={register('password')}
                            type={isShowPassword ? 'text' : 'password'}
                            label={'Password'}
                            placeholder={'Password'}
                            error={errors.password}
                            styles={{paddingRight: 50}}
                        />
                        <img className={signUpClasses.PasswordEyeIcon} src={isShowPassword ? EyeOff : Eye} alt=""
                             onClick={() => {
                                 setIsShowPassword(!isShowPassword)
                             }}/>
                    </div>
                    <div className={signUpClasses.PasswordInfoContainer}>
                        <img src={watchPassword?.length >= 8 ? CircleCheckBoxGreen : CircleCheckBoxBlack} alt=""/>
                        <span>At least 8 characters</span>
                    </div>

                    <Button className={signUpClasses.Button} disabled={errors.password}>Continue</Button>
                </form>
                <span className={signUpClasses.Text}>Already have an account? <Link to={'/auth'}>Log in</Link></span>
            </div>
            <ErrorsBlock formErrors={errors} error={signUpError}/>
        </>
    );
};

export default Step3;
