import React from 'react';
import {useDispatch} from "react-redux";
import classes from "../CreateBusines.module.scss";
import {setCover} from "../../../../store/reducers/businessSlice";
import {CREATE_BUSINESS_STEP_NAMES} from "../CreateBusiness";
import {withAnalytics} from "../../../shared/utility/withAnalytics";
import {BackButton, Button} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import Dropzone from "../../../../submodules/naoo-web-components/Components/Dropzone/Dropzone";

const CoverStep = ({cover, setStep}) => {
    const dispatch = useDispatch()

    const handleDropZoneChange = (files) => {
        const imgUrl = URL.createObjectURL(files[0])
        dispatch(setCover(imgUrl))
    }

    const handlePreviousStepButtonClick = () => {
        setStep(CREATE_BUSINESS_STEP_NAMES.LOGO)
    }

    const handleContinueButtonClick = withAnalytics(
        'dashboards.business.create_business.cover_image_page.continue_btn_clicked',
        () => {
            setStep(CREATE_BUSINESS_STEP_NAMES.GALLERY)
        })

    return (
        <>
            <p className={classes.Subtitle}>Let’s add a cover image or video to your business page.</p>
            <form className={classes.BusinessForm}>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom:32}}>
                    <Dropzone
                        media={cover}
                        onChange={handleDropZoneChange}
                        className={classes.CoverDropzoneSize}
                    />
                </div>
                <div className={classes.ButtonsContainer}>
                    <BackButton
                        className={classes.BackButton}
                        onClick={handlePreviousStepButtonClick}
                    >
                        Back to Logo
                    </BackButton>
                    <Button
                        className={classes.Button}
                        disabled={cover?.length !== 0 ? '' : "disabled"}
                        onClick={handleContinueButtonClick}
                    >
                        Continue
                    </Button>
                </div>
            </form>
        </>
    );
};

export default CoverStep;
