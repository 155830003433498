import React from 'react'

import classes from './OfferStepsOverview.module.css'

import StepIndicator from './OfferStepIndicator'
import Separator from './OfferStepsOverviewSeparator'

const OfferStepsOverview = (props) => {
  let step1Indicator = (
    <StepIndicator title={'Info'} number={1} isHighlighted={true} />
  )
  let separator1 = <Separator isHighlighted={props.currentStep >= 2} />
  let step2Indicator = (
    <StepIndicator
      title={'Schedule'}
      number={2}
      isHighlighted={props.currentStep >= 2}
    />
  )
  let separator2 = <Separator isHighlighted={props.currentStep >= 3} />
  let step3Indicator = (
    <StepIndicator
      title={'Pricing'}
      number={3}
      isHighlighted={props.currentStep >= 3}
    />
  )
  let separator3 = <Separator isHighlighted={props.currentStep >= 4} />
  let step4Indicator = (
    <StepIndicator
      title={'Settings'}
      number={4}
      isHighlighted={props.currentStep >= 4}
    />
  )

  return (
    <div className={classes.CreateOfferStepsOverview}>
      <ul>
        <li>{step1Indicator}</li>
        <li>{separator1}</li>
        <li>{step2Indicator}</li>
        <li>{separator2}</li>
        <li>{step3Indicator}</li>
        <li>{separator3}</li>
        <li>{step4Indicator}</li>
      </ul>
    </div>
  )
}

export default OfferStepsOverview
