import React from 'react';
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../submodules/naoo-web-components/Shared/constants";
import ModalWindow from "../../../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow";
import {googleSheetApi, SPREADSHEETS_SCOPE} from "../../../../api/googleSheetApi";
import {addMessageThunk} from "../../../../submodules/naoo-web-components/Shared/reducers/messagesReducer";
import {useDispatch} from "react-redux";
import {createMessage, MESSAGES_TYPES} from "../../../../submodules/naoo-web-components/Components/Message/Message";
import { useGoogleLogin } from '@react-oauth/google';

const ImportPostsModal = ({isOpen, onClose}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const importFromCsvClick = () => {
        onClose()
        navigate(ROUTES.ADMIN_DASHBOARD.POSTS + '/importFromCsv')
    }

    const importFromGoogleSheetClick = useGoogleLogin({
        onSuccess: tokenResponse => {
            googleSheetApi.signIn(tokenResponse)
            onClose()
            navigate(ROUTES.ADMIN_DASHBOARD.POSTS + '/syncGoogleSheet')
        },
        onError: errorResponse => {
            console.error(errorResponse)
            dispatch(addMessageThunk({
                    message: createMessage({
                        message: errorResponse.message,
                        type: MESSAGES_TYPES.ERROR,
                    })
                }
            ))
        },
        scope: SPREADSHEETS_SCOPE
    });


    return (
        <ModalWindow
            isOpen={isOpen}
            onClose={onClose}
            title={'Import Posts'}
            subtitle={'Choose source: CSV File or Google Sheet'}
            primaryButton={'Import from Google Sheet'}
            handlePrimaryClick={importFromGoogleSheetClick}
            secondaryButton={'Import from CSV'}
            handleSecondaryClick={importFromCsvClick}
        />
    );
};

export default ImportPostsModal;