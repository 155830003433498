import React, {useState} from 'react';
import classes from './AddComment.module.scss'
import TextArea from "../../../../../submodules/naoo-web-components/Components/TextArea/TextArea";

const AddComment = ({offer, handlePostComment}) => {

    const [comment, setComment] = useState('')

    const handleCommentChange = (event) => {
        setComment(event.target.value)
    }

    const handlePostButtonClick = () => {
        handlePostComment(comment, setComment)
    }

    const handleKeyPress = (event) =>{
        if (event.key === "Enter"){
            handlePostComment(comment, setComment)
        }
    }

    return (
        <div className={classes.AddCommentContainer} onKeyPress={handleKeyPress}>
            <img className={classes.Logo} src={offer?.business?.logo?.url} alt=""/>
            <TextArea
                className={classes.Input}
                type="COMMENT"
                name={'newComment'}
                placeholder={'Add a comment...'}
                value={comment}
                onChange={handleCommentChange}
            />
            <button
                className={classes.PostButton}
                onClick={handlePostButtonClick}
            >
                Post
            </button>
        </div>
    );
};

export default AddComment;