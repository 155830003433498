import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchManagedBusinesses } from './store/reducers/managedBusinessesSlice'
import { ROUTES } from './submodules/naoo-web-components/Shared/constants'
import { selectMessages } from './submodules/naoo-web-components/Shared/reducers/messagesReducer'
import Messages from './submodules/naoo-web-components/Components/Messages/Messages'
import { useProjectRoutes } from './routes/routes'

const App = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const messages = useSelector(selectMessages)

  const routes = useProjectRoutes()
  useEffect(() => {
    dispatch(fetchManagedBusinesses()).then((action) => {
      if (
        (Object.keys(action.payload).length === 0 || action.payload?.businesses.length === 0) &&
        location.pathname === ROUTES.MAIN
      ) {
        navigate(ROUTES.BUSINESS_DASHBOARD.CREATE_BUSINESS, { replace: true })
      } else if (!action.payload?.businesses[0].subscriptionName) {
        navigate(ROUTES.BUSINESS_DASHBOARD.SUBSCRIPTION, { replace: true })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {routes}
      <Messages messages={messages} />
    </>
  )
}

export default App
