import React from 'react'

import classes from './OfferPreview.module.scss'

import {ReactComponent as LocationIcon} from './assets/location-icon.svg'
import NaooLogo from './assets/noologo.svg'
import {ReactComponent as CameraIcon} from '../../../../submodules/naoo-web-components/Shared/UI/assets/camera-icon.svg'
import Avatar from "../../../../submodules/naoo-web-components/Components/Avatar/Avatar";
import {formatDistanceToNow} from "date-fns";
import {BUTTON_TYPES, Button} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";

const OfferPreview = ({offer}) => {

    const address = offer?.business?.address?.length > 20 ?
        offer?.business?.address.split(',')[0] : offer?.business?.address

    const offerTime = offer?.createdAt
        ? <span className={classes.OfferTime}>{formatDistanceToNow(new Date( offer?.createdAt),{ addSuffix:true})}</span>
        : null

    const locationIcon = (
        <LocationIcon className={classes.LocationIcon}/>
    )

    const offerLocation = <span className={classes.OfferLocation}>{address}</span>

    const offerImage = (
        offer?.media?.[0]?.url
            ? <img
                className={classes.OfferImage}
                alt=""
                src={offer?.media?.[0]?.url}
            />
            : <div className={classes.ImagePlaceholder}>
                <CameraIcon width={24} height={24}/>
            </div>
    )

    const coins = offer?.points ? (
        <div className={classes.CoinsContainer}>
            <span className={classes.CoinsText}>{offer?.points}</span>
            <img src={NaooLogo} alt=""/>
        </div>
    ) : null

    const hasPrice = !!offer?.price
    const hasDiscount = !!offer?.discountedPrice

    const offerTitle = <p className={classes.OfferTitle}>
        {
            (hasPrice && hasDiscount) &&
            <span className={classes.Discount}>
            -
                {
                    ((offer.price - offer.discountedPrice) / offer?.price * 100).toFixed(0)
                }
                %
            </span>
        }

        {offer.title ? offer.title : 'Offer Title'}
    </p>

    const offerText = (
        <p
            className={classes.OfferDescription}
            dangerouslySetInnerHTML={{
                __html: offer?.text?.replaceAll('\n','<br/>')
            }}
        />
    )

    return (
        <div className={classes.OfferPreview}>
            <div className={classes.OfferPreviewImageContainer}>
                {coins}
                {offerImage}
                <Avatar
                    className={classes.Logo}
                    name={offer?.business?.name}
                    img={offer?.business?.logo?.url}
                />
            </div>
            <div className={classes.TitleAndText}>
                {offerTitle}
                {offerText}
            </div>
            <div className={classes.TimeAndDistance}>
                {offerTime}
                {locationIcon}
                {offerLocation}
            </div>
            <div className={classes.Button}>
                {
                    hasPrice &&
                    <Button type={BUTTON_TYPES.NOT_ROUNDED}>
                        {
                            hasPrice &&
                            <span className={classes.Price}>{offer?.price} ₣</span>
                        }
                        {
                            hasDiscount &&
                            <span className={classes.Discount}>
                                {offer?.discountedPrice} ₣
                            </span>
                        }
                        <span className={classes.Dot}>•</span>

                        Buy Offer
                    </Button>
                }
            </div>
        </div>
    )
}

export default OfferPreview
