import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Button, BUTTON_TYPES } from '../../../../submodules/naoo-web-components/Components/Button/Buttons'
import { useSelector } from 'react-redux'
import { selectCurrentBusiness } from '../../../../store/reducers/managedBusinessesSlice'
import { createPaymentMethod } from '../../../../api/businessApi'
import { getSelf } from '../../../../api/userApi'

import styles from './PaymentMethodForm.module.scss'

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const PaymentMethodForm = ({ onCreate, onError, onClose, handleSetLoading }) => {
  const stripe = useStripe()
  const elements = useElements()
  const business = useSelector((state) => selectCurrentBusiness(state))

  const handleSubmit = async (e) => {
    e.preventDefault()

    handleSetLoading(true)

    const { data: user } = await getSelf()

    const cardNumberElement = elements.getElement(CardNumberElement)
    const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        email: user.email,
        name: business.name,
      },
    })

    if (paymentMethod) {
      try {
        await createPaymentMethod(business.id, {
          stripePaymentMethodId: paymentMethod.id,
        })

        onCreate(paymentMethod.id)
      } catch (err) {
        onError(err)
      }
    } else {
      onError(pmError)
    }
  }

  return (
    <div>
      <form>
        <div className={styles.Container}>
          <div className={styles.Field}>
            <label>Card Number</label>
            <CardNumberElement options={cardStyle} />
          </div>
          <div className={styles.Field}>
            <label>Expiry Date</label>
            <CardExpiryElement options={cardStyle} />
          </div>
          <div className={styles.Field}>
            <label>CVC</label>
            <CardCvcElement options={cardStyle} />
          </div>
        </div>
        <div className={styles.Buttons}>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button variant={BUTTON_TYPES.SECONDARY} onClick={onClose}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  )
}

export default PaymentMethodForm
