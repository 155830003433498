import React from 'react';
import ModalWindow from "../../../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow";

const DiscardOfferModal = ({isOpen, onCancel, offerTitle, discardOfferClicked}) => {
    return (
        <ModalWindow
            title={'Discard offer?'}
            isOpen={isOpen}
            onClose={onCancel}
            primaryButton={'Discard offer and leave page'}
            secondaryButton={'Cancel'}
            handlePrimaryClick={discardOfferClicked}
            handleSecondaryClick={onCancel}
        >
            <p>
                Your offer <strong>{offerTitle ?`"${offerTitle}"`:'' }</strong> won’t be saved.
            </p>
        </ModalWindow>
    );
};

export default DiscardOfferModal;