import React from 'react';
import {useDropzone} from "react-dropzone";
import classes from './FileDropZone.module.scss'
import PropTypes from "prop-types";
import {ReactComponent as ClearIcon} from "../../Shared/UI/assets/closeIcon.svg";

const FileDropZone = ({files, onChange, className, ...props}) => {
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: files => onChange(files),
        ...props,
    })

    const handleClearIconClick = (file) => (e) => {
        e.stopPropagation()
        onChange(files.filter(item=>item.name!==file.name))
    }

    return (
        <div {...getRootProps()} className={`${classes.Container} ${isDragActive ? classes.DragActive : null } ${className}`}>
            {
                !files.length
                    ? <p>
                        Drag CSV file here <span className={classes.Or}>or</span> <span className={classes.Link}>Browse</span>
                      </p>
                    :  <div className={classes.FilesContainer}>
                        {
                            files.map((file)=>(
                                <div className={classes.File} key={file.name}>
                                    {file.name}
                                    <ClearIcon
                                        className={classes.ClearIcon}
                                        onClick={handleClearIconClick(file)}
                                    />
                                </div>
                            ))
                        }
                    </div>
            }
            <input {...getInputProps()} />
        </div>
    );
};

export default FileDropZone;

FileDropZone.propTypes = {
    files: PropTypes.array,
    onChange: PropTypes.func,
    className: PropTypes.string
}