import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getOffersStats, getOfferStats, mainGetBusinessDashboard } from '../../api/api'
import { format } from 'date-fns'

export const fetchDashboard = createAsyncThunk(
	'dashboard/fetchDashboard',
	async ({ businessId, timeframeStart, timeframeEnd }) => {
		const resp = await mainGetBusinessDashboard(businessId, timeframeStart, timeframeEnd)
		return resp.data
	}
)

export const getOffersStatsThunk = createAsyncThunk('dashboard/getOffersStatsThunk', async (payload) => {
	const response = await getOffersStats({ ...payload })
	return response.data
})

export const getOfferStatsThunk = createAsyncThunk('dashboard/getOfferStatsThunk', async (payload) => {
	const response = await getOfferStats({ ...payload })
	return response.data
})

const initialState = {
	dashboard: null,
	isObsolete: false,
	activeModuleName: 'dashboard',
	offersStats: null,
	offerStats: {
		offer: null,
		stats: [],
	},
	status: 'idle',
	error: null,
}

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		updatedDashboardIsObsolete(state, action) {
			state.isObsolete = action.payload.isObsolete
		},
		changeActiveModule(state, action) {
			state.activeModuleName = action.payload.activeModuleName
		},
		setOfferForStats(state, action) {
			const offer = state.dashboard.offerPerformances
				.map((item) => item.offer)
				.find((item) => item.id === action.payload)
			state.offerStats.offer = { ...offer }
			state.offerStats.offer.price = state.offerStats.offer.price / 10 ** state.offerStats.offer.decimals
			state.offerStats.offer.discountedPrice =
				state.offerStats.offer.price - state.offerStats.offer.discount / 10 ** state.offerStats.offer.decimals
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchDashboard.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(fetchDashboard.fulfilled, (state, action) => {
			state.status = 'succeeded'
			state.isObsolete = false
			state.dashboard = action.payload
			state.error = null
		})
		builder.addCase(fetchDashboard.rejected, (state, action) => {
			state.status = 'failed'
			state.dashboard = null
			state.error = action.error.message
		})
		builder.addCase(getOffersStatsThunk.fulfilled, (state, action) => {
			state.offersStats = action.payload
		})
		builder.addCase(getOfferStatsThunk.fulfilled, (state, action) => {
			state.offerStats.stats = action.payload.reverse().map((item) => {
				item.labelDate = format(new Date(item.startDate), 'dd MMM')
				return item
			})
		})
	},
})

export const { updatedDashboardIsObsolete, changeActiveModule, setOfferForStats } = dashboardSlice.actions

export default dashboardSlice.reducer

// === Selectors ===

export const selectDashboard = (state) => state.dashboard.dashboard
export const selectDashboardStatus = (state) => state.dashboard.status
export const selectDashboardError = (state) => state.dashboard.error
export const selectDashboardIsObsolete = (state) => state.dashboard.isObsolete
export const selectActiveModuleName = (state) => state.dashboard.activeModuleName
export const selectOfferStats = (state) => state.dashboard.offerStats
