import React from 'react'

import classes from './OfferStepsOverviewSeparator.module.css'

const OfferStepsOverviewSeparator = (props) => {
  const separatorClasses = props.isHighlighted
    ? `${classes.Separator} ${classes.Highlighted}`
    : classes.Separator
  return <div className={separatorClasses} />
}

export default OfferStepsOverviewSeparator
