import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
	mainCreateMediaPresignedUrl,
	createPost,
	deletePost,
	mainGetMedia,
	getPosts,
	updatePost,
	getComments,
	createComment,
	createLike,
	deleteLike,
	createLikeForComment,
	deleteLikeForComment,
	getPostStats,
} from '../../api/api'
import { createPostDeepLink } from '../../api/firebaseDeepLinkApi'
import { sendImage } from '../../submodules/naoo-web-components/Shared/utility/sendImage'
import { format, isBefore } from 'date-fns'
import {selectCurrentBusinessId} from "./managedBusinessesSlice";

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async (payload,{getState}) => {
	const state = getState()
	const businessId = selectCurrentBusinessId(state)
	const response = await getPosts({...payload, businessId})
	return response.data
})

export const fetchPostsByNextToken = createAsyncThunk('posts/fetchPostsByNextToken', async (payload, {getState}) => {
	const state = getState()
	const businessId = selectCurrentBusinessId(state)
	const response = await getPosts({...payload, businessId})
	return response.data
})

const sendMedia = (items) => {
	return (
		items?.map(async (item) => {
			if (item?.id) {
				return { id: item?.id }
			}
			const mediaResponse = await sendImage(item?.url, mainCreateMediaPresignedUrl, mainGetMedia)
			return { id: mediaResponse.mediaId }
		}) ?? []
	)
}

const delay = (s) => new Promise((res) => setTimeout(res, s * 1000))

export const createPostThunk = createAsyncThunk('posts/createPost', async (post, { getState }) => {
	const media = await Promise.all(sendMedia(post.galleryItems))
	const { managedBusinesses } = getState()
	let data = {
		text: post.text,
		userID: post.userID,
		tagIds: post.tagIds,
		businessId: managedBusinesses.currentBusinessId,
		channelId: post.channelId,
		publicationDate: post.publicationDate,
		mentions: post.mentions,
		mediaIds: media?.map(item=>item.id),
	}
	const response = await createPost(data)
	await delay(2)
	return response.data
})

export const updatePostThunk = createAsyncThunk('posts/updatePost', async (payload) => {
	const media = await Promise.all(sendMedia(payload.post.galleryItems))
	let data = {
		text: payload.post.text,
		userID: payload.post.userID,
		channelId: payload.post.channelId,
		tagIds: payload.post.tagIds,
		publicationDate: payload.post.publicationDate,
		mediaIds: media?.map(item=>item.id),
		mentions: payload.post.mentions,
	}

	const response = await updatePost(data, payload.id)
	return response.data
})

export const deletePostThunk = createAsyncThunk('posts/deletePost', async (payload) => {
	await deletePost(payload)
	return payload
})

export const fetchComments = createAsyncThunk('posts/fetchComments', async (payload) => {
	const response = await getComments({
		id: payload.id,
	})
	return response.data
})

export const fetchCommentsByNextToken = createAsyncThunk('posts/fetchCommentsByNextToken', async (payload) => {
	const response = await getComments({
		id: payload.id,
		nextToken: payload.nextToken,
	})
	return response.data
})

export const createCommentThunk = createAsyncThunk('posts/createCommentThunk', async (payload) => {
	const response = await createComment({
		id: payload.id,
		comment: {
			text: payload.text,
			businessId: payload.bid,
			userId: payload.uid,
			mentions: payload.mentions,
		},
	})
	return response.data
})

export const creteLikeThunk = createAsyncThunk('posts/creteLikeThunk', async (payload) => {
	await createLike(payload)
})

export const deleteLikeThunk = createAsyncThunk('posts/deleteLikeThunk', async (payload) => {
	await deleteLike(payload)
})

export const createLikeForCommentThunk = createAsyncThunk('posts/createLikeForCommentThunk', async (payload) => {
	await createLikeForComment({
		id: payload.id,
		cid: payload.cid,
	})
	return payload.cid
})

export const deleteLikeForCommentThunk = createAsyncThunk('posts/deleteLikeForCommentThunk', async (payload) => {
	await deleteLikeForComment({
		id: payload.id,
		cid: payload.cid,
	})
	return payload.cid
})

export const getPostStatsThunk = createAsyncThunk('posts/getPostStatsThunk', async (payload) => {
	const response = await getPostStats({
		...payload,
	})
	return response.data
})

export const createDeepLink = createAsyncThunk('posts/createDeepLink', async (payload, { getState }) => {
	const { posts } = getState()
	if (posts.deepLink) return { shortLink: posts.deepLink }
	const response = await createPostDeepLink(`https://naoo.com/posts/${payload.postId}`)
	return response.data
})

const initialState = {
	posts: [],
	activePost: null,
	isSendImg: false,
	status: null,
	nextToken: '',
	fetching: false,
	fetchingComments: false,
	nextTokenComments: '',
	postStats: [],
	deepLink: '',
}

const postsSlice = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		setStatus: (state, action) => {
			state.status = action.payload
		},
		setActivePost: (state, action) => {
			state.activePost = state.posts.find((item) => item.id === action.payload)
			state.deepLink = ''
		},
		setIsSendImg: (state, action) => {
			state.isSendImg = action.payload
		},
		setFetching: (state, action) => {
			state.fetching = action.payload
		},
		setFetchingComments: (state, action) => {
			state.fetchingComments = action.payload
		},
		setIsViewed:(state, action) => {
			state.posts.find(it=>it.id === action.payload).isViewed = true
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPosts.pending, (state) => {
			state.status = 'loading'
		})
		builder.addCase(fetchPosts.fulfilled, (state, action) => {
			state.posts = action.payload.posts?.map(it=>({...it, isViewed:false})) || []
			state.status = null
			state.nextToken = action.payload.nextToken
		})
		builder.addCase(fetchPosts.rejected, (state) => {
			state.status = 'error'
		})
		builder.addCase(fetchPostsByNextToken.fulfilled, (state, action) => {
			state.posts.push(...action.payload.posts?.map(it=>({...it, isViewed:false})) || [])
			state.nextToken = action.payload.nextToken
			state.fetching = false
		})
		builder.addCase(createPostThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(createPostThunk.fulfilled, (state, action) => {
			state.posts.unshift(action.payload)
			state.status = 'successfully'
		})
		builder.addCase(createPostThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(updatePostThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(updatePostThunk.fulfilled, (state, action) => {
			state.posts = state.posts.map((post) => {
				if (post.id === action.payload.id) return action.payload
				return post
			})
			state.status = 'successfully'
		})
		builder.addCase(updatePostThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(deletePostThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(deletePostThunk.fulfilled, (state, action) => {
			state.posts = state.posts.filter((item) => item.id !== action.payload)
			state.status = 'successfully'
		})
		builder.addCase(deletePostThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(fetchComments.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(fetchComments.fulfilled, (state, action) => {
			state.activePost.comments = action.payload.comments || []
			state.nextTokenComments = action.payload.nextToken
			state.status = null
		})
		builder.addCase(fetchComments.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(fetchCommentsByNextToken.fulfilled, (state, action) => {
			if (action?.payload?.comments) {
				state.activePost.comments.push(...action.payload.comments)
			}
			state.nextTokenComments = action.payload.nextToken
		})
		builder.addCase(createCommentThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(createCommentThunk.fulfilled, (state, action) => {
			state.activePost.comments.unshift(action.payload)
			state.status = 'successfully'
		})
		builder.addCase(createCommentThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(creteLikeThunk.fulfilled, (state, action) => {
			state.activePost.userLiked = true
			state.activePost.likesCount++
		})
		builder.addCase(deleteLikeThunk.fulfilled, (state, action) => {
			state.activePost.userLiked = false
			state.activePost.likesCount--
		})
		builder.addCase(createLikeForCommentThunk.fulfilled, (state, action) => {
			state.activePost.comments.find((comment) => comment.id === action.payload).userLiked = true
		})
		builder.addCase(deleteLikeForCommentThunk.fulfilled, (state, action) => {
			state.activePost.comments.find((comment) => comment.id === action.payload).userLiked = false
		})
		builder.addCase(getPostStatsThunk.fulfilled, (state, action) => {
			state.postStats = action.payload.reverse().map((item) => {
				item.labelDate = format(new Date(item.startDate), 'dd MMM')
				return item
			})
		})
		builder.addCase(createDeepLink.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(createDeepLink.fulfilled, (state, action) => {
			state.deepLink = action.payload.shortLink
			state.status = 'successfully'
		})
		builder.addCase(createDeepLink.rejected, (state, action) => {
			state.status = 'error'
		})
	},
})

export default postsSlice.reducer
export const {
	setStatus,
	setActivePost,
	setIsSendImg,
	setFetching,
	setFetchingComments,
	setIsViewed,
} = postsSlice.actions

export const selectStatus = (state) => state.posts.status
export const selectPosts = (state, userId, onlyPublished) => {
	if (userId) {
		return state.posts.posts.filter((post) => post?.user?.id === userId)
	}
	if (onlyPublished) {
		return state.posts.posts.filter((post) => isBefore(new Date(post?.publicationDate), new Date()))
	}
	return state.posts.posts
}

export const selectActivePost = (state) => state.posts.activePost
export const selectNextToken = (state) => state.posts.nextToken
export const selectFetching = (state) => state.posts.fetching
export const selectNextTokenComments = (state) => state.posts.nextTokenComments
export const selectFetchingComments = (state) => state.posts.fetchingComments
export const selectPostStats = (state) => state.posts.postStats
export const selectDeepLink = (state) => state.posts.deepLink
export const selectIsAdmin = () => false
export const selectIsNotViewedCount = (state) => state.posts
