import React from 'react';
import classes from './PostedBy.module.scss'
import Avatar from "../../../../submodules/naoo-web-components/Components/Avatar/Avatar";
import {getUsername} from "../../../../submodules/naoo-web-components/Shared/utility/utils";

const PostedBy = ({gridClass, mediaClass, textClass, channel, user}) => {
    return (
        <>
            {
                channel
                    ? <div className={`${classes.PostedBy} ${gridClass}`}>
                        <Avatar
                            className={mediaClass}
                            name={channel.title}
                            img={channel?.media?.url}
                        />
                        <div className={`${textClass} ${classes.Text}`}>
                            <div className={classes.Title}>
                                {channel.title}
                            </div>
                            <div className={classes.User}>
                                {getUsername(user)}
                            </div>
                        </div>
                      </div>
                    :<div  className={`${classes.PostedBy} ${gridClass}`}>
                        <Avatar
                            className={`${mediaClass} ${classes.Logo}`}
                            name={user?.firstname}
                            lastname={user?.lastname}
                            img={user?.avatar?.url}
                        />
                        <div className={`${textClass} ${classes.Text}`}>
                            <div className={classes.Title}>
                                {getUsername(user)}
                            </div>
                        </div>
                    </div>
            }

        </>
    );
};

export default PostedBy;