import React from 'react';
import classes from './OfferWithComments.module.scss'
import ModalOverlay from "../../../../submodules/naoo-web-components/Components/ModalOverlay/ModalOverlay";
import ImgAndButtons from "./ImgAndButtons/ImgAndButtons";
import {selectActiveOffer} from "../../../../store/reducers/offerReducer";
import {useSelector} from "react-redux";
import OfferTextAndComments from "./OfferTextAndComments/OfferTextAndComments";
import SwipeWrapper from "../../../../submodules/naoo-web-components/Components/Draggable/SwipeWrapper";
const OfferWithComments = ({onClose}) => {
    const offer = useSelector(selectActiveOffer)
    return (
        <ModalOverlay onClose={onClose}>
            <SwipeWrapper handler={onClose}>
                <div className={classes.Container}>
                    <ImgAndButtons offer={offer}/>
                    <OfferTextAndComments offer={offer}/>
                </div>
            </SwipeWrapper>

        </ModalOverlay>
    );
};

export default OfferWithComments;