import React, {Fragment, useContext, useState} from 'react';
import classes from "./PostTextAndComments.module.scss";
import BusinessInfo from "../BusinessInfo/BusinessInfo";
import Comment from "../Comment/Comment";
import AddComment from "../AddComment/AddComment";
import {useDispatch, useSelector} from "react-redux";
import {
    createCommentThunk, deletePostThunk, fetchCommentsByNextToken,
    selectFetchingComments,
    selectNextTokenComments, setFetchingComments
} from "../../../../../store/reducers/postsReducer";
import spinnerClasses from '../../../../../submodules/naoo-web-components/Shared/Spinner.module.scss'
import {useObserver} from "../../../../../submodules/naoo-web-components/Shared/hooks/useObserver";
import {executeIfExist, returnObservableItem} from "../../../../../submodules/naoo-web-components/Shared/utility/utils";
import {Preloader} from "../../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import PostButtons from "../PostButtons/PostButtons";
import MoreDropdown from "../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {useNavigate} from "react-router-dom";
import UserInfo from "../UserInfo/UserInfo";
import {useMentionData} from "../../../../../submodules/naoo-web-components/Shared/hooks/useMentionData";
import {AnalyticsContext} from "../../../../../submodules/naoo-web-components/Shared/context/analyticsContext";

const PostTextAndComments = ({post}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const analyticsEvents = useContext(AnalyticsContext)

    const LIMIT = 10
    const nextToken = useSelector(state => selectNextTokenComments(state))
    const fetching = useSelector(state => selectFetchingComments(state))
    const [element, setElement] = useState(null)

    const {getTextWithMentions} = useMentionData(post.text, post.mentions)

    useObserver(
        element,
        fetching, setFetchingComments,
        nextToken, fetchCommentsByNextToken
    )
    const handlePostComment = (text, mentions, setComment)=>{
        dispatch(createCommentThunk({
            id:post.id,
            text,
            uid:post.user?.id,
            bid:post.business.id,
            mentions
        }))
            .then((response)=>{
                if(response.meta.requestStatus === 'fulfilled'){
                    setComment('')
                }
            })
    }

    const handleAllCommentsClick = () =>{
        dispatch(fetchCommentsByNextToken({
            id: post.id,
            nextToken
        }))
    }

    const handleDeleteMoreItemClick = () => {
        dispatch(deletePostThunk(post.id))
            .then((response)=>{
                if(response.meta.requestStatus === 'fulfilled'){
                    navigate(-1)
                }
            })
    }

    const handleMoreClick = () => {
        executeIfExist(analyticsEvents?.more_btn_clicked)
    }

    return (
        <>
            <div className={classes.Header}>
                {
                    post?.business
                        ? <BusinessInfo business={post?.business} createdAt={post?.createdAt}/>
                        : <UserInfo user={post?.user} createdAt={post?.createdAt}/>
                }
                <div className={classes.More}>
                    <MoreDropdown onClick={handleMoreClick}>
                        <MoreItem text={'Delete post'} danger onClick={handleDeleteMoreItemClick}/>
                    </MoreDropdown>
                </div>
            </div>
            <div className={classes.Comments}>
                <div className={classes.PostText}>
                    <span>{getTextWithMentions()}</span>
                </div>
                {
                    !post?.media?.[0]?.url &&
                    <PostButtons post={post}/>
                }
                {
                    ( post?.commentsCount > LIMIT && post?.comments?.length === LIMIT) &&
                    <div className={classes.ViewCommentsButton}>
                        <span onClick={handleAllCommentsClick}>View all {post.commentsCount} comments</span>
                    </div>
                }

                <div className={classes.CommentsContainer}>
                    {
                        post?.comments?.map((item, index, array)=>(
                            <Fragment key={item.id}>
                                <Comment
                                    key={item.id}
                                    comment={item}
                                    postId={post.id}
                                />
                                {
                                    returnObservableItem(array.length, LIMIT, index, setElement )
                                }
                            </Fragment>
                        ))
                    }
                    {
                        fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                    }

                </div>
            </div>
            <AddComment post={post} handlePostComment={handlePostComment}/>
        </>
    );
};

export default PostTextAndComments;