import React, {useState} from 'react';
import DropdownButton from "../../shared/UI/DropdownButton/DropdownButton";
import classes from "./TimeFrameDropdown.module.scss";
import {usePopper} from "react-popper";
import {usePopperClickOutside} from "../../../submodules/naoo-web-components/Shared/hooks/usePopperClickOutside";
import {startOfDay, sub} from "date-fns";

const TimeFrameDropdown = ({onStartDateSelected, onClick}) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const {styles, attributes} = usePopper(referenceElement, popperElement, {});

    const {visible, toggleVisible} = usePopperClickOutside({referenceElement, popperElement})

    const [timeframeText, setTimeframeText] = useState('Last 30 days')
    const names = ['Today', 'Last 7 days', 'Last 30 days', 'Since start']

    const currentDate = new Date()
    const startDates = [
        startOfDay(currentDate),
        sub(currentDate, {
            days: 7
        }),
        sub(currentDate, {
            days: 30
        }),
        new Date('2019-01-01')
    ]

    const handleTimeframeClick = () => {
        toggleVisible()
        if (onClick) {
            onClick()
        }
    }

    const handleItemClick = (index, name) => (event) => {
        event.stopPropagation()
        onStartDateSelected(startDates[index], names[index])
        setTimeframeText(name)
        toggleVisible()
    }

    return (
        <>
            <div ref={setReferenceElement} className={classes.Container} onClick={handleTimeframeClick}>
            <span className={classes.TimeframeText}>
                {timeframeText}
            </span>
                <DropdownButton clicked={handleTimeframeClick}/>
            </div>
            {
                visible &&
                <div className={classes.ItemsContainer} ref={setPopperElement}
                     style={styles.popper}  {...attributes.popper}>
                    <ul className={classes.List}>
                        {names.map((name, index, _) => (
                            <li
                                key={index}
                                onClick={handleItemClick(index, name)}
                            >
                                {name}
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </>
    );
};

export default TimeFrameDropdown;