import React from 'react';
import classes from './BusinessInfo.module.scss'
import {ReactComponent as LocationIcon} from "../../../../../submodules/naoo-web-components/Shared/UI/assets/location-icon.svg";
import {formatDistanceToNow} from "date-fns";

const BusinessInfo = ({business, createdAt}) => {

    const address = business?.address?.length > 20 ?
        business?.address.split(',')[0] : business?.address

    return (
       <div className={classes.Container}>
           <img className={classes.Logo}
                src={business?.logo?.url} alt=""/>
           <div className={classes.NameAndInfo}>
               <div className={classes.Name}><span>{business?.name}</span></div>
               <div className={classes.Info}>
                   <span>{formatDistanceToNow(new Date(createdAt),{ addSuffix:true})}</span>
                   <LocationIcon width={20} height={20}/>
                   <span>{address}</span>
               </div>
           </div>
       </div>
    );
};

export default BusinessInfo;