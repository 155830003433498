import {createMessage, MESSAGES_TYPES} from "../../../../submodules/naoo-web-components/Components/Message/Message";
import {Link} from "react-router-dom";
import classes from "../../../main/Posts/GoogleSheetModal/GoogleSheetModal.module.scss";
import React, {useState} from "react";
import {FullscreenPreloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../../Spinner.module.scss";
import {IMPORT_ERROR_TYPES, validateStructure} from "./commonUtils";
import {parse} from "date-fns";

export const postImportErrorMessages = {
    [IMPORT_ERROR_TYPES.FILE_EMPTY]: createMessage({
        children: <span>The file is empty. <Link className={classes.Link} to="/data/posts.csv" target="_blank"
                                                 download>Download the template</Link>, fill in the data and upload again</span>,
        type: MESSAGES_TYPES.ERROR,
        className: classes.Message
    }),
    [IMPORT_ERROR_TYPES.INVALID_TABLE_FORMAT]: createMessage({
        children: <span>Invalid table format. <Link className={classes.Link} to="/data/posts.csv" target="_blank"
                                                    download>Download the template</Link>, fill in the data and upload again</span>,
        type: MESSAGES_TYPES.ERROR,
        className: classes.Message
    }),
}

export const validatePostStructure = (data) => {
    const columnsNames = ['id', 'business', 'media', 'publicationDate', 'tags', 'text']

    return validateStructure(data, columnsNames)
}


export const generatePostFromRow = (row) => {
    const {id, business, publicationDate, tags, text} = row

    const post = {
        text,
        publicationDate: parse(publicationDate, 'dd.MM.yyyy hh:mm', new Date()).toISOString(),
    }

    if (tags) {
        post.tagIds = tags
            .split(',')
            .map(tag => tag.trim())
    }

    if (!id) {
        post.businessId = business
    }
    return post
}

export const useImportPosts = () => {
    const [isLoadingRead, setIsLoadingRead] = useState(false)
    const [isLoadingImport, setIsLoadingImport] = useState(false)
    const [postsFromImport, setPostFromImport] = useState([])

    const isHavePosts = !!postsFromImport.length

    const loader = (
        isLoadingImport &&
        <FullscreenPreloader
            overlayClassName={spinnerClasses.lightBlurPreloader}
            spinnerClassName={spinnerClasses.spinnerSize}
        />
    )

    const messageAfterLoad = (
        <div className={classes.Info}>
            <span>{postsFromImport.length} Posts will be added.</span>
        </div>
    )


    return {
        isLoadingRead, setIsLoadingRead,
        isLoadingImport, setIsLoadingImport,
        postsFromImport, setPostFromImport,
        isHavePosts,
        loader,
        messageAfterLoad
    }
}