import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
	createMediaPresignedUrl,
	createVouchers,
	createVoucherSample,
	deleteVoucher,
	getMedia,
	getVouchers,
	getVoucherSamples,
	updateVoucher,
	updateVoucherSample,
} from '../../api/api'
import { sendImage } from '../../submodules/naoo-web-components/Shared/utility/sendImage'

export const fetchVouchers = createAsyncThunk('vouchers/fetchVouchers', async (payload) => {
	const response = await getVouchers(payload)
	return response.data
})

export const fetchVouchersByNextToken = createAsyncThunk('vouchers/fetchVouchersByNextToken', async (payload) => {
	const response = await getVouchers({
		nextToken: payload?.nextToken,
	})
	return response.data
})

export const fetchVoucherSamples = createAsyncThunk('vouchers/fetchVoucherSamples', async (payload) => {
	const response = await getVoucherSamples(payload)
	return response.data
})

export const fetchVoucherSamplesByNextToken = createAsyncThunk(
	'vouchers/fetchVoucherSamplesByNextToken',
	async (payload) => {
		const response = await getVoucherSamples({
			nextToken: payload?.nextToken,
		})
		return response.data
	}
)

export const updateVoucherSampleThunk = createAsyncThunk('vouchers/updateVoucherOptionsThunk', async (payload) => {
	const { sample, id } = payload
	let mediaId = null
	if (sample?.media?.url && !sample?.media?.id) {
		mediaId = (await sendImage(sample?.media?.url, createMediaPresignedUrl, getMedia)).id
	}
	let data = {
		title: sample?.title,
		description: sample?.description,
		businessId: sample?.businessId,
		voucherType: sample?.voucherType,
		isActive: sample?.isActive,
		publicationDate: sample?.publicationDate,
		startDate: sample?.startDate,
		endDate: sample?.endDate,
		priceInPoints: sample?.priceInPoints,
		color: sample?.color,
	}

	if (mediaId) {
		data.mediaId = mediaId
	}

	const response = await updateVoucherSample({
		sample: data,
		id,
	})
	return response.data
})

export const createVoucherSampleThunk = createAsyncThunk('vouchers/createVoucherOptionsThunk', async (payload) => {
	let mediaId = null
	if (payload?.media?.url && !payload?.media?.id) {
		mediaId = (await sendImage(payload?.media?.url, createMediaPresignedUrl, getMedia)).id
	}

	let data = {
		title: payload?.title,
		description: payload?.description,
		businessId: payload?.businessId,
		voucherType: payload?.voucherType,
		isActive: payload?.isActive,
		publicationDate: payload?.publicationDate,
		startDate: payload?.startDate,
		endDate: payload?.endDate,
		priceInPoints: payload?.priceInPoints,
		color: payload?.color,
	}

	if (mediaId) {
		data.mediaId = mediaId
	}
	const response = await createVoucherSample(data)

	return response.data
})

export const createVouchersThunk = createAsyncThunk('vouchers/createVouchersThunk', async (payload) => {
	const response = await createVouchers(payload)
	return response.data
})
const delay = (s) => new Promise((res) => setTimeout(res, s * 1000))
export const addVouchersThunk = createAsyncThunk('vouchers/addVouchersThunk', async (payload) => {
	const prepareDataPromises = payload.map(async (voucher) => {
		const mediaId = (await sendImage(voucher.media, createMediaPresignedUrl, getMedia)).id
		return {
			sampleId: voucher?.sampleId,
			mediaId: mediaId,
			idInCrm: voucher.crmId,
		}
	})
	const preparedData = await Promise.all(prepareDataPromises)

	const response = await createVouchers({ vouchers: preparedData })
	await delay(1)
	return response.data
})

export const updateVoucherThunk = createAsyncThunk('vouchers/updateVoucherThunk', async (payload) => {
	const { data, id } = payload

	const response = await updateVoucher({
		data,
		id,
	})
	return response.data
})

export const deleteVoucherThunk = createAsyncThunk('vouchers/deleteVoucherThunk', async (payload) => {
	await deleteVoucher(payload)
	return payload
})

const initialState = {
	vouchers: [],
	samples: [],
	activeVoucher: null,
	activeSample: null,
	status: null,
	fetching: false,
	nextToken: '',
}

const vouchersSlice = createSlice({
	name: 'vouchers',
	initialState,
	reducers: {
		setStatus: (state, action) => {
			state.status = action.payload
		},
		setActiveSample: (state, action) => {
			state.activeSample = action.payload
		},
		setActiveVoucher: (state, action) => {
			state.activeVoucher = action.payload
		},
		setFetching: (state, action) => {
			state.fetching = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchVoucherSamples.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(fetchVoucherSamples.fulfilled, (state, action) => {
			state.samples = action.payload.thirdPartyVoucherSamples || []
			state.status = 'successfully'
		})
		builder.addCase(fetchVoucherSamples.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(fetchVoucherSamplesByNextToken.fulfilled, (state, action) => {
			if (action.payload.thirdPartyVoucherSamples) {
				state.samples.push(...action.payload.thirdPartyVoucherSamples)
			}
			state.nextToken = action.payload.nextToken
			state.fetching = false
		})
		builder.addCase(createVoucherSampleThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(createVoucherSampleThunk.fulfilled, (state, action) => {
			state.status = 'successfully'
			state.samples.unshift(action.payload)
		})
		builder.addCase(createVoucherSampleThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(updateVoucherSampleThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(updateVoucherSampleThunk.fulfilled, (state, action) => {
			state.samples = state.samples.map((sample) => {
				if (sample.id === action.payload.id) return action.payload
				return sample
			})
			state.activeSample = action.payload
			state.status = 'successfully'
		})
		builder.addCase(fetchVouchers.fulfilled, (state, action) => {
			state.vouchers = action.payload.thirdPartyVouchers || []
			state.nextToken = action.payload.nextToken
			state.status = 'successfully'
		})
		builder.addCase(fetchVouchers.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(fetchVouchersByNextToken.fulfilled, (state, action) => {
			if (action.payload.thirdPartyVouchers) {
				state.vouchers.push(...action.payload.thirdPartyVouchers)
			}
			state.nextToken = action.payload.nextToken
			state.fetching = false
		})
		builder.addCase(createVouchersThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(createVouchersThunk.fulfilled, (state, action) => {
			state.vouchers.push(...action.payload)
			state.status = 'successfully'
		})
		builder.addCase(createVouchersThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(addVouchersThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(addVouchersThunk.fulfilled, (state, action) => {
			state.vouchers.push(...action.payload)
			state.status = 'successfully'
		})
		builder.addCase(addVouchersThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(updateVoucherThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(updateVoucherThunk.fulfilled, (state, action) => {
			state.vouchers = state.vouchers.map((voucher) => {
				if (voucher.id === action.payload.id) return action.payload
				return voucher
			})
			state.status = 'successfully'
		})
		builder.addCase(updateVoucherThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(deleteVoucherThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(deleteVoucherThunk.fulfilled, (state, action) => {
			state.vouchers = state.vouchers.filter((voucher) => voucher.id !== action.payload)
			state.status = 'successfully'
		})
		builder.addCase(deleteVoucherThunk.rejected, (state, action) => {
			state.status = 'error'
		})
	},
})

export default vouchersSlice.reducer

export const { setStatus, setActiveSample, setFetching, setActiveVoucher } = vouchersSlice.actions

export const selectVouchers = (state) => state.vouchers.vouchers
export const selectVoucherSamples = (state) => state.vouchers.samples
export const selectStatus = (state) => state.vouchers.status
export const selectVoucherOptions = (state) => state.vouchers.options

export const selectActiveSample = (state) => state.vouchers.activeSample
export const selectActiveVoucher = (state) => state.vouchers.activeVoucher
export const selectNextToken = (state) => state.vouchers.nextToken
export const selectFetching = (state) => state.vouchers.fetching
