import React, {Fragment, useEffect, useState} from 'react';
import layoutClasses from "../Layout.module.scss";
import BusinessSidebar from "../Sidebar/BusinessSidebar";
import Topbar from "../Topbar/Topbar";
import classes from "./Followers.module.scss";
import spinnerClasses from '../../Spinner.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import TimeFrameDropdown from "../../components/TimeFrameDropdown/TimeFrameDropdown";
import {
    getBusinessFollowersByNexToken,
    getBusinessFollowersThunk,
    selectFetchingFollowers,
    selectFollowers,
    selectFollowersNextToken,
    setFetchingFollowers
} from "../../../store/reducers/businessSlice";
import {selectCurrentBusinessId} from "../../../store/reducers/managedBusinessesSlice";
import {returnObservableItem} from "../../../submodules/naoo-web-components/Shared/utility/utils";
import {Preloader} from "../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import {useObserver} from "../../../submodules/naoo-web-components/Shared/hooks/useObserver";
import PageTitle from "../../components/PageTitle/PageTitle";
import {withAnalytics} from "../../shared/utility/withAnalytics";
import TableWrapper from "../../components/Table/TableWrapper/TableWrapper";
import TableHeader from "../../components/Table/TableHeader/TableHeader";
import TableItem from "../../components/Table/TableItem/TableItem";
import Avatar from "../../../submodules/naoo-web-components/Components/Avatar/Avatar";
import {sub} from "date-fns";
import {useFetchWithQueryParams} from "../../../submodules/naoo-web-components/Shared/hooks/useFetchWithQueryParams";
import {setSearchParams} from "../../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer";

const CURRENT_PAGE = 'follovers'
const Followers = () => {
    const dispatch = useDispatch()
    // eslint-disable-next-line
    const navigate = useNavigate()
    const followers = useSelector(selectFollowers)
    const currentBusinessId = useSelector(selectCurrentBusinessId)
    const [startDate, setStartDate] = useState(sub(new Date(), {days: 30}))
    const [element, setElement] = useState(null)

    const fetching = useSelector(selectFetchingFollowers)
    const nextToken = useSelector(selectFollowersNextToken)

    const LIMIT = 10

    useEffect(() => {
        dispatch(setSearchParams({businessId: currentBusinessId}))
    }, [currentBusinessId, startDate, dispatch])

    const handleTimeFrameClick = (startDate, name) => {
        let analyticsEventName = ''
        switch (name) {
            case 'Today' :
                analyticsEventName = 'dashboards.business.followers.timeframe_dropdown_item_today_clicked'
                break
            case 'Last 7 days' :
                analyticsEventName = 'dashboards.business.followers.timeframe_dropdown_item_7d_clicked'
                break
            case 'Last 30 days' :
                analyticsEventName = 'dashboards.business.followers.timeframe_dropdown_item_30d_clicked'
                break
            case 'Since start' :
                analyticsEventName = 'dashboards.business.followers.timeframe_dropdown_item_since_start_clicked'
                break
            default :
                analyticsEventName = ''
        }
        withAnalytics(
            analyticsEventName,
            () => {
                setStartDate(startDate)
                dispatch(setSearchParams({startTime: startDate.toISOString()}))
            }
        )()
    }

    const handleTimeFrameDropdownClick = withAnalytics(
        'dashboards.business.followers.timeframe_btn_clicked'
    )

    const [{startTime, businessId}, isReady] = useFetchWithQueryParams(
        {
            initialValue: {
                startTime: startDate?.toISOString(),
                businessId: currentBusinessId,
            },
            currentPage: CURRENT_PAGE,
        }
    )

    useEffect(() => {
        console.log(businessId)
        if (isReady && businessId) {
            dispatch(getBusinessFollowersThunk())
        }
    }, [dispatch, isReady, startTime, businessId])


    useObserver(
        element,
        fetching, setFetchingFollowers,
        nextToken, getBusinessFollowersByNexToken
    )


    return (
        <div className={layoutClasses.Container}>
            <BusinessSidebar isOffset/>
            <div className={layoutClasses.Main}>
                <Topbar/>
                <div className={classes.Container}>
                    <PageTitle title={'Followers'}/>
                    <TimeFrameDropdown
                        onStartDateSelected={handleTimeFrameClick}
                        onClick={handleTimeFrameDropdownClick}
                    />
                    <TableWrapper>
                        <TableHeader className={classes.Header}>
                            <div className={classes.Username}>
                                <span>Username</span>
                            </div>
                            <div className={classes.Comments}>
                                <span>Comments</span>
                            </div>
                            <div className={classes.Shares}>
                                <span>Shares</span>
                            </div>
                            <div className={classes.Mentions}>
                                <span>Mentions</span>
                            </div>
                            <div className={classes.Likes}>
                                <span>Likes</span>
                            </div>
                        </TableHeader>
                        {/*ITEMS*/}
                        {
                            followers?.map((follower, index, array) => {
                                return (<Fragment key={follower.id}>
                                        <TableItem className={classes.FollowerItem}>
                                            <div className={classes.Username}>
                                                <Avatar
                                                    className={classes.Logo}
                                                    name={follower?.firstname}
                                                    lastname={follower?.lastname}
                                                    img={follower?.avatar?.url}
                                                />
                                                <span>{follower?.firstname} {follower?.lastname}</span>
                                            </div>
                                            <div className={classes.Comments}>
                                                <span className={classes.Title}>Comments</span>
                                                <span>{follower?.comentsCount || '-'}</span>
                                            </div>
                                            <div className={classes.Shares}>
                                                <span className={classes.Title}>Shares</span>
                                                <span>{follower?.followerUser?.businessFollowerStats?.sharesCount || '-'}</span>
                                            </div>
                                            <div className={classes.Mentions}>
                                                <span className={classes.Title}>Mentions</span>
                                                <span>{follower?.followerUser?.businessFollowerStats?.mentionsCount || '-'}</span>
                                            </div>
                                            <div className={classes.Likes}>
                                                <span className={classes.Title}>Likes</span>
                                                <span>{follower?.followerUser?.businessFollowerStats?.likesCount || '-'}</span>
                                            </div>
                                        </TableItem>
                                        {
                                            returnObservableItem(array.length, LIMIT, index, setElement)
                                        }
                                    </Fragment>
                                )
                            })
                        }
                        {
                            followers?.length === LIMIT && <div ref={setElement}/>
                        }
                        {
                            fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                        }
                        {/*ITEMS*/}
                    </TableWrapper>
                </div>
            </div>
        </div>
    );
};

export default Followers;