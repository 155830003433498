import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { mainGetBusinessesExcludeNotOwnedByCurrentUser } from '../../api/api'

// fetch businesses from backend using an async thunk
export const fetchManagedBusinesses = createAsyncThunk('managedBusinesses/fetchManagedBusinesses', async () => {
	const resp = await mainGetBusinessesExcludeNotOwnedByCurrentUser()
	return resp.data
})

// slice initial state
const initialState = {
	businesses: [],
	status: 'idle',
	error: null,
	currentBusinessId: undefined,
	isShowCreateBusiness: false,
}

// slice
const managedBusinessesSlice = createSlice({
	name: 'managedBusinesses',
	initialState,
	reducers: {
		updatedCurrentBusinessId(state, action) {
			console.debug('updatedCurrentBusinessId action: ')
			console.debug(action)
			state.currentBusinessId = action.payload.currentBusinessId
		},
		setCurrentBusinessLogo(state, action) {
			if (state.currentBusinessId) {
				state.businesses.find((item) => {
					return item.id === state.currentBusinessId
				}).logo = action.payload
			} else {
				state.businesses[0].logo = action.payload
			}
		},
		setCurrentBusinessName(state, action) {
			if (state.currentBusinessId) {
				state.businesses.find((item) => {
					return item.id === state.currentBusinessId
				}).name = action.payload
			} else {
				state.businesses[0].name = action.payload
			}
		},
		setCurrentBusinessAddress(state, action) {
			if (state.currentBusinessId) {
				state.businesses.find((item) => {
					return item.id === state.currentBusinessId
				}).address = action.payload
			} else {
				state.businesses[0].address = action.payload
			}
		},
		setIsShowCreateBusiness(state, action) {
			state.isShowCreateBusiness = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchManagedBusinesses.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(fetchManagedBusinesses.fulfilled, (state, action) => {
			state.status = 'succeeded'
			state.businesses = action.payload.businesses ?? []
			if (action.payload.length === 0) {
				state.isShowCreateBusiness = true
			}
			if (!state.currentBusinessId) {
				state.currentBusinessId = action.payload.businesses?.[0].id
			}
			state.error = null
		})
		builder.addCase(fetchManagedBusinesses.rejected, (state, action) => {
			state.status = 'failed'
			state.businesses = []
			state.error = action.error.message
		})
	},
})

export const {
	updatedCurrentBusinessId,
	setCurrentBusinessLogo,
	setCurrentBusinessName,
	setCurrentBusinessAddress,
	setIsShowCreateBusiness,
} = managedBusinessesSlice.actions

export default managedBusinessesSlice.reducer

// === Selectors (getters) ===

export const selectManagedBusinesses = (state) => state.managedBusinesses.businesses

export const selectCurrentBusiness = (state) => {
	console.debug('[managedBusinessesSlice] selectCurrentBusiness')
	if (state.managedBusinesses.currentBusinessId) {
		// a business is selected
		console.debug('looking for bid = ' + state.managedBusinesses.currentBusinessId)
		return state.managedBusinesses.businesses.find((b) => b.id === state.managedBusinesses.currentBusinessId)
	} else if (state.managedBusinesses.businesses.length > 0) {
		// nothing is selected but there's at least one business
		console.debug('getting first business')
		return state.managedBusinesses.businesses[0]
	}
	// no businesses in store
	console.debug('no businesses in store')
	return undefined
}

export const selectCurrentBusinessId = (state) => {
	if (state.managedBusinesses.currentBusinessId) {
		return state.managedBusinesses.currentBusinessId
	} else if (state.managedBusinesses.businesses?.length > 0) {
		return state.managedBusinesses.businesses[0].id
	}
	// no businesses in store
	console.debug('no businesses in store')
	return undefined
}
export const selectIsShowCreateBusiness = (state) => state.managedBusinesses.isShowCreateBusiness
