import React from 'react';
import ImgAndButtons from "./ImgAndButtons/ImgAndButtons";
import PostTextAndComments from "./PostTextAndComments/PostTextAndComments";
import classes from './PostWithComments.module.scss'
import {useSelector} from "react-redux";
import {selectActivePost} from "../../../../store/reducers/postsReducer";
import ModalOverlay from "../../../../submodules/naoo-web-components/Components/ModalOverlay/ModalOverlay";
import SwipeWrapper from "../../../../submodules/naoo-web-components/Components/Draggable/SwipeWrapper";

const PostWithComments = ({onClose}) => {
    const post = useSelector(selectActivePost)
    const img = post?.media?.[0]?.url

    return (
        <ModalOverlay onClose={onClose}>
            <SwipeWrapper handler={onClose}>
                <div
                    className={`${classes.Container} ${img ? classes.ContainerWithImg : classes.ContainerWithoutImg}`}
                >
                    {
                        img &&
                        <ImgAndButtons post={post}/>
                    }
                    <PostTextAndComments post={post}/>
                </div>
            </SwipeWrapper>
        </ModalOverlay>

    );
};

export default PostWithComments;