import React, { useEffect } from 'react'
import classes from './Offers.module.scss'
import layoutClasses from '../Layout.module.scss'
import BusinessSidebar from '../Sidebar/BusinessSidebar'
import Topbar from '../Topbar/Topbar'
import PageTitle from '../../components/PageTitle/PageTitle'
import {
  fetchDashboard,
  selectDashboard,
  selectDashboardIsObsolete,
  selectDashboardStatus,
} from '../../../store/reducers/dashboardSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentBusiness } from '../../../store/reducers/managedBusinessesSlice'
import { ROUTES } from '../../../submodules/naoo-web-components/Shared/constants'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import OffersList from '../Dashboard/UI/Offers/Offers'
import { withAnalytics } from '../../shared/utility/withAnalytics'
import { Button } from '../../../submodules/naoo-web-components/Components/Button/Buttons'

const Offers = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const dashboardData = useSelector(selectDashboard)
  const dashboardStatus = useSelector(selectDashboardStatus)
  const dashboardIsObsolete = useSelector(selectDashboardIsObsolete)
  const currentBusiness = useSelector(selectCurrentBusiness)

  useEffect(() => {
    if (
      (currentBusiness && dashboardStatus === 'idle') ||
      (currentBusiness &&
        dashboardData &&
        dashboardData.businessId !== currentBusiness.id &&
        dashboardStatus !== 'loading') ||
      (currentBusiness &&
        (dashboardStatus === 'idle' || dashboardStatus === 'succeeded') &&
        dashboardIsObsolete === true)
    ) {
      dispatch(
        fetchDashboard({
          businessId: currentBusiness.id,
        })
      )
    }
  }, [currentBusiness, dashboardData, dashboardIsObsolete, dashboardStatus, dispatch])

  const match = useMatch(ROUTES.ADMIN_DASHBOARD.OFFERS)

  const handleNewOfferButtonClick = withAnalytics('dashboards.business.sidebar.offers_new_offer_btn_clicked', () => {
    navigate(ROUTES.ADMIN_DASHBOARD.OFFERS + '/create')
  })

  return (
    <div className={layoutClasses.Container}>
      <BusinessSidebar disabled={!match}>
        <Button disabled={!match} onClick={handleNewOfferButtonClick}>
          New Offer
        </Button>
      </BusinessSidebar>

      <div className={layoutClasses.Main}>
        <Topbar />
        <div className={classes.Container}>
          <PageTitle title='Offers' subtitle='Your business and your offers are seen by all naoo users on the map' />
          <Outlet />
          {match && (
            <OffersList
              onOfferUpdated={(completionHandler) => {
                dispatch(
                  fetchDashboard({
                    businessId: currentBusiness.id,
                  })
                ).then(completionHandler())
              }}
              createNewOfferClicked={() => {
                navigate(ROUTES.ADMIN_DASHBOARD.OFFERS + '/create')
              }}
              offers={
                dashboardData && dashboardData.offerPerformances.length > 0 ? dashboardData.offerPerformances : null
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Offers
