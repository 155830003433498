import React from 'react';
import {useSelector} from "react-redux";
import CreateOrUpdatePost from "./CreateOrUpdatePost";
import {selectActivePost} from "../../../../store/reducers/postsReducer";

const UpdatePostContainer = () => {
    const post = useSelector(state => selectActivePost(state))
    return (
        <CreateOrUpdatePost post={post}/>
    );
};

export default UpdatePostContainer;