import React from 'react';
import LocationIcon from '../../../../shared/UI/assets/location-icon.svg'
import NaooLogoIcon from '../../../../shared/UI/assets/noologo.svg'
import BackgroundImage from '../../../../shared/UI/assets/partner-card-text-bg.png'
import PreviewBackground from '../../../../shared/UI/assets/offer-image-placeholder.svg'
import classes from './BusinessPreview.module.css'

const BusinessPreview = ({name, logo, cover}) => {
    return (
        <div className={classes.Container}>
            {(cover !== '' && cover)
                ? <img className={classes.Cover} src={cover?.url ? cover.url : cover} alt=""/>
                : <img src={PreviewBackground} alt=""/>
            }
            {(logo !== '' && logo)
                ? <img className={classes.Logo} src={logo?.url ? logo.url : logo} alt=""/>
                : <span className={classes.FirstLetter}>
                    {name ?name.toUpperCase()[0] : ''}
                    </span>
            }
            <div className={classes.BottomContainer} style={{backgroundImage: `url("${BackgroundImage}")`}}>
                <p className={classes.Text}>Earn naoo points with each purchase
                    at <strong>{name}</strong></p>
                <div className={classes.InfoContainer}>
                    <img src={LocationIcon} alt=""/>
                    <span>120 m</span>
                    <span className={classes.Dot}>•</span>
                    <span>1 CHF = 1</span>
                    <img className={classes.NaooIcon} src={NaooLogoIcon} alt=""/>
                </div>
            </div>

        </div>
    );
};

export default BusinessPreview;
