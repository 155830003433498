import React from 'react'
import classes from './OfferKpisTable.module.scss'
import {mainDeleteOffer, mainUpdateOffer} from '../../../../../../api/api'
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../../../submodules/naoo-web-components/Shared/constants";
import {useDispatch, useSelector} from "react-redux";
import {fetchOfferComments, setActiveOffer} from "../../../../../../store/reducers/offerReducer";
import MoreDropdown from "../../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {getOfferById} from "../../../../../../store/reducers/editOfferSlice";
import {fetchDashboard} from "../../../../../../store/reducers/dashboardSlice";
import {selectCurrentBusinessId} from "../../../../../../store/reducers/managedBusinessesSlice";
import {numberWithSpaces} from "../../../../../shared/utility/numberWithSpaces";
import {withAnalytics} from "../../../../../shared/utility/withAnalytics";
import TableHeader from "../../../../../components/Table/TableHeader/TableHeader";
import TableItem from "../../../../../components/Table/TableItem/TableItem";
import Switch from "../../../../../../submodules/naoo-web-components/Components/Switch/Switch";


const OfferKpisTable = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const currentBusinessId = useSelector(selectCurrentBusinessId)

    const handleIsActiveButtonClick = async (offerId, newIsActiveState) => {
        await mainUpdateOffer(
            offerId,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            newIsActiveState
        )
    }

    const handleOfferItemClick = (offer) => withAnalytics(
        'dashboards.business.offers.offer_clicked',
        () => {
            dispatch(setActiveOffer(offer))
            dispatch(fetchOfferComments({
                id: offer.id
            }))
            navigate(`${ROUTES.ADMIN_DASHBOARD.OFFERS}/${offer.id}/comments`)
        })

    const handleActiveButtonClick = (offerData) => withAnalytics(
        'dashboards.business.offers.offer_is_active_btn_clicked',
        () => {
            handleIsActiveButtonClick(offerData.offer.id, !offerData.offer.isActive)
                .then(()=>{
                    dispatch(fetchDashboard({
                        businessId:currentBusinessId
                    }))
                })
        })

    const handleMoreButtonClick = withAnalytics(
        'dashboards.business.offers.offer_more_btn_clicked',
    )

    const handleMoreMenuEditClick = (offerData) => withAnalytics(
        'dashboards.business.offers.offer_more_menu_edit_btn_clicked',
        () => {
            dispatch(getOfferById(offerData.offer.id))
            navigate(`${ROUTES.ADMIN_DASHBOARD.OFFERS}/${offerData.offer.id}/update`)
        })

    // const handleMoreMenuShowStatsClick = (offerData) => withAnalytics(
    //     'dashboards.business.offers.offer_more_menu_stats_btn_clicked',
    //     () => {
    //         dispatch(setOfferForStats(offerData.offer.id))
    //         navigate(`${ROUTES.ADMIN_DASHBOARD.OFFERS}/${offerData.offer.id}/stats`)
    //     })

    const handleMoreMenuDeleteItemClick = (offerData) => withAnalytics(
        'dashboards.business.offers.offer_more_menu_delete_offer_btn_clicked',
        async () => {
            await mainDeleteOffer(offerData.offer.id)
            dispatch(fetchDashboard({businessId: currentBusinessId}))
        })

    return (
        <>
            <TableHeader className={classes.Header}>
                <div className={classes.Name}>
                    <span>Offer name</span>
                </div>
                <div className={classes.Impressions}>
                    <span>Impressions</span>
                </div>
                <div className={classes.Walkins}>
                    <span>Walk-ins</span>
                </div>
                <div className={classes.Reach}>
                    <span>Reach</span>
                </div>
                <div className={classes.Engagement}>
                    <span>Engagement</span>
                </div>
                <div className={classes.Active}>
                    <span>State</span>
                </div>
            </TableHeader>
            {
                props?.offers.map((offerData) => {
                    return (
                        <TableItem
                            className={classes.Item}
                            key={offerData.offer.id}
                            onClick={handleOfferItemClick(offerData.offer)}
                        >
                            {
                                (offerData.offer.media && offerData.offer.media.length > 0) &&
                                <img
                                    alt=""
                                    src={offerData.offer.media[0].url}
                                    className={`${classes.Logo} ${!offerData.offer.isActive ? classes.Inactive : ''}`}
                                />
                            }
                            <div className={`${classes.Name} ${!offerData.offer.isActive ? classes.Inactive : ''}`}>
                                {
                                    !offerData.offer.isActive && <span>Inactive</span>
                                }
                                <span>{offerData.offer.title}</span>
                            </div>
                            <div className={classes.Impressions}>
                                <span className={`${classes.Label}`}>Impressions</span>
                                <span>{offerData.kpis.impressions || '-'}</span>
                            </div>
                            <div className={classes.Walkins}>
                                <span className={`${classes.Label} ${classes.LabelSmall}`}>Walkins</span>
                                <span>{numberWithSpaces(offerData.kpis.walkins)}</span>
                            </div>
                            <div className={classes.Reach}>
                                <span className={`${classes.Label} ${classes.LabelSmall}`}>Reach</span>
                                <span> {numberWithSpaces(offerData.kpis.reach)}</span>
                            </div>
                            <div className={classes.Engagement}>
                                <span className={`${classes.Label}`}>Engagement</span>
                                <span>{numberWithSpaces(offerData.kpis.pointsClaimed)}</span>
                            </div>
                            <div className={classes.Active}>
                                <Switch checked={offerData.offer.isActive} onChange={handleActiveButtonClick(offerData)} onClick={(event)=>{event.stopPropagation()}}/>
                            </div>
                            <div className={classes.More}>
                                <MoreDropdown onClick={handleMoreButtonClick}>
                                    <MoreItem
                                        text={'Edit offer'}
                                        onClick={handleMoreMenuEditClick(offerData)}
                                    />
                                    {/*<MoreItem*/}
                                    {/*    text={'Show statistics'}*/}
                                    {/*    onClick={handleMoreMenuShowStatsClick(offerData)}*/}
                                    {/*/>*/}
                                    <MoreItem
                                        text={'Delete offer'}
                                        danger
                                        onClick={handleMoreMenuDeleteItemClick(offerData)}
                                    />
                                </MoreDropdown>
                            </div>
                        </TableItem>
                    )
                })
            }
        </>
    )
}

export default OfferKpisTable