import React, {useState} from 'react';
import classes from "../CreateBusines.module.scss";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {setEmail, setPhone, setWebsite} from "../../../../store/reducers/businessSlice";
import {regexPhone} from "../../../shared/utility/phoneNumberValidation";
import {regexWebsite} from "../../../shared/utility/websiteValidation";
import {CREATE_BUSINESS_STEP_NAMES} from "../CreateBusiness";
import {withAnalytics} from "../../../shared/utility/withAnalytics";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import {BackButton, Button} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import {yupResolver} from "@hookform/resolvers/yup";

const Step2 = ({business, setStep}) => {

    const [inputs, setInputs] = useState({
        website: '',
        phone: '',
        email: ''
    })

    const inputHandler = (e) => {
        const temp = {
            ...inputs,
            [e.target.name]: e.target.value
        }
        setInputs(temp)
    }


    const schema = yup.object().shape({
        website: yup.string().test('website-test', 'Enter valid website', function (value) {
            if (value.length === 0) {
                return true
            }
            return regexWebsite.test(value)
        }),
        phone: yup.string().test('test-name', 'Enter Valid Phone',
            function (value) {
                if (value.length === 0) {
                    return true
                }
                return regexPhone.test(value)
            }),
        email: yup.string().email()
    })

    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema)
    })

    const watchWebsite = watch('website', business.website)

    const formSubmit = withAnalytics(
        'dashboards.business.create_business.contacts_page.continue_btn_clicked',
        (data) => {
            dispatch(setWebsite(data.website))
            dispatch(setPhone(data.phone))
            dispatch(setEmail(data.email))
            setStep(CREATE_BUSINESS_STEP_NAMES.LOGO)
        }
    )

    const dispatch = useDispatch()
    return (
        <form className={classes.BusinessForm} onSubmit={handleSubmit(formSubmit)}>
            <Input
                containerClassName={classes.InputContainer}
                label={'Website'}
                register={register('website')}
                type="text"
                placeholder={'Your website link'}
                defaultValue={business.website}
                onChange={inputHandler}
                maxLength={255}
                length={inputs.website.length}
                error={errors.website}
            />

            <Input
                containerClassName={classes.InputContainer}
                label={'Phone number'}
                register={register('phone')}
                type="phone"
                placeholder={'Enter your phone number'}
                defaultValue={business.phone}
                onChange={inputHandler}
                maxLength={64}
                length={inputs.phone.length}
                error={errors.phone}
            />

            <Input
                containerClassName={classes.InputContainer}
                label={'Email'}
                register={register('email')}
                type="text"
                placeholder={'Enter your email'}
                defaultValue={business.email}
                onChange={inputHandler}
                maxLength={512}
                length={inputs.email.length}
                error={errors.email}
            />

            <div className={classes.ButtonsContainer}>
                <BackButton onClick={()=>setStep(CREATE_BUSINESS_STEP_NAMES.NAME)}>
                    Back to Name & Category
                </BackButton>
                <Button
                    className={classes.Button}
                    type={'submit'}
                    disabled={(
                        errors.website ||
                        errors.email ||
                        errors.phone ||
                        watchWebsite?.length === 0
                       )
                        ? 'disabled' : ""
                    }
                >
                    Continue
                </Button>
            </div>
        </form>
    );
};

export default Step2;
