import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentMethodForm from './PaymentMethodForm'
import ModalOverlay from '../../../../submodules/naoo-web-components/Components/ModalOverlay/ModalOverlay'
import styles from './PaymentMethodDialog.module.scss'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const PaymentMethodDialog = ({ onClose, onCreate, onError, handleSetLoading }) => {
  return (
    <ModalOverlay onClose={onClose}>
      <div className={styles.Container}>
        <div className={styles.ModalTitle}>Payment Method Details</div>
        <div className={styles.ModalText}>Please provide payment method details to create a new subscription.</div>
        <Elements stripe={stripePromise}>
          <PaymentMethodForm
            onError={onError}
            onCreate={onCreate}
            onClose={onClose}
            handleSetLoading={handleSetLoading}
          />
        </Elements>
      </div>
    </ModalOverlay>
  )
}

export default PaymentMethodDialog
