import {sendImage} from "../../../../submodules/naoo-web-components/Shared/utility/sendImage";
import {createMediaPresignedUrl, getMedia} from "../../../../api/api";

export const IMPORT_ERROR_TYPES = {
    FILE_EMPTY: 'The file is empty',
    INVALID_TABLE_FORMAT: 'Invalid table format',
}

export const getArrayOfMediaId = async (media) => {
    const mediaPromiseArray = media
        .split('\n')
        .map(async item => {
            let mediaId
            if (item[0] === '{') {
                mediaId = JSON.parse(item.trim()).id
            }
            else {
                const response = await sendImage(item, createMediaPresignedUrl, getMedia)
                mediaId = response.mediaId
            }
            return mediaId
        })
    return await Promise.all(mediaPromiseArray)
}

export const validateStructure = (data, columnsNames) => {
    const dataKeys = Object.keys(data)
    return columnsNames.reduce((acc, current) => acc * dataKeys.includes(current), true)
}