import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom'
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {ErrorsBlock, USER_DATA} from "../SignUp";
import {Button} from "../../../Components/Button/Buttons";
import Input from "../../../Components/Input/Input";
import ApiClient from "../../../Api/client";
import {ROUTES} from "../../../Shared/constants";
import classes from '../../Auth.module.scss'
import signUpClasses from '../SingUp.module.scss'
import {yupResolver} from "@hookform/resolvers/yup";

const Step4 = ({data, timer}) => {
    const api = new ApiClient()
    const navigate = useNavigate()
    const [confirmError, setConfirmError] = useState({})
    const schema = yup.object().shape({
        code: yup.string().required('Code is a required field'),
    });

    const {register, handleSubmit, formState: { errors }} = useForm({
        mode: 'onChange ',
        resolver: yupResolver(schema)
    });


    const onSubmit = (formData) => {
        api.confirmRegistration(formData.code.trim(), data.user,
            (res) => {
                localStorage.removeItem(USER_DATA)
                api.signIn(
                    data.phone || data.email,
                    data.password,
                    () => {
                        timer.start()
                    },
                    (result) => {
                        navigate(ROUTES.BUSINESS_DASHBOARD.CREATE_BUSINESS, {replace: true})
                    },
                    (err) => {
                        setConfirmError(err)
                        console.log(`auth failed, err: ${err.message}`)
                    }
                )
            },
            (err) => {
                setConfirmError(err)
            })
    }

    const resendValidationClick = () => {
        api.resendConfirmationCode(data.user, () => {
            const time = new Date();
            time.setSeconds(time.getSeconds() + 30);
            timer.restart(time)
        }, (err) => {
            setConfirmError(err)
        })
    }

    return (
        <>
            <div className={classes.AuthForm}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <span className={classes.FormTitle}>
                        Enter the code sent to your email
                    </span>
                    <Input
                        register={register('code')}
                        containerClassName={classes.Input}
                        type={'text'}
                        label={'Validation code'}
                        placeholder={'Validation code'}
                        error={errors.code}
                    />
                    <Button disabled={errors.code} className={signUpClasses.Button}>Continue</Button>
                </form>
                {
                    timer.isRunning
                        ? <span className={signUpClasses.Text}>Code sent to {data.email || data.phone} <br/>
                             Wait for {timer.seconds} sec to retry
                        </span>
                        : <span className={signUpClasses.Text}>Didn’t get the code?
                            <span className={signUpClasses.TextButton}
                                  onClick={resendValidationClick}
                            >
                                Tap here to resend
                            </span>
                            to {data.email || data.phone}
                         </span>
                }
            </div>
            <ErrorsBlock formErrors={errors} error={confirmError}/>
        </>
    );
};

export default Step4;
