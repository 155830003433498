import React from 'react';
import classes from "./ImgAndButtons.module.scss";
import PostButtons from "../PostButtons/PostButtons";
import Slider from "../../../../../submodules/naoo-web-components/Components/Slider/Slider";

const ImgAndButtons = ({post}) => {
    return (
        <div className={classes.ImgPlusButtons}>
            <div className={classes.Img}>
                <Slider media={post.media}/>
            </div>

            <div className={classes.ButtonsContainer}>
                <PostButtons post={post}/>
            </div>
        </div>
    );
};

export default ImgAndButtons;