import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import classes from './Post.module.scss'
import {
    createDeepLink,
    createPostThunk,
    deletePostThunk, selectDeepLink,
    selectStatus,
    updatePostThunk
} from "../../../../store/reducers/postsReducer";
import spinnerClasses from '../../../../submodules/naoo-web-components/Shared/Spinner.module.scss'
import {selectTags} from "../../../../store/reducers/tagsReducer";
import {fetchUserChannelRolesForChannels, selectChannels} from "../../../../store/reducers/channelsReducer";
import {ROUTES} from "../../../../submodules/naoo-web-components/Shared/constants";
import {
    FullscreenPreloader,
    Preloader
} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import TagsMultiselect
    from "../../../../submodules/naoo-web-components/Components/Tags/TagsMultiselect/TagsMultiselect";
import Radio from "../../../../submodules/naoo-web-components/Components/Radio/Radio";
import {BackButton, Button, TextButton} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import PageTitle from "../../../components/PageTitle/PageTitle";
import QRCode from "qrcode.react";
import TextArea from "../../../../submodules/naoo-web-components/Components/TextArea/TextArea";
import {useMentionData} from "../../../../submodules/naoo-web-components/Shared/hooks/useMentionData";
import {withAnalytics} from "../../../shared/utility/withAnalytics";
import DateTimePicker from "../../../../submodules/naoo-web-components/Components/Date/DateTimePicker/DateTimePicker";
import Gallery from "../../../../submodules/naoo-web-components/Components/Gallery/Gallery";
import CheckBox from "../../../../submodules/naoo-web-components/Components/CheckBox/CheckBox";

const CreateOrUpdatePost = ({post}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tags = useSelector(selectTags)
    const status = useSelector(selectStatus)
    const channels = useSelector(selectChannels)
    const deepLink = useSelector(selectDeepLink)
    const [text, setText] = useState(post?.text || '')
    const [channel, setChannel] = useState(post?.channel || null)
    const [postTags, setPostTags] = useState(post?.tags || [])
    const [galleryItems, setGalleryItems] = useState(post?.media ?? [])
    const [settings, setSettings] = useState(post?.publicationDate ? 'schedule' : 'postNow')
    const [dateTime, setDateTime] = useState(post?.publicationDate ? new Date(post?.publicationDate) : null)

    const {getMentions, transformedText} = useMentionData(text, post?.mentions)

    useEffect(() => {
        if (channels.length === 0) {
            dispatch(fetchUserChannelRolesForChannels())
        }
    }, [dispatch, channels.length])

    useEffect(() => {
        if (post?.id) {
            dispatch(createDeepLink({
                postId: post.id
            }))
        }
    }, [dispatch, post?.id])

    const handleText = (event) => {
        setText(event.target.value)
    }

    const handleTags = (tags) => {
        setPostTags(tags)
    }

    const handleDateTime = (value, flag) => {
        setDateTime(value)
        const isTimeClick = flag === 'TIME'
        withAnalytics(
            post
                ? isTimeClick ? 'dashboards.business.edit_post.schedule_time_dropdown_item_clicked' : 'dashboards.business.edit_post.schedule_date_clicked'
                : isTimeClick ? 'dashboards.business.create_post.schedule_time_dropdown_item_clicked' : 'dashboards.business.create_post.schedule_date_clicked'
        )()
    }

    const createPostHandler = withAnalytics(
        post ? 'dashboards.business.edit_post.save_btn_clicked' : 'dashboards.business.create_post.save_btn_clicked',
        () => {
            const data = {
                text: transformedText,
                tagIds: postTags?.map(item=>item.id),
                galleryItems,
                mentions: getMentions()
            }
            if (dateTime) data.publicationDate = dateTime.toISOString()
            if (channel?.id) data.channelId = channel.id
            if (post) {
                dispatch(updatePostThunk({
                        id: post.id,
                        post: data
                    })
                )
                    .then((response) => {
                        if (response.meta.requestStatus === 'fulfilled') {
                            navigate(-1)
                        }
                    })
            } else {
                dispatch(createPostThunk(data))
                    .then((response) => {
                        if (response.meta.requestStatus === 'fulfilled') {
                            navigate(-1)
                        }
                    })
            }
        })

// eslint-disable-next-line
    const deletePostHandler = () => {
        if (post) {
            dispatch(deletePostThunk(post.id))
                .then(() => {
                    navigate(ROUTES.ADMIN_DASHBOARD.POSTS)
                })
                .catch((err) => console.error(err))
            return
        }
        navigate(-1)
    }

    const handlePrintQrCodeClick = withAnalytics(
        'dashboards.business.edit_post.print_qr_code_btn_clicked',
        () => {
            const canvas = document.getElementById("qr-gen");
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            const win = window.open();
            win.document.write(`<img width="300" height="300" src='${pngUrl}'>`);
            setTimeout(() => {
                win.print();
                win.close()
            })
        })

    const handleAddGalleryItems = (urlList) => {
        const newItems = urlList.map((item) => (
            {
                url: item
            }))
        setGalleryItems((prevState) => [...prevState, ...newItems])
    }

    const deleteGalleryItem = withAnalytics(
        post ? 'dashboards.business.edit_post.image_dropzone_delete_btn_clicked' : 'dashboards.business.create_post.image_dropzone_delete_btn_clicked',
        (item) => {
            setGalleryItems((prevState) => {
                return prevState.filter((media) => {
                    if (media.id) return media.id !== item
                    return media.url !== item.url
                })
            })
    })

    const handleDiscardButtonClick = withAnalytics(
        post ? 'dashboards.business.edit_post.discard_btn_clicked' : 'dashboards.business.create_post.discard_btn_clicked',
        () => navigate(-1)
    )

    const handlePostNowClick = withAnalytics(
        post ? 'dashboards.business.edit_post.post_now_btn_clicked' : 'dashboards.business.create_post.post_now_btn_clicked',
        () => {
            setSettings('postNow')
        })


    const handleScheduleClick = withAnalytics(
        post ? 'dashboards.business.edit_post.schedule_btn_clicked' : 'dashboards.business.create_post.schedule_btn_clicked',
        () => {
            setSettings('schedule')
        })

    const handleDateTimePickerClick = withAnalytics(
        post ? 'dashboards.business.edit_post.schedule_field_clicked' : 'dashboards.business.create_post.schedule_field_clicked',
    )

    // const handleDateTimePickerClick = withAnalytics(
    //     post ? 'dashboards.business.edit_post.schedule_field_clicked' : 'dashboards.business.create_post.schedule_field_clicked',
    // )
    //
    // const handleDateTimePickerTimeClick = withAnalytics(
    //     post ? 'dashboards.business.edit_post.schedule_time_field_clicked' : 'dashboards.business.create_post.schedule_time_field_clicked',
    // )
    //
    // const handleDateTimePickerDateClick = withAnalytics(
    //     post ? 'dashboards.business.edit_post.schedule_date_clicked' : 'dashboards.business.create_post.schedule_date_clicked'
    // )

    const handleChannelClick = (item) => withAnalytics(
        post ? 'dashboards.business.edit_post.channels_list_channel_checkbox_clicked' : 'dashboards.business.create_post.channels_list_channel_checkbox_clicked',
         () => {
            if (item.id === channel?.id) {
                setChannel(null)
                return
            }
            setChannel(item)
        })

    const handleOnDrop = withAnalytics(
        post ? 'dashboards.business.edit_post.image_dropzone_drag_n_drop' : 'dashboards.business.create_post.image_dropzone_drag_n_drop',
    )

    const handleDropzoneClick = withAnalytics(
        post ? 'dashboards.business.edit_post.image_dropzone_btn_clicked' : 'dashboards.business.create_post.image_dropzone_btn_clicked',
    )

    return (
        <>
            {
                status === 'loading' &&
                <FullscreenPreloader
                    overlayClassName={spinnerClasses.lightBlurPreloader}
                    spinnerClassName={spinnerClasses.spinnerSize}
                />
            }
            <div className={classes.Container}>
                <BackButton onClick={handleDiscardButtonClick}>Discard changes</BackButton>
                <div className={classes.TitleContainer}>
                    <PageTitle title={post ? 'Edit post' : 'New post'}/>
                </div>
                <div className={classes.FormContainer}>
                    <div className={classes.MainContainer}>
                        <div className={classes.Section}>
                            <div className={classes.Title}>
                                <span>Post</span>
                            </div>
                            <TextArea
                                className={classes.Input}
                                label={'Text'}
                                placeholder={'Your Post Text'}
                                name={'text'}
                                value={text}
                                mentions={post?.mentions}
                                onChange={handleText}
                            />
                            <div className={`${classes.Label} ${classes.LabelMargin}`}>
                                <span>Tags</span>
                            </div>
                            <TagsMultiselect
                                initialValue={postTags}
                                options={tags}
                                onChange={handleTags}
                            />
                            <div className={classes.Buttons}>
                                <span className={classes.Subtitle}>Post settings</span>
                                <Radio
                                    className={classes.Left}
                                    name={'settings'}
                                    value={'postNow'}
                                    label={'Post Now'}
                                    checked={settings === 'postNow'}
                                    onChange={handlePostNowClick}
                                />
                                <Radio
                                    className={classes.Right}
                                    name={'settings'}
                                    value={'schedule'}
                                    label={'Schedule'}
                                    checked={settings === 'schedule'}
                                    onChange={handleScheduleClick}
                                />
                            </div>
                            {
                                settings === 'schedule' &&
                                <div className={classes.Schedule}>
                                    <DateTimePicker
                                        value={dateTime}
                                        onChange={handleDateTime}
                                        format={"dd MMMM yyyy 'at' HH:mm"}
                                        className={classes.ScheduleInput}
                                        placeholder={'Choose Date and Time'}
                                        minDate={new Date()}
                                        onInputClick={handleDateTimePickerClick}
                                    />
                                </div>
                            }
                        </div>
                        <div className={classes.Section}>
                            <div className={classes.Title}>
                                <span>Where to publish</span>
                            </div>
                            <div className={classes.ChannelsContainer}>
                                <div className={classes.Channel}>
                                    <CheckBox isChecked readOnly />
                                    <span className={classes.Name}>Business page</span>
                                </div>
                                {
                                    channels.map(item => (
                                        <div
                                            key={item.id}
                                            className={classes.Channel}
                                            onClick={handleChannelClick(item)}
                                        >
                                            <CheckBox isChecked={channel?.id === item?.id} readOnly onClick={(e)=>e.stopPropagation()}/>
                                            <span className={classes.Name}>{item?.title}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        {
                            post &&
                            <div className={classes.Section}>
                                <div className={classes.Title}>
                                    <span>Deeplink</span>
                                </div>
                                <Input
                                    label={'Deeplink'}
                                    placeholder={'Deeplink'}
                                    name={'text'}
                                    value={deepLink}
                                    copy={true}
                                    readOnly
                                />
                                <div className={`${classes.Label} ${classes.LabelMargin}`}>
                                    <span>QR-code</span>
                                </div>
                                <div className={classes.QrCodeContainer}>
                                    <div className={classes.Qr}>
                                        {
                                            status === 'loading'
                                                ? <Preloader/>
                                                : <QRCode
                                                    id={'qr-gen'}
                                                    size={120}
                                                    bgColor={'#F5F7FA'}
                                                    includeMargin={true}
                                                    value={deepLink}
                                                />
                                        }
                                    </div>
                                    <TextButton
                                        className={classes.Print}
                                        onClick={handlePrintQrCodeClick}
                                    >
                                        Print QR-code
                                    </TextButton>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={classes.PreviewContainer}>
                        <div className={classes.Section}>
                            <div className={classes.Title}>
                                <span>Attachments</span>
                            </div>
                            <div className={classes.GalleryContainer}>
                                <Gallery
                                    className={classes.Gallery}
                                    itemClassName={classes.ItemStyle}
                                    dropzoneClassName={!!galleryItems.length ? classes.GalleryDropzoneWithMedia : classes.GalleryDropzoneWithoutMedia}
                                    items={galleryItems}
                                    onAdd={handleAddGalleryItems}
                                    onDelete={deleteGalleryItem}
                                    handleOnDrop={handleOnDrop}
                                    handleDropzoneClick={handleDropzoneClick}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.SaveButtonContainer}>
                        <Button
                            onClick={createPostHandler}
                            disabled={!text}
                        >{post ? 'Save changes' : 'Publish post'}</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateOrUpdatePost;