import React, {useContext, useState} from 'react';
import classes from "./PostButtons.module.scss";
import {ReactComponent as Like} from "../../../../../submodules/naoo-web-components/Shared/UI/assets/like.svg";
import {ReactComponent as CommentIcon} from "../../../../../submodules/naoo-web-components/Shared/UI/assets/comment_Icon.svg";
import {ReactComponent as QRIcon} from "../../../../../submodules/naoo-web-components/Shared/UI/assets/qr.svg";
import {BUTTON_TYPES, Button} from "../../../../../submodules/naoo-web-components/Components/Button/Buttons";
import {ReactComponent as Check} from "../../../../../submodules/naoo-web-components/Shared/UI/assets/check.svg";
import {createDeepLink, creteLikeThunk, deleteLikeThunk} from "../../../../../store/reducers/postsReducer";
import {ROUTES} from "../../../../../submodules/naoo-web-components/Shared/constants";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {AnalyticsContext} from "../../../../../submodules/naoo-web-components/Shared/context/analyticsContext";
import {executeIfExist} from "../../../../../submodules/naoo-web-components/Shared/utility/utils";
import {Preloader} from "../../../../../submodules/naoo-web-components/Components/Preloader/Preloader";

const PostButtons = ({post}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const analyticsEvents = useContext(AnalyticsContext)

    const [isCopy, setIsCopy] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleLikeIconClick =  () => {
        executeIfExist(analyticsEvents?.like_btn_clicked)
        if (post?.userLiked) {
            dispatch(deleteLikeThunk(post.id))
        } else {
            dispatch(creteLikeThunk(post.id))
        }
    }

    const handleCommentIconClick =  () => {
        executeIfExist(analyticsEvents?.post_comment_btn_clicked)
    }

    const handleQrCodeClick = () => {
        executeIfExist(analyticsEvents?.show_qr_code_btn_clicked)
        navigate(`${ROUTES.ADMIN_DASHBOARD.POSTS}/${post.id}/qr`)
    }

    const handleCopyDeepLinkClick = () => {
        executeIfExist(analyticsEvents?.copy_link_btn_clicked)
        setIsLoading(true)
        dispatch(createDeepLink({
            postId: post.id
        }))
            .then(result => {
                return navigator.clipboard.writeText(result.payload.shortLink)
            })
            .then(() => {
                setIsCopy(true)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div className={classes.Buttons}>
            <div className={classes.SocialButtons}>
                <Like
                    className={post.userLiked ? classes.LikeActive : null}
                    width={20}
                    height={20}
                    onClick={handleLikeIconClick}
                /> <span>{post?.likesCount}</span>
                <CommentIcon
                    width={20}
                    height={20}
                    onClick={handleCommentIconClick}
                />
                <span>{post?.commentsCount}</span>
                <QRIcon
                    width={20}
                    height={20}
                    onClick={handleQrCodeClick}
                />
            </div>
            <Button
                variant={BUTTON_TYPES.NOT_ROUNDED}
                className={classes.CopyButton}
                onClick={handleCopyDeepLinkClick}
            >
                {
                    isLoading
                        ? <Preloader className={classes.LoadingIndicator}/>
                        : isCopy
                            ? <><Check height={18}/> LINK COPIED</>
                            : 'COPY LINK '
                }
            </Button>
        </div>
    );
};

export default PostButtons;