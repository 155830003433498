import React from 'react';
import classes from "../CreateBusines.module.scss";
import {setLogo, setLogoDimensions} from "../../../../store/reducers/businessSlice";
import {useDispatch} from "react-redux";
import {CREATE_BUSINESS_STEP_NAMES} from "../CreateBusiness";
import {withAnalytics} from "../../../shared/utility/withAnalytics";
import {BackButton, Button, TextButton} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import Dropzone from "../../../../submodules/naoo-web-components/Components/Dropzone/Dropzone";

const Step3 = ({logo, setStep}) => {
    const dispatch = useDispatch()

    const handleDropZoneChange = (acceptedFiles) => {
        const imgUrl = URL.createObjectURL(acceptedFiles[0])
        dispatch(setLogo(imgUrl))
        const img = new Image()
        img.src = imgUrl
        img.onload = function () {
            dispatch(setLogoDimensions([this.width, this.height]))
        }
    }

    const handleDropZoneClear = () => {
        dispatch(setLogo(''))
    }

    const handlePreviousStepButtonClick = () => {
        setStep(CREATE_BUSINESS_STEP_NAMES.CONTACTS)
    }

    const handleContinueButtonClick = withAnalytics(
        'dashboards.business.create_business.logo_page.continue_btn_clicked',
        () => {
            setStep(CREATE_BUSINESS_STEP_NAMES.COVER)
        })

    return (
        <>
            <p className={classes.Subtitle}>Let’s add your logo so customers can recognize you better.</p>
            <form className={classes.BusinessForm}>
                <div className={classes.DropzoneContainer}>
                    <Dropzone
                        media={logo}
                        onChange={handleDropZoneChange}
                        className={classes.OfferImageDropzone}
                        onClear={handleDropZoneClear}
                    />
                    {
                        logo &&
                        <div className={classes.PendingMediaInfo}>
                            <TextButton>
                                Remove image
                            </TextButton>
                        </div>
                    }
                </div>
                <div className={classes.ButtonsContainer}>
                    <BackButton
                        className={classes.BackButton}
                        onClick={handlePreviousStepButtonClick}
                    >
                        Back to Contacts
                    </BackButton>
                    <Button
                        className={classes.Button}
                        disabled={logo.length !== 0 ? '' : "disabled"}
                        onClick={handleContinueButtonClick}
                    >
                        Continue
                    </Button>
                </div>
            </form>
        </>

    );
};

export default Step3;