// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    authDomain: "naoo-1.firebaseapp.com",
    databaseURL: "https://naoo-1.firebaseio.com",
    projectId: "naoo-1",
    storageBucket: "naoo-1.appspot.com",
    messagingSenderId: "469305132768",
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? "notProvided",
    appId: process.env.REACT_APP_FIREBASE_APP_ID ?? "notProvided",
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? "notProvided"
}