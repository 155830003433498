import React, {useState} from 'react';
import classes from './AddComment.module.scss'
import TextArea from "../../../../../submodules/naoo-web-components/Components/TextArea/TextArea";
import {useMentionData} from "../../../../../submodules/naoo-web-components/Shared/hooks/useMentionData";

const AddComment = ({post, handlePostComment}) => {

    const [comment, setComment] = useState('')

    const {transformedText, getMentions} = useMentionData(comment)

    const handleCommentChange = (event) => {
        setComment(event.target.value)
    }

    const handlePostButtonClick = () => {
        handlePostComment(transformedText, getMentions(), setComment)
    }

    const handleKeyPress = (event) =>{
        if (event.key === "Enter"){
            handlePostComment(transformedText, getMentions(), setComment)
        }
    }

    return (
        <div className={classes.AddCommentContainer} onKeyPress={handleKeyPress}>
            <img className={classes.Logo} src={post?.business?.logo?.url} alt=""/>
            <TextArea
                className={classes.Input}
                type="COMMENT"
                name={'newComment'}
                placeholder={'Add a comment...'}
                value={comment}
                onChange={handleCommentChange}
            />
            {
                comment &&
                <button
                    className={classes.PostButton}
                    onClick={handlePostButtonClick}
                >
                    Post
                </button>
            }
        </div>
    );
};

export default AddComment;