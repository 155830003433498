import React, {Fragment, useState} from 'react';
import classes from "./OfferTextAndComments.module.scss";
import BusinessInfo from "../BusinessInfo/BusinessInfo";
import Comment from "../Comment/Comment";
import AddComment from "../AddComment/AddComment";
import {useDispatch, useSelector} from "react-redux";
import spinnerClasses from '../../../../../submodules/naoo-web-components/Shared/Spinner.module.scss'
import {useObserver} from "../../../../../submodules/naoo-web-components/Shared/hooks/useObserver";
import {returnObservableItem} from "../../../../../submodules/naoo-web-components/Shared/utility/utils";
import {Preloader} from "../../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import {
    createOfferCommentThunk,
    fetchOfferCommentsByNextToken,
    selectFetchingComments,
    selectNextTokenComments,
    setFetchingComments
} from "../../../../../store/reducers/offerReducer";
import MoreDropdown from "../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {mainDeleteOffer} from "../../../../../api/api";
import {fetchDashboard} from "../../../../../store/reducers/dashboardSlice";
import {selectCurrentBusinessId} from "../../../../../store/reducers/managedBusinessesSlice";
import {useNavigate} from "react-router-dom";
import {withAnalytics} from "../../../../shared/utility/withAnalytics";

const OfferTextAndComments = ({offer}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const LIMIT = 10
    const nextToken = useSelector(state => selectNextTokenComments(state))
    const fetching = useSelector(state => selectFetchingComments(state))

    const currentBusinessId = useSelector(selectCurrentBusinessId)
    const [element, setElement] = useState(null)


    useObserver(
        element,
        fetching, setFetchingComments,
        nextToken, fetchOfferCommentsByNextToken
    )
    const handlePostComment = (text, setComment)=>{
        dispatch(createOfferCommentThunk({
            id:offer.id,
            text,
            uid:offer.user.id,
            bid:offer.business.id
        }))
            .then((response)=>{
                if(response.meta.requestStatus === 'fulfilled'){
                    setComment('')
                }
            })
    }

    const handleAllCommentsClick = () =>{
        dispatch(fetchOfferCommentsByNextToken({
            id: offer.id,
            nextToken
        }))
    }

    const handleMoreIconClick = withAnalytics(
        'dashboards.business.offer.more_btn_clicked',
    )

    return (
        <>
            <div className={classes.Header}>
                <BusinessInfo business={offer?.business} createdAt={offer?.createdAt}/>
                <div className={classes.More}>
                    <MoreDropdown onClick={handleMoreIconClick}>
                        <MoreItem
                            text={'Delete offer'}
                            danger
                            onClick={ ()=>{
                                mainDeleteOffer(offer.id)
                                    .then(()=>{
                                        dispatch(fetchDashboard({businessId: currentBusinessId}))
                                        navigate(-1)
                                    })

                            }}
                        />
                    </MoreDropdown>
                </div>
            </div>
            <div className={classes.Comments}>
                <div className={classes.PostText}>
                    <span>{offer.text}</span>
                </div>
                {
                    ( offer?.commentsCount > LIMIT && offer?.comments?.length === LIMIT) &&
                    <div className={classes.ViewCommentsButton}>
                        <span onClick={handleAllCommentsClick}>View all {offer.commentsCount} comments</span>
                    </div>
                }

                <div className={classes.CommentsContainer}>
                    {
                        offer?.comments?.map((item, index, array)=>(
                            <Fragment key={item.id}>
                                <Comment
                                    key={item.id}
                                    comment={item}
                                />
                                {
                                    returnObservableItem(array.length, LIMIT, index, setElement )
                                }
                            </Fragment>
                        ))
                    }
                    {
                        fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                    }

                </div>
            </div>
            <AddComment offer={offer} handlePostComment={handlePostComment}/>
        </>
    );
};

export default OfferTextAndComments;