import React from 'react';
import classes from './PostPreview.module.scss'
import {useMentionData} from "../../../../submodules/naoo-web-components/Shared/hooks/useMentionData";

const PostPreview = (
    {
        post, mediaClass, textClass,
        repost, delayed
    }) => {
    const {getTextWithMentions} = useMentionData(post.text, post.mentions)

    return (
        <>

            <div className={`${textClass} ${delayed ? classes.Delayed : ''}`}>
                <div className={classes.MediaContainer}>
                    {
                        post?.media?.length &&
                        <video
                            className={`${mediaClass} ${delayed ? classes.Delayed : ''}`}
                            src={post?.media[0]?.url}
                            autoPlay={true}
                            muted={true}
                            poster={post?.media[0]?.url}
                            loop={true}
                        />
                    }
                </div>

                <div className={classes.Post}>
                    {
                        delayed &&
                        <div className={classes.NotPublished}>
                            <span>Not published yet</span>
                        </div>
                    }
                  <span>
                      {
                          getTextWithMentions()
                      }
                  </span>
                </div>
                {
                    repost &&
                    <div className={classes.Repost}>
                        <span>{repost}</span>
                    </div>
                }
            </div>
        </>
    );
};

export default PostPreview;