import React, {forwardRef} from 'react';
import classes from "./TableItem.module.scss";

const TableItem = forwardRef(({className, onClick = () => {}, children}, ref) => {
    return (
        <div
            className={`${classes.Container} ${className ?? ''}`}
            onClick={onClick}
            ref={ref}
        >
            {children}
        </div>
    );
});

export default TableItem;