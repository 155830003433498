import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {googleSheetApi} from "../../../../api/googleSheetApi";
import {Preloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import ModalWindow from "../../../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow";
import classes from './GoogleSheetModal.module.scss'
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import {createMessage, MESSAGES_TYPES} from "../../../../submodules/naoo-web-components/Components/Message/Message";
import {useDispatch, useSelector} from "react-redux";
import {addMessageThunk} from "../../../../submodules/naoo-web-components/Shared/reducers/messagesReducer";
import {postImportErrorMessages, useImportPosts} from "../../../shared/utility/importUtils/importPostsUtils";
import {ROUTES} from "../../../../submodules/naoo-web-components/Shared/constants";
import {fetchPosts} from "../../../../store/reducers/postsReducer";
import {selectCurrentBusinessId} from "../../../../store/reducers/managedBusinessesSlice";

const GoogleSheetModal = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const businessId = useSelector(selectCurrentBusinessId)

    const [url, setUrl] = useState('')
    const {
        isLoadingRead, setIsLoadingRead,
        setIsLoadingImport,
        postsFromImport, setPostFromImport,
        isHavePosts,
        loader,
        messageAfterLoad
    } = useImportPosts()

    const handleUrlChange = (event) => {
        setUrl(event.target.value)
    }

    const handleCloseClick = () => {
        navigate(-1)
    }

    const transformError = (error) => {
        return postImportErrorMessages[error.message] ?? createMessage({
            message:error.message,
            type: MESSAGES_TYPES.ERROR,
        })
    }

    const handleImportFromGoogleClick = () => {
        setIsLoadingRead(true)
        googleSheetApi.transformDataToArray(url)
            .then((response) => {
                setPostFromImport(response)
            })
            .catch((error) => {
                console.log(error)
                dispatch(addMessageThunk({
                    message: transformError(error)
                }))
            })
            .finally(() => {
                setIsLoadingRead(false)
            })
    }

    const handleConfirmImport = () => {
        setIsLoadingImport(true)
        googleSheetApi.importPosts(postsFromImport)
            .then((addCount)=> {
                const message = addCount > 0 ? `Posts updated. ${addCount} posts added` : `Posts updated`

                navigate(ROUTES.ADMIN_DASHBOARD.POSTS)
                dispatch(addMessageThunk({
                    message: createMessage({
                        message,
                        type: MESSAGES_TYPES.SUCCESS,
                    })
                }))
                dispatch(fetchPosts({businessId}))
            })
            .catch((error)=>{
                dispatch(addMessageThunk({
                        message: transformError(error)
                }))
                console.log(error)
            })
            .finally(()=>{
                setIsLoadingImport(false)
            })
    }

    const handleCancelClick = () => {
        setPostFromImport([])
    }

    useEffect(() => {
        return () => {
            googleSheetApi.signOut()
        }
    }, [dispatch])

    const isShowCopy = (!!url.length && !isLoadingRead)

    return (
        <>
            {loader}
            <ModalWindow
                isOpen={true}
                onClose={handleCloseClick}
                title={'Import from Google Sheet'}
                primaryButton={!isHavePosts ? 'Import from Google Sheet' : 'Ok, add posts'}
                disabledPrimary={!url.length}
                handlePrimaryClick={isHavePosts ? handleConfirmImport : handleImportFromGoogleClick}
                secondaryButton={`${isHavePosts ? 'Cancel': ''}`}
                handleSecondaryClick={handleCancelClick}
            >
                {
                    !isHavePosts
                        ? <>
                            <ol className={classes.List}>
                                <li>
                                    Download the <Link to="/data/posts.csv" target="_blank" download>template</Link>
                                </li>
                                <li>Enter new posts data</li>
                                <li>Add a link to your Google Sheet</li>
                                <li>All posts will be added to your profile automatically</li>
                            </ol>

                            <div className={classes.InputContainer}>
                                <Input
                                    placeholder={'Your Google Sheet URL'}
                                    value={url}
                                    onChange={handleUrlChange}
                                    error={!url.length}
                                    copy={isShowCopy}
                                />
                                {
                                    isLoadingRead &&
                                    <Preloader className={classes.Spinner}/>
                                }
                            </div>
                        </>
                        : messageAfterLoad
                }
            </ModalWindow>
        </>
    );
};

export default GoogleSheetModal;