import React from 'react'

import { useDispatch } from 'react-redux'

import classes from './OfferPricing.module.css'

const OfferPricing = (props) => {
  const dispatch = useDispatch()
  // const newOfferData = useSelector((state) => selectMutabletOffer(state))

  const discountPercentage =
    props.offer.price && props.offer.discountedPrice
      ? ((props.offer.price - props.offer.discountedPrice) / props.offer.price) * 100
      : 0

  const validateNumberInput = (event) => {
    const numbersRegexp = /^\d+(\.\d{0,2})?$/
    return !event.target.value || numbersRegexp.test(event.target.value)
  }

  const handleOriginalPriceInput = (event) => {
    console.debug('original price input changed: ', event.target.value)
    const isValidInput = validateNumberInput(event)
    if (isValidInput) {
      dispatch(
        props.updateOffer({
          price: event.target.value,
        })
      )
    } else {
      event.target.value = props.offer.price ? props.offer.price : 0
    }
  }

  const handleDiscountedPriceInput = (event) => {
    console.debug('discounted price input changed: ', event.target.value)
    const isValidInput = validateNumberInput(event)
    if (isValidInput) {
      dispatch(
        props.updateOffer({
          discountedPrice: event.target.value,
          discount: props.offer.price - event.target.value,
        })
      )
    } else {
      event.target.value = props.offer.discountedPrice ? props.offer.discountedPrice : 0
    }
  }

  return (
    <div className={classes.Container}>
      <span className={classes.Title}>{'Pricing'}</span>
      <span className={classes.FieldTitle}>{'Original price'}</span>
      <input
        type='text'
        className={classes.Field}
        onChange={handleOriginalPriceInput}
        value={props.offer.price ? props.offer.price : ''}
      />
      <span className={classes.FieldTitle}>{'Discounted price'}</span>
      <input
        type='text'
        className={classes.Field}
        onChange={handleDiscountedPriceInput}
        value={props.offer.discountedPrice ? props.offer.discountedPrice : ''}
      />
      <span className={classes.Discount}>
        This offer has <b>{discountPercentage.toFixed(0)}% discount</b>
      </span>
    </div>
  )
}

export default OfferPricing
