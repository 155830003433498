import React, { useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'
import { CognitoUser } from 'amazon-cognito-identity-js'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import classes from '../Auth.module.scss'
import signUpClasses from './SingUp.module.scss'
import ApiClient from '../../Api/client'
import ErrorMessage from '../../Components/ErrorMessage/ErrorMessage'
import { useTimer } from 'react-timer-hook'

export const USER_DATA = 'USER_DATA'

const SignUp = ({ logo, isDisabledRegisterByPhone }) => {
  const api = useMemo(() => new ApiClient(), [])
  const userId = v4()
  const user = new CognitoUser({
    Username: userId,
    Pool: api.userPool,
  })
  const [data, setData] = useState({
    userId: userId,
    user,
  })
  const [step, setStep] = useState(1)

  const time = new Date()
  const { seconds, isRunning, start, restart } = useTimer({
    expiryTimestamp: time.setSeconds(time.getSeconds() + 30),
    autoStart: false,
  })

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem(USER_DATA))

    if (userData && step === 1) {
      const user = new CognitoUser({
        Username: userData.userId,
        Pool: api.userPool,
      })

      setData((prevState) => {
        return {
          ...prevState,
          ...userData,
          user,
        }
      })
      api.resendConfirmationCode(
        user,
        () => {
          start()
        },
        () => {}
      )
      setStep(4)
    }
  }, [api, step, start])

  let form
  switch (step) {
    case 1:
      form = <Step1 setData={setData} setStep={setStep} />
      break
    case 2:
      form = <Step2 setData={setData} setStep={setStep} isDisabledRegisterByPhone={isDisabledRegisterByPhone} />
      break
    case 3:
      form = (
        <Step3
          data={data}
          setData={setData}
          setStep={setStep}
          timer={{
            seconds,
            isRunning,
            start,
            restart,
          }}
        />
      )
      break
    case 4:
      form = (
        <Step4
          data={data}
          setData={setData}
          setStep={setStep}
          timer={{
            seconds,
            isRunning,
            start,
            restart,
          }}
        />
      )
      break
    default:
      form = <Step1 setData={setData} setStep={setStep} />
  }
  return (
    <>
      <img src={logo} alt='' className={classes.NaooBusinessLogo} />
      {form}
    </>
  )
}

export const ErrorsBlock = ({ formErrors, error }) => {
  return (
    <div className={signUpClasses.ErrorsContainer}>
      {formErrors &&
        Object.keys(formErrors).map((key, index) => {
          return <ErrorMessage key={index} message={formErrors[key].message} />
        })}
      {error && Object.keys(error)?.length !== 0 && <ErrorMessage message={error?.message} />}
    </div>
  )
}

export default SignUp
