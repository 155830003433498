import React from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import classes from './DeleteBusinessModal.module.scss'
import {Button} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import {deleteBusinessThunk} from "../../../../store/reducers/businessSlice";
import ModalOverlay from "../../../../submodules/naoo-web-components/Components/ModalOverlay/ModalOverlay";
import {
    selectManagedBusinesses,
    updatedCurrentBusinessId
} from "../../../../store/reducers/managedBusinessesSlice";
import {ROUTES} from "../../../../submodules/naoo-web-components/Shared/constants";
import {withAnalytics} from "../../../shared/utility/withAnalytics";

const DeleteBusinessModal = ({business, setIsShowModal}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const businesses = useSelector(selectManagedBusinesses)

    const handleDeleteButtonClick = withAnalytics(
        'dashboards.business.business_profile.delete_business_popup.delete_btn_clicked',
        () => {
            dispatch(deleteBusinessThunk(business.id))
                .then((response)=>{
                    if(response.meta.requestStatus === 'fulfilled'){
                        if (businesses.length === 0) {
                            navigate(ROUTES.BUSINESS_DASHBOARD.CREATE_BUSINESS)
                        }
                        else{
                            dispatch(updatedCurrentBusinessId(businesses[0].id))
                            navigate(-1)
                        }
                    }
                })
        })

    const handleCloseModal = withAnalytics(
        'dashboards.business.business_profile.delete_business_popup.do_not_delete_btn_clicked',
        () => setIsShowModal(false)
    )

    return (
        <ModalOverlay onClose={handleCloseModal}>
            <div className={classes.Container} onClose={() => setIsShowModal(false)}>
                <div className={classes.ModalTitle}>
                    Are you sure you want to delete {business.name} and all data?
                </div>
                <div className={classes.ModalText}>
                    This can’t be undone.
                </div>
                <div className={classes.ButtonsContainer}>
                    <Button onClick={handleDeleteButtonClick}>Delete</Button>
                </div>
            </div>
        </ModalOverlay>
    );
};

export default DeleteBusinessModal;