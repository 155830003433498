import { configureStore } from '@reduxjs/toolkit'

import managedBusinessesReducer from './reducers/managedBusinessesSlice'
import dashboardReducer from './reducers/dashboardSlice'
import newOfferReducer from './reducers/newOfferSlice'
import editOfferReduser from './reducers/editOfferSlice'
import businessReducer from './reducers/businessSlice'
import postsReducer from "./reducers/postsReducer";
import tagsReducer from "./reducers/tagsReducer";
import usersReducer from "./reducers/usersReducer";
import offerReducer from "./reducers/offerReducer";
import channelsReducer from "./reducers/channelsReducer";
import messagesReducer from "../submodules/naoo-web-components/Shared/reducers/messagesReducer";
import vouchersReducer from "./reducers/vouchersReducer";
import searchParamsReducer from "../submodules/naoo-web-components/Shared/reducers/searchParamsReducer";

export default configureStore({
  reducer: {
    managedBusinesses: managedBusinessesReducer,
    dashboard: dashboardReducer,
    newOffer: newOfferReducer,
    editOffer: editOfferReduser,
    business: businessReducer,
    posts:postsReducer,
    tags:tagsReducer,
    users:usersReducer,
    offer: offerReducer,
    channels: channelsReducer,
    messages: messagesReducer,
    vouchers: vouchersReducer,
    searchParamsReducer,

  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false
  })

})
