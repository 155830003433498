import React from 'react';
import classes from './ErrorMessage.module.css'
const ErrorMessage = ({message}) => {
    return (
        <div className={classes.ErrorMessageContainer}>
            <span className={classes.Message}>{message}</span>
        </div>
    );
};

export default ErrorMessage;
