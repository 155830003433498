import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getFollowers, getUsers } from '../../api/api'
const initialState = {
	users: [],
	followers: [],
	activeUser: null,
	status: null,
	nextToken: '',
	fetching: false,
	followersNextToken: '',
	followersFetching: false,
}

export const getUsersThunk = createAsyncThunk('users/getUsers', async (payload) => {
	const response = await getUsers({
		limit: payload?.limit,
		q: payload?.q,
		gender: payload?.gender,
		excludeEmptyNames: payload?.excludeEmptyNames,
		startTime: payload?.startTime,
		endTime: payload?.endTime,
	})
	return response.data
})

export const getUsersByNextToken = createAsyncThunk('users/getUsersByNextToken', async (payload) => {
	const response = await getUsers({
		nextToken: payload?.nextToken,
	})
	return response.data
})

export const getUserFollowers = createAsyncThunk('users/getUserFollowers', async (payload) => {
	const response = await getFollowers({
		userId: payload.id,
	})
	return response.data
})

export const getUserFollowersByNexToken = createAsyncThunk('users/getUserFollowersByNexToken', async (payload) => {
	const response = await getFollowers({
		userId: payload.id,
		nextToken: payload.nextToken,
	})
	return response.data
})

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setStatus: (state, action) => {
			state.status = action.payload
		},
		setActiveUser: (state, action) => {
			state.activeUser = state.users.find((item) => item.id === action.payload)
		},
		setFetching: (state, action) => {
			state.fetching = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUsersThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(getUsersThunk.fulfilled, (state, action) => {
			state.status = 'successfully'
			state.users = action.payload.users
			state.nextToken = action.payload.nextToken
		})
		builder.addCase(getUsersThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(getUsersByNextToken.fulfilled, (state, action) => {
			state.status = 'successfully'
			if (action?.payload?.users) {
				state.users.push(...action.payload.users)
			}
			state.nextToken = action.payload.nextToken
			state.fetching = false
		})
		builder.addCase(getUserFollowers.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(getUserFollowers.fulfilled, (state, action) => {
			state.status = 'successfully'
			state.followers = action.payload.followers
			state.followersNextToken = action.payload.nextToken
		})
		builder.addCase(getUserFollowers.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(getUserFollowersByNexToken.fulfilled, (state, action) => {
			state.status = 'successfully'
			if (action?.payload?.followers) {
				state.followers.push(...action.payload.followers)
			}
			state.followersNextToken = action.payload.nextToken
			state.followersFetching = false
		})
	},
})

export default usersSlice.reducer
export const { setStatus, setActiveUser, setFetching } = usersSlice.actions

export const selectUsers = (state) => state.users.users

export const selectActiveUser = (state) => state.users.activeUser
export const selectNextToken = (state) => state.users.nextToken
export const selectFetching = (state) => state.users.fetching
export const selectStatus = (state) => state.users.status
