import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
	createChannel,
	createMediaPresignedUrl,
	createUserChannelRole,
	deleteUserChannelRoles,
	getChannel,
	getChannels,
	getMedia,
	getUserChannelRoles,
	updateChannel,
} from '../../api/api'
import { sendImage } from '../../submodules/naoo-web-components/Shared/utility/sendImage'

export const fetchChannels = createAsyncThunk('channels/fetchChannels', async (payload) => {
	const response = await getChannels({})
	return response.data
})

export const fetchChannelsByNextToken = createAsyncThunk('channels/fetchChannelsByNextToken', async (payload) => {
	const response = await getChannels({
		nextToken: payload.nextToken,
	})
	return response.data
})

export const getChannelByIdThunk = createAsyncThunk('channels/getChannelByIdThunk', async (payload, { getState }) => {
	const { channels } = getState()
	if (channels.activeChannel) return channels.activeChannel
	const response = await getChannel(payload)
	return response.data
})

export const createChannelThunk = createAsyncThunk('channels/createChannelThunk', async (payload, { getState }) => {
	const { channels } = getState()
	let media = null
	if (payload.img && channels.isSendImg) {
		media = await sendImage(payload.img, createMediaPresignedUrl, getMedia)
	}

	let data = {
		title: payload.title,
		description: payload.description,
	}
	if (media) {
		data.mediaId = media.mediaId
	}
	const response = await createChannel(data)
	return response.data
})

export const updateChannelThunk = createAsyncThunk('channels/updateChannelThunk', async (payload, { getState }) => {
	const { channels } = getState()
	let media = null
	let data = {
		title: payload.channel.title,
		description: payload.channel.description,
	}
	if (!payload.channel.img) {
		data.mediaId = null
	} else if (payload.channel.img && channels.isSendImg) {
		media = await sendImage(payload.channel.img, createMediaPresignedUrl, getMedia)
	}
	if (media) {
		data.mediaId = media.mediaId
	}
	const response = await updateChannel({
		channel: data,
		id: payload.id,
	})
	return response.data
})

export const createUserChannelRoleThunk = createAsyncThunk('channels/createUserChannelRoleThunk', async (payload) => {
	const response = await createUserChannelRole({
		...payload,
	})
	return response.data
})

export const fetchUserChannelRoles = createAsyncThunk('channels/fetchUserChannelRoles', async (payload) => {
	const response = await getUserChannelRoles(payload.channelId)
	return response.data
})

export const fetchUserChannelRolesForChannels = createAsyncThunk(
	'channels/fetchUserChannelRolesForChannels',
	async (payload) => {
		const response = await getUserChannelRoles()
		return response.data
	}
)

export const deleteUserChannelRoleThunk = createAsyncThunk('channels/deleteUserChannelRoleThunk', async (payload) => {
	await deleteUserChannelRoles(payload)
	return payload
})

const initialState = {
	channels: [],
	roles: [],
	activeChannel: null,
	isSendImg: false,
	status: null,
	nextToken: '',
	fetching: false,
}

const channelsSlice = createSlice({
	name: 'channels',
	initialState,
	reducers: {
		setStatus: (state, action) => {
			state.status = action.payload
		},
		setActiveChannel: (state, action) => {
			state.activeChannel = state.channels.find((item) => item.id === action.payload)
		},
		setIsSendImg: (state, action) => {
			state.isSendImg = action.payload
		},
		setFetching: (state, action) => {
			state.fetching = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchChannels.pending, (state) => {
			state.status = 'loading'
		})
		builder.addCase(fetchChannels.fulfilled, (state, action) => {
			state.channels = action.payload.channels
			state.status = null
			state.nextToken = action.payload.nextToken
		})
		builder.addCase(fetchChannels.rejected, (state) => {
			state.status = 'error'
		})
		builder.addCase(fetchChannelsByNextToken.fulfilled, (state, action) => {
			if (action.payload.channels) {
				state.channels.push(...action.payload.channels)
			}
			state.nextToken = action.payload.nextToken
			state.fetching = false
		})
		builder.addCase(getChannelByIdThunk.fulfilled, (state, action) => {
			state.activeChannel = action.payload
		})
		builder.addCase(createChannelThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(createChannelThunk.fulfilled, (state, action) => {
			state.channels.unshift(action.payload)
			state.status = 'successfully'
		})
		builder.addCase(createChannelThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(updateChannelThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(updateChannelThunk.fulfilled, (state, action) => {
			state.channels = state.channels.map((channel) => {
				if (channel.id === action.payload.id) return action.payload
				return channel
			})
			state.status = 'successfully'
		})
		builder.addCase(updateChannelThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(createUserChannelRoleThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(createUserChannelRoleThunk.fulfilled, (state, action) => {
			state.status = ''
			state.roles.push(action.payload)
		})
		builder.addCase(createUserChannelRoleThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(fetchUserChannelRoles.fulfilled, (state, action) => {
			state.roles = action.payload
		})
		builder.addCase(fetchUserChannelRoles.rejected, (state, action) => {
			state.roles = []
		})
		builder.addCase(fetchUserChannelRolesForChannels.fulfilled, (state, action) => {
			state.channels = action.payload.map((role) => role.channel)
		})
		builder.addCase(deleteUserChannelRoleThunk.fulfilled, (state, action) => {
			state.roles = state.roles = state.roles.filter((role) => role.id !== action.payload)
		})
	},
})

export default channelsSlice.reducer

export const { setStatus, setActiveChannel, setIsSendImg, setFetching } = channelsSlice.actions

export const selectChannels = (state) => state.channels.channels
export const selectStatus = (state) => state.channels.status
export const selectActiveChannel = (state) => state.channels.activeChannel
export const selectNextToken = (state) => state.channels.nextToken
export const selectFetching = (state) => state.channels.fetching
export const selectRoles = (state) => state.channels.roles
