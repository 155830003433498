import React from 'react';
import {useDispatch} from "react-redux";
import {setDescription} from "../../../../store/reducers/businessSlice";
import classes from "../CreateBusines.module.scss";
import {CREATE_BUSINESS_STEP_NAMES} from "../CreateBusiness";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import {withAnalytics} from "../../../shared/utility/withAnalytics";
import {BackButton, Button} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";

const AboutStep = ({business, setStep}) => {
    const dispatch = useDispatch()

    const handleDescriptionChange = (event) => {
        dispatch(setDescription(event.target.value))
    }

    const handlePreviousStepButtonClick = () => {
        setStep(CREATE_BUSINESS_STEP_NAMES.GALLERY)
    }

    const handleContinueButtonClick = withAnalytics(
        'dashboards.business.create_business.about_page.continue_btn_clicked',
        () => {
            setStep(CREATE_BUSINESS_STEP_NAMES.LOCATION)
        })

    return (
        <>
            <p className={classes.Subtitle}>
                This will be shown to all users and should be short and to the point.
            </p>
            <form className={classes.BusinessForm}>

                <div className={classes.InputContainer}>
                    <Input
                        name={'description'}
                        label={'Describe your business'}
                        type="text"
                        placeholder={'Description of your business'}
                        value={business.description}
                        onChange={handleDescriptionChange}
                        maxLength={500}
                    />
                </div>

                <div className={classes.ButtonsContainer}>
                    <BackButton
                        className={classes.BackButton}
                        onClick={handlePreviousStepButtonClick}
                    >
                        Back to Gallery
                    </BackButton>
                    <Button
                        className={classes.Button}
                        type={'submit'}
                        disabled={business?.description?.length !== 0 ? '' : "disabled"}
                        onClick={handleContinueButtonClick}
                    >
                        Continue
                    </Button>
                </div>
            </form>
        </>
    );
};

export default AboutStep;
