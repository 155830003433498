import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
	createOfferComment,
	createOfferLike,
	createOfferLikeForComment,
	deleteOfferLike,
	deleteOfferLikeForComment,
	getOfferComments,
} from '../../api/api'
import { createPostDeepLink } from '../../api/firebaseDeepLinkApi'

export const fetchOfferComments = createAsyncThunk('offer/fetchOfferComments', async (payload) => {
	const response = await getOfferComments({
		id: payload.id,
	})
	return response.data
})

export const fetchOfferCommentsByNextToken = createAsyncThunk(
	'offer/fetchOfferCommentsByNextToken',
	async (payload) => {
		const response = await getOfferComments({
			id: payload.id,
			nextToken: payload.nextToken,
		})
		return response.data
	}
)

export const createOfferCommentThunk = createAsyncThunk('offer/createOfferCommentThunk', async (payload) => {
	const response = await createOfferComment({
		id: payload.id,
		comment: {
			text: payload.text,
			businessId: payload.bid,
			userId: payload.uid,
		},
	})
	return response.data
})

export const creteOfferLikeThunk = createAsyncThunk('offer/creteOfferLikeThunk', async (payload) => {
	await createOfferLike(payload)
})

export const deleteOfferLikeThunk = createAsyncThunk('offer/deleteOfferLikeThunk', async (payload) => {
	await deleteOfferLike(payload)
})

export const createOfferLikeForCommentThunk = createAsyncThunk(
	'offer/createOfferLikeForCommentThunk',
	async (payload) => {
		await createOfferLikeForComment({
			id: payload.id,
			cid: payload.cid,
		})
		return payload.cid
	}
)

export const deleteOfferLikeForCommentThunk = createAsyncThunk(
	'offer/deleteOfferLikeForCommentThunk',
	async (payload) => {
		await deleteOfferLikeForComment({
			id: payload.id,
			cid: payload.cid,
		})
		return payload.cid
	}
)

export const createDeepLink = createAsyncThunk('offer/createDeepLink', async (payload, { getState }) => {
	const { offer } = getState()
	if (offer.deepLink) return { shortLink: offer.deepLink }
	const response = await createPostDeepLink(`https://naoo.com/offers/${payload.offerId}`)
	return response.data
})

const initialState = {
	activeOffer: null,
	offerStats: {
		offer: null,
		stats: [],
	},
	status: null,
	fetchingComments: false,
	nextTokenComments: '',
	deepLink: '',
}

const offerSlice = createSlice({
	name: 'offer',
	initialState,
	reducers: {
		setActiveOffer: (state, action) => {
			state.activeOffer = action.payload
			state.deepLink = ''
		},
		setFetchingComments: (state, action) => {
			state.fetchingComments = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchOfferComments.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(fetchOfferComments.fulfilled, (state, action) => {
			state.activeOffer.comments = action.payload.comments || []
			state.nextTokenComments = action.payload.nextToken
			state.status = null
		})
		builder.addCase(fetchOfferComments.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(fetchOfferCommentsByNextToken.fulfilled, (state, action) => {
			if (action?.payload?.comments) {
				state.activeOffer.comments.push(...action.payload.comments)
			}
			state.nextTokenComments = action.payload.nextToken
		})
		builder.addCase(createOfferCommentThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(createOfferCommentThunk.fulfilled, (state, action) => {
			state.activeOffer.comments.unshift(action.payload)
			state.status = 'successfully'
		})
		builder.addCase(createOfferCommentThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(creteOfferLikeThunk.fulfilled, (state, action) => {
			state.activeOffer.userLiked = true
			state.activeOffer.likesCount++
		})
		builder.addCase(deleteOfferLikeThunk.fulfilled, (state, action) => {
			state.activeOffer.userLiked = false
			state.activeOffer.likesCount--
		})
		builder.addCase(createOfferLikeForCommentThunk.fulfilled, (state, action) => {
			state.activeOffer.comments.find((comment) => comment.id === action.payload).userLiked = true
		})
		builder.addCase(deleteOfferLikeForCommentThunk.fulfilled, (state, action) => {
			state.activeOffer.comments.find((comment) => comment.id === action.payload).userLiked = false
		})
		builder.addCase(createDeepLink.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(createDeepLink.fulfilled, (state, action) => {
			state.deepLink = action.payload.shortLink
			state.status = 'successfully'
		})
		builder.addCase(createDeepLink.rejected, (state, action) => {
			state.status = 'error'
		})
	},
})

export default offerSlice.reducer

export const { setActiveOffer, setFetchingComments } = offerSlice.actions

export const selectActiveOffer = (state) => state.offer.activeOffer
export const selectFetchingComments = (state) => state.offer.fetchingComments
export const selectNextTokenComments = (state) => state.offer.nextTokenComments
export const selectDeepLink = (state) => state.offer.deepLink
export const selectStatus = (state) => state.offer.status
