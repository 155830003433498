import React from 'react'

import classes from './OfferStepIndicator.module.css'

const OfferStepIndicator = (props) => {
  const numberClasses = props.isHighlighted
    ? `${classes.Number} ${classes.Highlighted}`
    : classes.Number
  const titleClasses = props.isHighlighted
    ? `${classes.Title} ${classes.Highlighted}`
    : classes.Title

  return (
    <div>
      <div className={classes.Box}>
        <span className={numberClasses}>{props.number}</span>
      </div>
      <span className={titleClasses}>{props.title}</span>
    </div>
  )
}

export default OfferStepIndicator
