import Geocode from 'react-geocode'
import { GOOGLE_MAPS_API_KEY } from '../../../envVariables'

class GeocodeApi {
  constructor() {
    Geocode.setApiKey(GOOGLE_MAPS_API_KEY)
  }

  getCoordinatesFromAddress = async (address) => {
    return new Promise((resolve, reject) => {
      Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location
          resolve([lng, lat])
        },
        (error) => {
          reject(error)
          console.error(error)
        }
      )
    })
  }

  getAddressFromCoordinates = async (lng, lat) => {
    return new Promise((resolve, reject) => {
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0]
          resolve(address)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}

export const GeocodeApiInstance = new GeocodeApi()
