import React from 'react';
import classes from './Comment.module.scss'
import {ReactComponent as Like} from "../../../../../submodules/naoo-web-components/Shared/UI/assets/like.svg";
import {useDispatch} from "react-redux";
import {
    createOfferLikeForCommentThunk,
    deleteOfferLikeForCommentThunk
} from "../../../../../store/reducers/offerReducer";
import {formatDistanceToNow} from "date-fns";
import {useMentionData} from "../../../../../submodules/naoo-web-components/Shared/hooks/useMentionData";


const Comment = ({comment}) => {
    const dispatch = useDispatch()

    const {getTextWithMentions} = useMentionData(comment?.text, comment?.mentions)

    const commentInfo = comment?.business
        ? {
            name: comment.business.name,
            logo: comment.business?.logo?.url
        }
        : {
            name: `${comment?.user?.firstname} ${comment?.user?.lastname}`,
            logo: comment?.user?.avatar?.url
        }

    const handleLikeClick = () => {
        if (comment.userLiked) {
            dispatch(deleteOfferLikeForCommentThunk({
                id: comment.offer.id,
                cid: comment.id
            }))
        } else {
            dispatch(createOfferLikeForCommentThunk({
                id: comment.offer.id,
                cid: comment.id
            }))
        }
    }

    return (
        <div className={classes.Comment}>
            <div className={classes.Logo}>
                <img className={classes.Logo}
                     src={commentInfo.logo}
                     alt=""/>
            </div>

            <div className={classes.Text}>
                <div className={classes.UserNameAndInfo}>
                    <div className={classes.Name}>
                        <span>{commentInfo.name}</span>
                    </div>
                    <div className={classes.Time}>
                        <span>{formatDistanceToNow(new Date(comment.updatedAt || comment?.createdAt),{ addSuffix:true})}</span>
                    </div>
                    •
                    {
                        comment?.business &&
                        <div className={classes.AuthorName}>
                            <span>{comment?.user?.firstname} {comment?.user?.lastname}</span>
                        </div>
                    }

                    <div className={classes.Like}>
                        <Like
                            className={comment.userLiked ? classes.LikeActive : null}
                            onClick={handleLikeClick}
                        />
                    </div>
                </div>
                <div className={classes.Text}>
                    <span>{getTextWithMentions()}</span>
                </div>
            </div>

        </div>
    );
};

export default Comment;