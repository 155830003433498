export const ACTIVE_MODULE_NAME = {
    DASHBOARD: 'dashboard',
    CREATE_OFFER: 'create-offer',
    EDIT_OFFER: 'edit-offer'
}

export const OFFER_STATUS = {
    IDLE: 'idle',
    CHANGED: 'CHANGED',
    LOADING:'loading',
    SUCCESSED:'successed',
    FAILED:'failed'
}

export const IMPORT_ERROR_TYPES = {
    FILE_EMPTY: 'The file is empty',
    INVALID_TABLE_FORMAT: 'Invalid table format',
}