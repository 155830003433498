import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classes from './TopbarBusinessesDropdown.module.scss'

import { updatedCurrentBusinessId } from '../../../../store/reducers/managedBusinessesSlice'
import PlusIcon from '../../../shared/UI/assets/plus.svg'
import { clearBusiness } from '../../../../store/reducers/businessSlice'
import { ROUTES } from '../../../../submodules/naoo-web-components/Shared/constants'
import { withAnalytics } from '../../../shared/utility/withAnalytics'
import Avatar from '../../../../submodules/naoo-web-components/Components/Avatar/Avatar'

const TopbarBusinessesDropdown = React.forwardRef(({ businesses, businessClicked, ...rest }, ref) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  if (!businesses) return null

  const handleBusinessClick = (businessId) =>
    withAnalytics('dashboards.business.topbar.businesses_dropdown.business_btn_clicked', () => {
      dispatch(
        updatedCurrentBusinessId({
          currentBusinessId: businessId,
        })
      )
      businessClicked()
    })

  const handleNewBusinessButtonClick = withAnalytics(
    'dashboards.business.topbar.businesses_dropdown.add_new_business_btn_clicked',
    () => {
      businessClicked()
      dispatch(clearBusiness())
      navigate(ROUTES.BUSINESS_DASHBOARD.CREATE_BUSINESS, { replace: true })
    }
  )
  return (
    <div className={classes.Dropdown} ref={ref} {...rest}>
      <ul>
        {businesses.map((b) => (
          <li key={b.id} onClick={handleBusinessClick(b.id)}>
            <Avatar className={classes.BusinessLogo} name={b?.name} img={b?.logo?.url || b?.logo} />
            <span className={classes.BusinessName}>{b.name}</span>
            {b?.subscriptionName && <span className={classes.BusinessSubscription}>{b?.subscriptionName}</span>}
            {!b?.subscriptionName && <span className={classes.BusinessSubscription}>Bronze</span>}
          </li>
        ))}
      </ul>
      <div className={classes.AddBusinessButtonContainer} onClick={handleNewBusinessButtonClick}>
        <img alt='' src={PlusIcon} />
        <span>Add a new business</span>
      </div>
    </div>
  )
})

export default TopbarBusinessesDropdown
