import { createRequest } from '../submodules/naoo-web-components/Api/createRequest'
import { generateUrlWithQueryParams } from '../submodules/naoo-web-components/Api/generateUrlWithQueryParams/generateUrlWithQueryParams'

const getBusinesses = ({ q, nextToken }) => {
  const url = generateUrlWithQueryParams('/businesses', {
    q,
    nextToken,
  })
  return createRequest('main', 'get', url, null)
}

const createPaymentMethod = (businessId, paymentMethod) =>
  createRequest('main', 'post', `businesses/${businessId}/payment_method`, paymentMethod)

const createSubscription = (businessId, subscription) =>
  createRequest('main', 'post', `businesses/${businessId}/subscription`, subscription)

const deleteSubscription = (businessId) => createRequest('main', 'delete', `businesses/${businessId}/subscription`)

export { getBusinesses, createPaymentMethod, createSubscription, deleteSubscription }
