import ModalOverlay from '../../../../submodules/naoo-web-components/Components/ModalOverlay/ModalOverlay'
import { Button, BUTTON_TYPES } from '../../../../submodules/naoo-web-components/Components/Button/Buttons'
import React from 'react'

import styles from './SubscriptionConfirmationModal.module.scss'

const SubscriptionConfirmationModal = ({ onClose, onConfirm, subscription }) => {
  const { name, pricePerMonth, isFree } = subscription
  return (
    <ModalOverlay onClose={onClose}>
      <div className={styles.Container} onClose={onClose}>
        <div className={styles.ModalTitle}>Confirmation</div>
        <div className={styles.ModalText}>
          {isFree
            ? 'Are you sure you want to downgrade to the Bronze subscription?'
            : `Are you sure you want to subscribe to ${name} subscription (CFH ${pricePerMonth}/month) ?`}
        </div>
        <div className={styles.ButtonsContainer}>
          <Button onClick={onConfirm}>Confirm</Button>
          <Button variant={BUTTON_TYPES.SECONDARY} onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalOverlay>
  )
}

export default SubscriptionConfirmationModal
