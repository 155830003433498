import React from 'react'

import {ReactComponent as DropdownIcon} from '../assets/dropdown-icon.svg'

import classes from './DropdownButton.module.css'

const dropdownButton = ({clicked}) => {
  return (
   <DropdownIcon className={classes.button} width={12} height={12} onClick={clicked}/>
  )
}

export default dropdownButton
