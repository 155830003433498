import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import ModalWindow from "../../../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow";
import classes from './ImportFromCsvModal.module.scss'
import FileDropZone from "../../../../submodules/naoo-web-components/Components/FileDropZone/FileDropZone";
import * as parser from 'papaparse'
import {Preloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import {useDispatch, useSelector} from "react-redux";
import {addMessageThunk} from "../../../../submodules/naoo-web-components/Shared/reducers/messagesReducer";
import {createMessage, MESSAGES_TYPES} from "../../../../submodules/naoo-web-components/Components/Message/Message";
import {createPost, updatePost} from "../../../../api/api";
import {
    generatePostFromRow,
    postImportErrorMessages,
    useImportPosts,
    validatePostStructure
} from "../../../shared/utility/importUtils/importPostsUtils";
import {getArrayOfMediaId, IMPORT_ERROR_TYPES} from "../../../shared/utility/importUtils/commonUtils";
import {fetchPosts} from "../../../../store/reducers/postsReducer";
import {selectCurrentBusinessId} from "../../../../store/reducers/managedBusinessesSlice";

const ImportFromCsvModal = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const businessId = useSelector(selectCurrentBusinessId)

    const [files, setFiles] = useState([])
    const {
        isLoadingRead, setIsLoadingRead,
        setIsLoadingImport,
        postsFromImport, setPostFromImport,
        isHavePosts,
        loader,
        messageAfterLoad
    } = useImportPosts()


    const handleCloseClick = () => {
        navigate(-1)
    }

    const handleCancelClick = () => {
        setPostFromImport([])
        setFiles([])
    }

    const showTableFormatError = () => {
        dispatch(addMessageThunk({
            message: postImportErrorMessages[IMPORT_ERROR_TYPES.INVALID_TABLE_FORMAT]
        }))
        setIsLoadingRead(false)
    }

    const transformDataToPostsArray = async (data) => {
        if (!data.length){
            dispatch(addMessageThunk({
                message: postImportErrorMessages[IMPORT_ERROR_TYPES.FILE_EMPTY]
            }))
            setIsLoadingRead(false)
            return
        }

        if (!validatePostStructure(data[0])){
            showTableFormatError()
            return
        }

        const result = []

        for (const row of data) {
            const {id, media} = row

            const post = generatePostFromRow(row)

            try {
                let mediaIdArray = null
                if (media) {
                    mediaIdArray = await getArrayOfMediaId(media)
                    post.media = mediaIdArray.map(id => ({id}))
                }
            }
            catch (e){
                console.log(e)
                showTableFormatError()
                return
            }

            result.push({
                post:post,
                id: id
            })
        }
        setPostFromImport(result)
    }

    const uploadCSVClick = () => {
        setIsLoadingRead(true)
        parser.parse(files[0], {
            header: true,
            complete:async (results) => {
                await transformDataToPostsArray(results.data)
                setIsLoadingRead(false)
            },
            error: (error) => {
                console.log(error)
                setIsLoadingRead(false)
            }
        });
    }


    const handleFilesChange = (files) => {
        setFiles(files)
    }

    const handleConfirmImport = async () => {
        setIsLoadingImport(true)
        let addCount = 0
        try {
            for (const item of postsFromImport) {
                if (item.id) {
                    await updatePost(item.post, item.id)
                } else {
                    addCount++
                    await createPost(item.post)
                }
            }
            navigate(-1)
            const message = addCount > 0 ? `Posts updated. ${addCount} posts added` : `Posts updated`

            dispatch(addMessageThunk({
                message: createMessage({
                    message,
                    type: MESSAGES_TYPES.SUCCESS,
                })
            }))
            dispatch(fetchPosts({businessId}))
        } catch (error) {
            setIsLoadingImport(false)
            dispatch(addMessageThunk({
                message: createMessage({
                    message: error.message,
                    type: MESSAGES_TYPES.ERROR,
                    className: classes.Message
                })
            }))
        }
    }

    return (
        <>
            {loader}
            <ModalWindow
                isOpen
                onClose={handleCloseClick}
                title={'Import from CSV'}
                primaryButton={!isHavePosts ? 'Upload CSV' : 'Ok, add posts'}
                handlePrimaryClick={isHavePosts ? handleConfirmImport : uploadCSVClick}
                disabledPrimary={!files.length}
                secondaryButton={`${isHavePosts ? 'Cancel': ''}`}
                handleSecondaryClick={handleCancelClick}
            >
                {
                    !isHavePosts
                        ? <>
                            <ol className={classes.List}>
                                <li>
                                    Download the <Link to="/data/posts.csv" target="_blank" download>template</Link>
                                </li>
                                <li>Enter new posts data</li>
                                <li>Upload your CSV file</li>
                                <li>All posts will be added to your profile automatically</li>
                            </ol>

                            <div className={classes.FileContainer}>
                                <FileDropZone
                                    files={files}
                                    onChange={handleFilesChange}
                                    accept={'text/csv'}
                                    maxFiles={1}
                                />
                                {
                                    isLoadingRead &&
                                    <Preloader className={classes.Spinner}/>
                                }
                            </div>

                        </>
                        : messageAfterLoad
                }
            </ModalWindow>
        </>

    );
};

export default ImportFromCsvModal;