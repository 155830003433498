import React, {useState} from 'react';
import classes from "../CreateBusines.module.scss";
import {addCategory, removeCategory, setName, setVerify} from "../../../../store/reducers/businessSlice";
import {useDispatch} from "react-redux";
import {CREATE_BUSINESS_STEP_NAMES} from "../CreateBusiness";
import {withAnalytics} from "../../../shared/utility/withAnalytics";
import CategoriesDropdown
    from "../../../../submodules/naoo-web-components/Business/CategoriesDropdown/CategoriesDropdown";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import {Button} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import CheckBox from "../../../../submodules/naoo-web-components/Components/CheckBox/CheckBox";

const Step1 = ({business, categories, setStep}) => {
    const [isSelected, setIsSelected] = useState(false)
    const dispatch = useDispatch()

    const handleNameChange = (event) => {
        dispatch(setName(event.target.value))
    }

    const handleCategoriesChange = (item) => {
        if (item.isSelected) {
            dispatch(removeCategory(item))
            return
        }
        dispatch(addCategory(item))
    }

    const handleVerifyTextClick = () => {
        setIsSelected(!isSelected)
        dispatch(setVerify(!isSelected))
    }

    const handleContinueButtonClick = withAnalytics(
        'dashboards.business.create_business.name_and_category_page.continue_btn_clicked',
        () => {
            setStep(CREATE_BUSINESS_STEP_NAMES.CONTACTS)
        })

    return (
        <form className={classes.BusinessForm}>
            <Input
                containerClassName={classes.InputContainer}
                label={'Name'}
                name={'name'}
                placeholder={'Your public business name'}
                value={business.name}
                onChange={handleNameChange}
                maxLength={48}
            />
            <label className={classes.Label}>Category</label>
            <CategoriesDropdown
                list={categories}
                selectedList={business.businessCategories}
                onChange={handleCategoriesChange}
            />
            <div className={classes.TextWithButton}>
                <div className={classes.TextContainer} onClick={handleVerifyTextClick}>
                    <CheckBox isChecked={isSelected} readOnly onClick={(e)=>e.stopPropagation()}/>
                    <span className={classes.Text}>
                        I verify that I am the official representative of this business and have the right to act on behalf of the business in the creation of this page. I have read and agree to the Terms and Conditions and Privacy Policy.
                    </span>
                </div>
                <Button
                    className={classes.Button}
                    disabled={(isSelected && business.businessCategories.length !== 0 && business.name.length !== 0) ? '' : "disabled"}
                    onClick={handleContinueButtonClick}
                >Continue</Button>
            </div>
        </form>
    );
};

export default Step1;
