import React from 'react';
import classes from '../../Auth.module.scss'
import signUpClasses from '../SingUp.module.scss'
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {ErrorsBlock} from "../SignUp";
import {Button} from "../../../Components/Button/Buttons";
import Input from "../../../Components/Input/Input";
import {yupResolver} from "@hookform/resolvers/yup";

const Step1 = ({setData, setStep}) => {

    const schema = yup.object().shape({
        firstName: yup.string().required('First name is a required field').max(48, 'Max 48 charaters'),
        lastName: yup.string().max(48, 'Max 48 charaters')
    });

    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema)
    });

    const onSubmit = (formData) => {
        setData((prevState) => {
            return {
                ...prevState,
                ...formData
            }
        })
        setStep(2)
    }

    const handleInputChange = (event) => {
        event.target.value = event.target.value.replace(/[^A-Za-z]/g, "")
    }

    return (
        <>
            <div className={classes.AuthForm}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <span className={classes.FormTitle}>What’s your name?</span>
                    <Input
                        register={register('firstName')}
                        containerClassName={classes.Input}
                        type={'text'}
                        label={'First name'}
                        placeholder={'First name'}
                        error={errors?.firstName}
                        onChange={handleInputChange}
                    />
                    <Input
                        register={register('lastName')}
                        containerClassName={classes.Input}
                        type={'text'}
                        label={'Last name'}
                        placeholder={'Last name'}
                        error={errors?.lastName}
                        onChange={handleInputChange}
                    />
                    <Button className={signUpClasses.Button}
                            disabled={errors?.firstName || errors?.lastName}
                    >
                        Continue
                    </Button>
                </form>
                <span className={signUpClasses.Text}>
                    Already have an account? <Link to={'/auth'}>Log in</Link>
                </span>
            </div>
            <ErrorsBlock formErrors={errors}/>
        </>
    );
};

export default Step1;
