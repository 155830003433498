import {firehoseClient} from "../../../api/firehoseClient";
import {executeIfExist} from "../../../submodules/naoo-web-components/Shared/utility/utils";

export const withAnalytics = (eventName, mainFunction) => {
    return async (...args) => {
        if (!eventName) {
            return
        }

        const record = firehoseClient.createRecord({
            eventType: eventName
        })
        await firehoseClient.putRecord(record)
        executeIfExist(mainFunction?.bind(undefined, ...args))
    }
}

