import React, {useEffect, useRef} from 'react';
import TableItem from "../../../components/Table/TableItem/TableItem";
import classes from "../PostContainer.module.scss";
import PostPreview from "../PostPreview/PostPreview";
import {format, isAfter} from "date-fns";
import PostedBy from "../PostedBy/PostedBy";
import {numberWithSpaces} from "../../../shared/utility/numberWithSpaces";
import MoreDropdown from "../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {useIntersectionObserver} from "../../../hooks/useIntersectionObserver";
import {useDispatch} from "react-redux";
import {setIsViewed} from "../../../../store/reducers/postsReducer";

const PostTableItem = ({
                           post,
                           handlePostItemClick,
                           handleDropdownClick,
                           handleMoreItemEditClick,
                           handleMoreItemPublishNowClick,
                           handleMoreItemStatsClick,
                           handleMoreItemDeleteClick
}) => {
    const dispatch = useDispatch()
    const ref = useRef(null)
    const entry = useIntersectionObserver(ref, {})
    const isVisible = !!entry?.isIntersecting

    useEffect(() => {
        if (!post.isViewed && isVisible) {
            dispatch(setIsViewed(post.id))
        }
    }, [isVisible, dispatch, post.id, post.isViewed]);


    return (
        <TableItem
            ref={ref}
            className={classes.PostItem}
            onClick={handlePostItemClick(post.id)}
        >
            <PostPreview
                post={post}
                mediaClass={classes.Logo}
                textClass={classes.Post}
                delayed={isAfter(new Date(post?.publicationDate), new Date())}
            />
            <PostedBy
                gridClass={classes.Account}
                channel={post.channel}
                user={post.user}
            />
            <div className={classes.PublishDate}>
                {
                    isAfter(new Date(post?.publicationDate), new Date()) &&
                    <span className={classes.Scheduled}>Scheduled</span>
                }
                <span>{format(new Date(post?.publicationDate), 'dd.MM.yy HH:mm')}</span>
            </div>
            <div className={classes.Comments}>
                <span>{post?.commentsCount ? numberWithSpaces(post?.commentsCount) : '-'}</span>
            </div>
            <div className={classes.Likes}>
                <span>{post?.likesCount ? numberWithSpaces(post?.likesCount) : '-'}</span>
            </div>
            <div className={classes.More}>
                <MoreDropdown onClick={handleDropdownClick}>
                    <MoreItem
                        text={'Edit'}
                        onClick={handleMoreItemEditClick(post.id)}
                    />
                    {
                        isAfter(new Date(post?.publicationDate), new Date()) &&
                        <MoreItem
                            text={'Publish Now'}
                            onClick={handleMoreItemPublishNowClick(post.id)}
                        />
                    }
                    {/*<MoreItem*/}
                    {/*    text={'Show statistics'}*/}
                    {/*    onClick={handleMoreItemStatsClick(post.id)}*/}
                    {/*/>*/}
                    <MoreItem
                        text={'Delete post'}
                        danger
                        onClick={handleMoreItemDeleteClick(post.id)}
                    />
                </MoreDropdown>
            </div>
        </TableItem>
    );
};

export default PostTableItem;