import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createMediaPresignedUrl, getMedia, mainGetOfferById, mainUpdateOffer } from '../../api/api'
import { OFFER_STATUS } from '../../app/shared/constants'
import { GeocodeApiInstance } from '../../app/shared/utility/getCoordinatesFromAddress'
import { sendImage } from '../../submodules/naoo-web-components/Shared/utility/sendImage'

export const updateOffer = createAsyncThunk(
  'editOffer/updateOffer',
  async ({
    mediaURI,
    offerId,
    title,
    text,
    media,
    price,
    discount,
    decimals,
    startTime,
    endTime,
    tags,
    points,
    claimsLimit,
    claimsPerUserLimit,
    canBeClaimedAtLocation,
    hours,
    isActive,
    coordinates,
  }) => {
    let mediaId = null

    if (mediaURI !== '') {
      mediaId = mediaURI?.id ?? (await sendImage(mediaURI, createMediaPresignedUrl, getMedia)).mediaId
    }

    price = price ? price * 100 : undefined
    discount = discount ? discount * 100 : undefined

    if (!decimals) {
      decimals = 2
    }

    if (!points) {
      points = Math.round((discount ? price - discount : price) / 10 ** decimals)
    }

    // Update offer record using the backend api
    const updateOfferResp = await mainUpdateOffer(
      offerId,
      title,
      text,
      !mediaId ? [] : [{ id: mediaId }],
      price,
      discount,
      decimals,
      startTime,
      endTime,
      tags,
      points,
      claimsLimit,
      claimsPerUserLimit,
      canBeClaimedAtLocation,
      hours,
      isActive,
      coordinates
    )
    // check http code
    if (updateOfferResp.status < 200 || updateOfferResp.status >= 300) {
      console.debug('could not create offer, http status: ', updateOfferResp.status)
    }
    return updateOfferResp.data
  }
)

export const getOfferById = createAsyncThunk('editOffer/getOfferById', async (offerId) => {
  const response = await mainGetOfferById(offerId)
  if (response.data.coordinates) {
    const address = await GeocodeApiInstance.getAddressFromCoordinates(
      response.data.coordinates[0],
      response.data.coordinates[1]
    )
    console.log('addr', address)
    response.data.address = address.formatted_address
  }
  return response.data
})

const initialState = {
  mutableOffer: {},
  media: {},
  status: '',
}

const editOfferSlice = createSlice({
  name: 'editOffer',
  initialState,
  reducers: {
    setMutableOffer(state, action) {
      state.mutableOffer = action.payload
      state.media = action.payload.media
      state.status = ''
    },
    updateOfferData(state, action) {
      console.debug('[newOfferSlice.js] updatedNewOfferData action: ')
      console.debug(action)
      if (action.payload.businessId !== undefined) {
        state.mutableOffer.businessId = action.payload.businessId
      }
      if (action.payload.title !== undefined) {
        state.mutableOffer.title = action.payload.title
      }
      if (action.payload.text !== undefined) {
        state.mutableOffer.text = action.payload.text
      }
      if (action.payload.coordinates !== undefined) {
        state.mutableOffer.coordinates = action.payload.coordinates
      }
      if (action.payload.media !== undefined) {
        state.mutableOffer.media = action.payload.media
      }
      if (action.payload.price !== undefined) {
        state.mutableOffer.price = action.payload.price
      }
      if (action.payload.discountedPrice !== undefined) {
        state.mutableOffer.discountedPrice = action.payload.discountedPrice
      }
      if (action.payload.discount !== undefined) {
        state.mutableOffer.discount = action.payload.discount
      }
      if (action.payload.currency !== undefined) {
        state.mutableOffer.currency = action.payload.currency
      }
      if (action.payload.decimals !== undefined) {
        state.mutableOffer.decimals = action.payload.decimals
      }
      if (action.payload.startTime !== undefined) {
        state.mutableOffer.startTime = action.payload.startTime
      }
      if (action.payload.endTime !== undefined) {
        state.mutableOffer.endTime = action.payload.endTime
      }
      if (action.payload.tags !== undefined) {
        state.mutableOffer.tags = action.payload.tags
      }
      if (action.payload.walkInPoints !== undefined) {
        state.mutableOffer.walkInPoints = action.payload.walkInPoints
      }
      if (action.payload.claimsLimit !== undefined) {
        state.mutableOffer.claimsLimit = action.payload.claimsLimit
      }
      if (action.payload.claimsPerUserLimit !== undefined) {
        state.mutableOffer.claimsPerUserLimit = action.payload.claimsPerUserLimit
      }
      if (action.payload.canBeClaimedAtLocation !== undefined) {
        state.mutableOffer.canBeClaimedAtLocation = action.payload.canBeClaimedAtLocation
      }
      if (action.payload.address !== undefined) {
        state.mutableOffer.address = action.payload.address
      }
      if (action.payload.hours !== undefined) {
        state.mutableOffer.hours = action.payload.hours
        state.mutableOffer.customHours = action.payload.hours
      }
      if (action.payload.isActive !== undefined) {
        state.mutableOffer.isActive = action.payload.isActive
      }
      if (action.payload.customHours !== undefined) {
        state.mutableOffer.customHours = action.payload.customHours
      }
      state.status = OFFER_STATUS.CHANGED
    },
    updatedPendingMedia(state, action) {
      console.debug('[newOfferSlice.js] updatedPendingImageMetadata action:')
      console.debug(action)
      state.media = action.payload.media
      state.status = OFFER_STATUS.CHANGED
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOfferById.fulfilled, (state, action) => {
      const DECIMALS = 2
      state.mutableOffer = action.payload
      if (action.payload.media) {
        state.media = action.payload.media[0]
      } else {
        state.media = ''
      }
      state.mutableOffer.price = state.mutableOffer.price / 10 ** DECIMALS
      state.mutableOffer.discount = state.mutableOffer.discount / 10 ** DECIMALS
      state.mutableOffer.discountedPrice = state.mutableOffer.price - state.mutableOffer.discount
      state.mutableOffer.customHours = action.payload.hours
      state.status = ''
      state.mutableOffer.address = action.payload.address
    })
    builder.addCase(updateOffer.fulfilled, (state, action) => {
      state.mutableOffer = {}
      state.media = ''
      state.status = ''
    })
    builder.addCase(updateOffer.rejected, (state, action) => {
      state.mutableOffer = {}
      state.media = ''
    })
  },
})

export default editOfferSlice.reducer

export const { setMutableOffer, updateOfferData, updatedPendingMedia } = editOfferSlice.actions

export const selectMutableOffer = (state) => state.editOffer.mutableOffer
export const selectMedia = (state) => state.editOffer.media
export const selectStatus = (state) => state.editOffer.status
