import React, { useEffect, useState } from 'react'
import BusinessSidebar from '../Sidebar/BusinessSidebar'
import Topbar from '../Topbar/Topbar'
import { FullscreenPreloader } from '../../../submodules/naoo-web-components/Components/Preloader/Preloader'

import style from './SubscriptionContainer.module.scss'
import spinnerStyle from '../../../submodules/naoo-web-components/Shared/Spinner.module.scss'

import subscriptions from '../../../data/subscriptions.json'
import SubscriptionCard from './SubscriptionCard/SubscriptionCard'
import PaymentMethodDialog from './PaymentMethod/PaymentMethodDialog'
import { useDispatch, useSelector } from 'react-redux'
import { fetchManagedBusinesses, selectCurrentBusiness } from '../../../store/reducers/managedBusinessesSlice'
import {
  createSubscription as createBusinessSubscription,
  deleteSubscription as deleteBusinessSubscription,
} from '../../../api/businessApi'
import SubscriptionConfirmationModal from './SubscriptionConfirmationModal/SubscriptionConfirmationModal'
import { addMessageThunk } from '../../../submodules/naoo-web-components/Shared/reducers/messagesReducer'
import { createMessage, MESSAGES_TYPES } from '../../../submodules/naoo-web-components/Components/Message/Message'

const SubscriptionContainer = () => {
  const dispatch = useDispatch()
  const business = useSelector((state) => selectCurrentBusiness(state))

  const [loading, setLoading] = useState(true)
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState()

  const createSubscription = async () => {
    const { stripePriceId, stripeProductId } = selectedSubscription

    try {
      await createBusinessSubscription(business.id, {
        stripeProductId,
        stripePriceId,
      })
      dispatch(
        addMessageThunk({
          message: createMessage({
            type: MESSAGES_TYPES.SUCCESS,
            message: 'Subscription created successfully.',
          }),
        })
      )
    } catch (err) {
      setLoading(false)
      dispatch(
        addMessageThunk({
          message: createMessage({
            type: MESSAGES_TYPES.ERROR,
            message: 'Something went wrong',
          }),
        })
      )

      console.error(err)
    }
  }

  const deleteSubscription = async () => {
    try {
      await deleteBusinessSubscription(business.id)
      dispatch(
        addMessageThunk({
          message: createMessage({
            type: MESSAGES_TYPES.SUCCESS,
            message: 'Subscription removed successfully.',
          }),
        })
      )
    } catch (err) {
      setLoading(false)
      dispatch(
        addMessageThunk({
          message: createMessage({
            type: MESSAGES_TYPES.ERROR,
            message: 'Something went wrong',
          }),
        })
      )

      console.error(err)
    }
  }

  const selectSubscription = (subscription) => {
    setSelectedSubscription(subscription)

    if (!business.stripePaymentMethodId) {
      setIsPaymentMethodModalOpen(true)
    } else {
      setIsConfirmationModalOpen(true)
    }
  }

  const onPaymentMethodClose = () => {
    setIsPaymentMethodModalOpen(false)
    setSelectedSubscription(undefined)
  }

  const onPaymentMethodCreated = async () => {
    setIsPaymentMethodModalOpen(false)

    await dispatch(fetchManagedBusinesses())
    setIsConfirmationModalOpen(true)
  }

  const onConfirmSubscription = async () => {
    setIsConfirmationModalOpen(false)
    setLoading(true)
    if (selectedSubscription.isFree) {
      await deleteSubscription()
    } else {
      await createSubscription()
    }
  }

  const onCloseSubscriptionConfirmation = () => {
    setIsConfirmationModalOpen(false)
    setSelectedSubscription(undefined)
  }

  const onUnsubscribe = () => {
    setSelectedSubscription(subscriptions.find(({ isFree }) => isFree))
    setIsConfirmationModalOpen(true)
  }

  useEffect(() => {
    setLoading(true)
    dispatch(fetchManagedBusinesses())
  }, [dispatch])

  useEffect(() => {
    if (business) {
      setLoading(false)
    }
  }, [business, setLoading])

  return (
    <>
      {loading && (
        <FullscreenPreloader
          overlayClassName={spinnerStyle.lightBlurPreloader}
          spinnerClassName={spinnerStyle.spinnerSize}
        />
      )}
      <div className={style.Container}>
        <BusinessSidebar isOffset />
        <div className={style.Main}>
          <Topbar />
          <div className={style.SubscriptionsContainer}>
            <div className={style.Cards}>
              {subscriptions.map((subscription, index) => (
                <SubscriptionCard
                  key={index}
                  onUnsubscribe={onUnsubscribe}
                  subscription={subscription}
                  selected={
                    (subscription.isFree && !business?.subscriptionName) ||
                    subscription.name === business?.subscriptionName
                  }
                  onSelect={() => {
                    selectSubscription(subscription)
                  }}
                />
              ))}
            </div>
            {isPaymentMethodModalOpen && (
              <PaymentMethodDialog
                onClose={onPaymentMethodClose}
                onCreate={onPaymentMethodCreated}
                onError={(err) => {
                  dispatch(
                    addMessageThunk({
                      message: createMessage({
                        type: MESSAGES_TYPES.ERROR,
                        message: 'Something went wrong',
                      }),
                    })
                  )
                  console.error(err)
                }}
                handleSetLoading={(loading) => setLoading(loading)}
              />
            )}
            {isConfirmationModalOpen && (
              <SubscriptionConfirmationModal
                subscription={selectedSubscription}
                onClose={onCloseSubscriptionConfirmation}
                onConfirm={onConfirmSubscription}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscriptionContainer
