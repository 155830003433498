import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import classes from './Topbar.module.scss'
import TopbarBusinessesDropdown from './TopbarBusinessesDropdown/TopbarBusinessesDropdown'
import DropdownButton from '../../shared/UI/DropdownButton/DropdownButton'
import { selectCurrentBusiness, selectManagedBusinesses } from '../../../store/reducers/managedBusinessesSlice'
import { selectActiveModuleName } from '../../../store/reducers/dashboardSlice'
import { ACTIVE_MODULE_NAME } from '../../shared/constants'
import { withAnalytics } from '../../shared/utility/withAnalytics'
import Avatar from '../../../submodules/naoo-web-components/Components/Avatar/Avatar'
import { usePopper } from 'react-popper'
import { usePopperClickOutside } from '../../../submodules/naoo-web-components/Shared/hooks/usePopperClickOutside'

const Topbar = (props) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'auto-start' })

  const { visible, toggleVisible } = usePopperClickOutside({ referenceElement, popperElement })

  const toggleDropdown = withAnalytics('dashboards.business.topbar.businesses_dropdown_clicked', () => {
    toggleVisible()
  })

  const selectedBusiness = useSelector((state) => selectCurrentBusiness(state))
  const bs = useSelector((state) => selectManagedBusinesses(state))

  const activeModule = useSelector(selectActiveModuleName)
  const cls = [classes.SelectedBusinessContainer]

  if (activeModule === ACTIVE_MODULE_NAME.EDIT_OFFER) {
    cls.push(classes.SelectedBusinessContainerDisabled)
  }
  return (
    <>
      <div className={classes.Topbar}>
        <div ref={setReferenceElement} className={cls.join(' ')} onClick={toggleDropdown}>
          <Avatar
            className={classes.selectedBusinessLogo}
            name={selectedBusiness?.name}
            img={selectedBusiness?.logo?.url || selectedBusiness?.logo}
          />
          <span className={classes.selectedBusinessName}>{selectedBusiness?.name ?? null}</span>
          <span className={classes.selectedBusinessSubscription}>{selectedBusiness?.subscriptionName}</span>
          <span className={classes.selectedBusinessAddress}>{selectedBusiness?.address ?? null}</span>
          <DropdownButton />
        </div>
      </div>
      {visible && (
        <TopbarBusinessesDropdown
          ref={setPopperElement}
          businesses={bs}
          businessClicked={toggleDropdown}
          style={styles.popper}
          {...attributes.popper}
        />
      )}
    </>
  )
}

export default Topbar
