import React, {useState} from 'react'
import OffersTable from './OfferKpisTable/OfferKpisTable'
import classes from './Offers.module.scss'
import NoOffersIcon from './assets/no-offers-icon.svg'
import CreateNewOfferIcon from './assets/create-new-offer-icon.svg'
import {withAnalytics} from "../../../../shared/utility/withAnalytics";
import TableWrapper from "../../../../components/Table/TableWrapper/TableWrapper";
import CheckboxSelectedIcon from "../../../../../submodules/naoo-web-components/Shared/UI/assets/CheckBoxSelected.svg";
import CheckboxNotSelectedIcon
    from "../../../../../submodules/naoo-web-components/Shared/UI/assets/CheckBoxNotSelected.svg";
import {Button} from "../../../../../submodules/naoo-web-components/Components/Button/Buttons";

const Offers = (props) => {
    const [isShowingOnlyActiveOffers, setShowOnlyActiveOffers] = useState(false)

    // show only active offers
    const handleShowOnlyActiveOffersClick = withAnalytics(
        'dashboards.business.offers.show_only_active_offers_checkbox_clicked',
        () => {
            setShowOnlyActiveOffers(!isShowingOnlyActiveOffers)
        })
    // page structure
    let header = null
    let content = null
    const titleLabel = <span className={classes.OffersSectionTitle}>Offers</span>
    if (!props.offers) {
        // header
        header = <div className={classes.OffersHeader}>{titleLabel}</div>
        // content - 'no offers, create one?' message
        content = (
            <div className={classes.OffersSectionContent}>
                <img className={classes.NoOffersIcon} alt="" src={NoOffersIcon}/>
                <p className={classes.NoOffersText}>{'No offers created'}</p>
                <button
                    onClick={props.createNewOfferClicked}
                    className={classes.CreateFirstOfferBtn}
                >
                    {'Create your first offer'}
                </button>
            </div>
        )
    } else {
        // header
        const showOnlyActiveOffersLabel = (
            <label
                onClick={handleShowOnlyActiveOffersClick}
                className={classes.ShowOnlyActiveOffersLabel}
            >
                {'Show only active offers'}
            </label>
        )

        const createNewOfferLabel = (
            <label
                className={classes.CreateNewOfferLabel}
                onClick={props.createNewOfferClicked}
            >
                {'Create new offer'}
            </label>
        )
        const createNewOfferButton = (
            <img
                className={classes.CreateNewOfferButton}
                onClick={props.createNewOfferClicked}
                alt=""
                src={CreateNewOfferIcon}
            />
        )
        header = (
            <div className={classes.OffersHeader}>
                {titleLabel}
                <img
                    className={classes.CheckBox}
                    src={isShowingOnlyActiveOffers ? CheckboxSelectedIcon : CheckboxNotSelectedIcon}
                    alt=""
                    onClick={handleShowOnlyActiveOffersClick}
                />
                {showOnlyActiveOffersLabel}
                {createNewOfferButton}
                {createNewOfferLabel}
            </div>
        )
        // content - list of offers
        content = (
            <OffersTable
                offers={
                    isShowingOnlyActiveOffers
                        ? props.offers.filter((o) => o.offer.isActive)
                        : props.offers
                }
                onOfferUpdated={props.onOfferUpdated}
            />
        )
    }

    return (
        <TableWrapper>
            {header}
            {content}
            <Button
                className={classes.CreateNewOfferButtonFixed}
                onClick={props.createNewOfferClicked}
            >
                Create offer
            </Button>
        </TableWrapper>
    )
}

export default Offers
