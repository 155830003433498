import React, {useEffect, useState} from 'react'
import {ThemeContext, THEMES} from "../context/themeContext";

const getTheme = () => {
    const theme = `${localStorage.getItem('theme')}`
    if (Object.values(THEMES).includes(theme)) return theme

    const userMedia = window.matchMedia('(prefers-color-scheme: light)')
    if (userMedia.matches) return THEMES.LIGHT

    return THEMES.DARK
}

const ThemeProvider = ({ children }) => {
    const [ theme, setTheme ] = useState(getTheme)

    useEffect(() => {
        document.documentElement.dataset.theme = theme
        localStorage.setItem('theme', theme)
    }, [ theme ])

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeProvider