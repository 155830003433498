import { createContext } from 'react'

export const RemoteConfigContext = createContext()

export const MODULE_OFFERS_ENABLED = 'offersEnabled'
export const MODULE_POSTS_ENABLED = 'postsEnabled'
export const MODULE_FOLLOWERS_ENABLED = 'followersEnabled'
export const MODULE_CHAT_ENABLED = 'chatEnabled'
export const MODULE_BUSINESS_PROFILE_ENABLED = 'businessProfileEnabled'
export const POSTS_IMPORT_ENABLED = 'postsImportEnabled'
export const MODULE_SUBSCRIPTIONS_ENABLED = 'bdSubscriptionsEnabled'
export const CLAIM_OFFER_AT_LOCATION_ENABLED = 'bdClaimOfferAtLocationEnabled'
