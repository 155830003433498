import {GoogleSpreadsheet} from "google-spreadsheet";
import {createPost, updatePost} from "./api";
import {
    generatePostFromRow,
    validatePostStructure
} from "../app/shared/utility/importUtils/importPostsUtils";
import {getArrayOfMediaId, IMPORT_ERROR_TYPES} from "../app/shared/utility/importUtils/commonUtils";
import {googleLogout} from '@react-oauth/google';

export const SPREADSHEETS_SCOPE = 'https://www.googleapis.com/auth/spreadsheets'

class GoogleSheetApi {
    constructor() {
        this.isSignIn = false
        this.doc = null
        this.token = ''
    }

    updateSignInStatus = (isSignedIn) => {
        this.isSignIn = isSignedIn
    }

    signIn = response => {
        this.token = response.access_token
        return response
    }

    signOut = () => {
        googleLogout()
        this.isSignIn = false
    }

    getSpreadsheetById = async (id) => {
        this.doc = new GoogleSpreadsheet(id)
        this.doc.useRawAccessToken(this.token)
        await this.doc.loadInfo()
        const sheet = this.doc.sheetsByIndex[0];
        try {
            const rows = await sheet.getRows();
            this.rows = rows
        } catch (error) {
            this.rows = []
            throw error
        }
    }

    getIdFromLink = (link) => {
        return link.split('/')[5]
    }

    getSpreadsheetByLink = async (link) => {
        const id = this.getIdFromLink(link)
        await this.getSpreadsheetById(id)
    }

    transformDataToArray = async (link) => {
        try {
            await this.getSpreadsheetByLink(link)
        } catch (e) {
            throw e
        }

        const result = []

        if (!this.rows.length) {
            throw new Error(IMPORT_ERROR_TYPES.FILE_EMPTY)
        }

        if (!validatePostStructure(this.rows[0])) {
            throw new Error(IMPORT_ERROR_TYPES.INVALID_TABLE_FORMAT)
        }

        for (const row of this.rows) {
            const index = this.rows.indexOf(row);
            const {id, media} = row
            const post = generatePostFromRow(row)

            try {
                let mediaIdArray = null
                if (media) {
                    mediaIdArray = await getArrayOfMediaId(media)
                    await this.updateMediaOnSheet(mediaIdArray, index)
                    post.mediaIds = mediaIdArray
                }
            } catch (e) {
                throw new Error(IMPORT_ERROR_TYPES.INVALID_TABLE_FORMAT)
            }

            result.push({
                post,
                id
            })
        }
        return result
    }

    importPosts = async (posts) => {
        let addCount = 0
        for (const item of posts) {
            const index = posts.indexOf(item);
            try {
                if (item.id) {
                    await updatePost(item.post, item.id)
                } else {
                    const response = await createPost(item.post)
                    this.rows[index].id = response.data.id
                    await this.rows[index].save()
                    addCount++
                }
            } catch (error) {
                throw error
            }
        }
        return addCount
    }

    updateMediaOnSheet = async (mediaIdArray, rowIndex) => {
        try {
            this.rows[rowIndex].media = mediaIdArray
                .map(id => (JSON.stringify({id})))
                .join('\n')
            await this.rows[rowIndex].save()
            return mediaIdArray
        } catch (e) {
            console.log(e)
        }
    }
}

export const googleSheetApi = new GoogleSheetApi()

export default GoogleSheetApi