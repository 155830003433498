import React from 'react';
import classes from "../CreateBusines.module.scss";
import {CREATE_BUSINESS_STEP_NAMES} from "../CreateBusiness";
import {withAnalytics} from "../../../shared/utility/withAnalytics";
import {BackButton, Button} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import {addProfileMedia, deleteProfileMedia} from "../../../../store/reducers/businessSlice";
import {useDispatch} from "react-redux";
import Gallery from "../../../../submodules/naoo-web-components/Components/Gallery/Gallery";

const GalleryStep = ({business, setStep}) => {
    const dispatch = useDispatch()

    const handlePreviousStepButtonClick = () => {
        setStep(CREATE_BUSINESS_STEP_NAMES.COVER)
    }

    const handleContinueButtonClick = withAnalytics(
        'dashboards.business.create_business.photos_image_page.continue_btn_clicked',
        () => {
            setStep(CREATE_BUSINESS_STEP_NAMES.ABOUT)
        })

    const handleAddGalleryItems = (items) => {
        dispatch(addProfileMedia(items))
    }

    const handleDeleteGalleryItem = (id) => {
        dispatch(deleteProfileMedia(id))
    }

    return (
        <>
            <p className={classes.Subtitle}>
                If you have a video, that is even better. Try to keep it short – people usually don’t watch videos longer than 30 sec.
            </p>
            <form className={classes.BusinessForm}>
                <div className={classes.GalleryContainer}>
                    <Gallery
                        className={classes.Gallery}
                        itemClassName={classes.GalleryItem}
                        dropzoneClassName={!business.profileMedia?.length ? classes.GalleryDropzone : classes.GalleryItem}
                        items={business.profileMedia}
                        onAdd={handleAddGalleryItems}
                        onDelete={handleDeleteGalleryItem}
                    />
                </div>
                <div className={classes.ButtonsContainer}>
                    <BackButton
                        className={classes.BackButton}
                        onClick={handlePreviousStepButtonClick}
                    >
                        Back to Profile Cover
                    </BackButton>
                    <Button
                        className={classes.Button}
                        disabled={business.profileMedia?.length !== 0 ? '' : "disabled"}
                        onClick={handleContinueButtonClick}
                    >
                        Continue
                    </Button>
                </div>
            </form>
        </>
    );
};

export default GalleryStep;
