import React from 'react';
import classes from './UserInfo.module.scss'
import Avatar from "../../../../../submodules/naoo-web-components/Components/Avatar/Avatar";
import {formatDistanceToNow} from "date-fns";

const UserInfo = ({user, createdAt}) => {
    return (
        <div className={classes.Container} data-testid={'userInfo'}>
            <Avatar
                className={classes.Logo}
                img={user?.avatar?.url}
                name={user?.firstname}
                lastname={user?.lastname}
            />
            <div className={classes.NameAndInfo}>
                <div className={classes.Name}><span>{user?.firstname} {user?.lastname}</span></div>
                <div className={classes.Info}>
                    <span>{formatDistanceToNow(new Date(createdAt),{ addSuffix:true})}</span>
                </div>
            </div>
        </div>
    );
};

export default UserInfo;