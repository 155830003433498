import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentBusinessId } from '../../../store/reducers/managedBusinessesSlice'
import layoutClasses from '../Layout.module.scss'
import classes from './PostContainer.module.scss'
import BusinessSidebar from '../Sidebar/BusinessSidebar'
import { ROUTES } from '../../../submodules/naoo-web-components/Shared/constants'
import Topbar from '../Topbar/Topbar'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import { ReactComponent as SyncIcon } from '../../../submodules/naoo-web-components/Shared/UI/assets/sync.svg'
import PageTitle from '../../components/PageTitle/PageTitle'
import {
  deletePostThunk,
  fetchComments,
  fetchPosts,
  fetchPostsByNextToken,
  selectFetching,
  selectNextToken,
  selectPosts,
  selectStatus,
  setActivePost,
  setFetching,
  setIsSendImg,
  setStatus,
  updatePostThunk,
} from '../../../store/reducers/postsReducer'
import { FullscreenPreloader, Preloader } from '../../../submodules/naoo-web-components/Components/Preloader/Preloader'
import spinnerClasses from '../../../submodules/naoo-web-components/Shared/Spinner.module.scss'
import { Button, TextButton } from '../../../submodules/naoo-web-components/Components/Button/Buttons'
import ImportPostsModal from './ImportPostsModal/ImportPostsModal'
import { withAnalytics } from '../../shared/utility/withAnalytics'
import TableWrapper from '../../components/Table/TableWrapper/TableWrapper'
import TableHeader from '../../components/Table/TableHeader/TableHeader'
import PostTableItem from './PostTableItem/PostTableItem'
import { POSTS_IMPORT_ENABLED, RemoteConfigContext } from '../../shared/contexts/remote-config.context'
import { getBoolean } from 'firebase/remote-config'

const PostsContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const posts = useSelector(selectPosts)
  const postStatus = useSelector(selectStatus)
  const businessId = useSelector(selectCurrentBusinessId)
  const nextToken = useSelector(selectNextToken)
  const fetching = useSelector(selectFetching)

  const [isShowImportModal, setIsShowImportModal] = useState(false)

  const remoteConfig = useContext(RemoteConfigContext)

  useEffect(() => {
    if (businessId) {
      dispatch(fetchPosts())
    }
  }, [dispatch, businessId])

  const isNotViewedCount = useMemo(() => {
    return posts.filter((it) => !it.isViewed).length
  }, [posts])

  useEffect(() => {
    if (isNotViewedCount < 20 && !fetching && nextToken) {
      dispatch(fetchPostsByNextToken({ nextToken }))
      dispatch(setFetching(true))
    }
  }, [isNotViewedCount, nextToken, dispatch, fetching, businessId])

  const handleNewPostButtonClick = withAnalytics('dashboards.business.sidebar.posts_new_post_btn_clicked', () => {
    navigate(ROUTES.ADMIN_DASHBOARD.POSTS + '/create')
    dispatch(setStatus(null))
  })

  const handlePostItemClick = (postId) =>
    withAnalytics('dashboards.business.posts.post_clicked', () => {
      dispatch(setActivePost(postId))
      dispatch(
        fetchComments({
          id: postId,
        })
      )
      navigate(`${ROUTES.ADMIN_DASHBOARD.POSTS}/${postId}/comments`)
    })

  const handleImportPostsClick = () => {
    setIsShowImportModal(true)
  }

  const handleCloseImportModalClick = () => {
    setIsShowImportModal(false)
  }

  const handleDropdownClick = withAnalytics('dashboards.business.posts.post_more_btn_clicked', () => {})

  const handleMoreItemEditClick = (postId) =>
    withAnalytics('dashboards.business.posts.post_more_menu_edit_btn_clicked', () => {
      dispatch(setActivePost(postId))
      dispatch(setStatus(null))
      dispatch(setIsSendImg(false))
      navigate(`${ROUTES.ADMIN_DASHBOARD.POSTS}/${postId}/update`)
    })

  const handleMoreItemStatsClick = (postId) =>
    withAnalytics('dashboards.business.posts.post_more_menu_stats_btn_clicked', () => {
      dispatch(setActivePost(postId))
      navigate(`${ROUTES.ADMIN_DASHBOARD.POSTS}/${postId}/stats`)
    })

  const handleMoreItemPublishNowClick = (postId) => () => {
    dispatch(
      updatePostThunk({
        id: postId,
        post: {
          publicationDate: new Date().toISOString(),
        },
      })
    ).then(() => {
      dispatch(setStatus(null))
    })
  }

  const handleMoreItemDeleteClick = (postId) => () => {
    dispatch(deletePostThunk(postId))
  }

  const match = useMatch(ROUTES.ADMIN_DASHBOARD.POSTS)

  if (!businessId) return null
  return (
    <>
      {postStatus === 'loading' && (
        <FullscreenPreloader
          overlayClassName={spinnerClasses.lightBlurPreloader}
          spinnerClassName={spinnerClasses.spinnerSize}
        />
      )}
      {getBoolean(remoteConfig, POSTS_IMPORT_ENABLED) && (
        <ImportPostsModal isOpen={isShowImportModal} onClose={handleCloseImportModalClick} />
      )}
      <div className={layoutClasses.Container}>
        <BusinessSidebar>
          <Button onClick={handleNewPostButtonClick}>New Post</Button>
        </BusinessSidebar>
        <div className={layoutClasses.Main}>
          <Topbar />
          <Outlet />
          {match && (
            <div className={classes.Container}>
              <div className={classes.PostPageHeader}>
                <PageTitle title={'Posts – empower the business'} />
                {getBoolean(remoteConfig, POSTS_IMPORT_ENABLED) && (
                  <TextButton className={classes.Button} onClick={handleImportPostsClick}>
                    Import posts
                  </TextButton>
                )}
              </div>
              {posts?.length ? (
                <TableWrapper>
                  <TableHeader className={classes.Header}>
                    <div className={classes.Post}>
                      <span>Post</span>
                    </div>
                    <div className={classes.OriginAccount}>
                      <span>Origin Account</span>
                    </div>
                    <div className={classes.PublishDate}>
                      <span>Publication Date</span>
                    </div>
                    <div className={classes.Comments}>
                      <span>Comments</span>
                    </div>
                    <div className={classes.Likes}>
                      <span>Likes</span>
                    </div>
                  </TableHeader>
                  {/*ITEMS*/}
                  {posts?.map((post, index, array) => (
                    <PostTableItem
                      key={post.id}
                      post={post}
                      handlePostItemClick={handlePostItemClick}
                      handleDropdownClick={handleDropdownClick}
                      handleMoreItemDeleteClick={handleMoreItemDeleteClick}
                      handleMoreItemEditClick={handleMoreItemEditClick}
                      handleMoreItemPublishNowClick={handleMoreItemPublishNowClick}
                      handleMoreItemStatsClick={handleMoreItemStatsClick}
                    />
                  ))}
                  {fetching && <Preloader className={spinnerClasses.paginationSpinnerSize} />}
                  {/*ITEMS*/}
                </TableWrapper>
              ) : (
                <div className={classes.NoPostsContainer}>
                  <SyncIcon />
                  <p>Help them to help you.</p>
                  <Button onClick={handleNewPostButtonClick}>New Post</Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PostsContainer
