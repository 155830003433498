import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTags } from '../../api/api'

const initialState = {
	tags: [],
	nextToken: '',
	fetching: false,
}

export const fetchTags = createAsyncThunk('tags/fetchTags', async (payload) => {
	const response = await getTags(payload)
	return response.data
})

export const fetchTagsByNextToken = createAsyncThunk('tags/fetchTagsByNextToken', async (payload) => {
	const response = await getTags({
		nextToken: payload?.nextToken,
	})
	return response.data
})

const tagsSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		setFetching: (state, action) => {
			state.fetching = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchTags.fulfilled, (state, action) => {
			state.tags = action.payload.tags || []
			state.nextToken = action.payload.nextToken
		})
		builder.addCase(fetchTagsByNextToken.fulfilled, (state, action) => {
			state.tags.push(...action.payload.tags)
			state.nextToken = action.payload.nextToken
			state.fetching = false
		})
	},
})

export default tagsSlice.reducer

export const { setFetching } = tagsSlice.actions

export const selectTags = (state) => state.tags.tags
export const selectNextToken = (state) => state.tags.nextToken
export const selectFetching = (state) => state.tags.fetching
