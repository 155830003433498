import React from 'react'
import Map from '../Components/Map/Map'
import classes from './EditBusiness.module.scss'
import CheckboxSelectedIcon from '../../../submodules/naoo-web-components/Shared/UI/assets/CheckBoxSelected.svg'
import CheckboxNotSelectedIcon from '../../../submodules/naoo-web-components/Shared/UI/assets/CheckBoxNotSelected.svg'

const Location = ({ business, errors, handleAddressChange, handleClickIntoMap, isOnline, handleCheckBoxClick }) => {
  return (
    <div className={classes.Section}>
      <span className={classes.SubTitle}>Location</span>
      <div className={classes.CheckboxContainer} onClick={handleCheckBoxClick}>
        <img src={isOnline ? CheckboxSelectedIcon : CheckboxNotSelectedIcon} alt='' />
        <span className={classes.CheckboxLabel}>Online only</span>
      </div>
      {!isOnline && (
        <>
          <div className={classes.NameContainer}>
            <input
              className={`${classes.Input} ${errors.address && classes.InputError}`}
              name={'address'}
              type='text'
              placeholder={'Enter your address'}
              value={business.address}
              onChange={handleAddressChange}
            />
          </div>
          <div className={classes.MapContainer}>
            {business.coordinates ? (
              <Map
                lng={business?.coordinates[0]}
                lat={business?.coordinates[1]}
                defaultZoom={15}
                onClick={handleClickIntoMap}
              />
            ) : (
              <Map onClick={handleClickIntoMap} />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Location
