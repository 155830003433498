import React from 'react'

import style from './SubscriptionCard.module.scss'
import { Button, BUTTON_TYPES } from '../../../../submodules/naoo-web-components/Components/Button/Buttons'

const SubscriptionCard = ({ subscription, disabled, onSelect, selected, onUnsubscribe }) => {
  const {
    name,
    offers,
    pricePerMonth,
    pricePerDay,
    postsPerWeek,
    postsPerDay,
    color,
    profileInSearch,
    entryOnMap,
    ads,
    isFree,
  } = subscription

  return (
    <div className={style.Container}>
      <h2 style={{ color }}>{name}</h2>
      <h3>{pricePerDay ? `CHF ${pricePerDay}/day` : 'FREE'}</h3>
      <h4>{pricePerMonth && `(billed CHF ${pricePerMonth} monthly)`}</h4>
      <ul>
        <li>
          <span>Profile in Search: </span>
          <span>{profileInSearch ? 'yes' : 'false'}</span>
        </li>
        <li>
          <span>Entry on Map: </span>
          <span>{entryOnMap ? 'yes' : 'false'}</span>
        </li>
        <li>
          <span>Posts: </span>
          <span>
            {postsPerDay && `${postsPerDay} per day`}
            {postsPerWeek && `${postsPerWeek} per week`}
          </span>
        </li>
        <li>
          <span>Offers: </span>
          <span>{offers}</span>
        </li>
        <li>
          <span>Ads in the naoo feed: </span>
          <span>{ads ? `${ads} guaranteed Impressions / day` : '-'}</span>
        </li>
      </ul>
      <Button disabled={disabled || selected} onClick={onSelect}>
        {selected ? 'Subscribed' : 'Select'}
      </Button>
      {selected && !isFree && (
        <div className={style.Unsubscribe}>
          <Button variant={BUTTON_TYPES.TEXT} onClick={onUnsubscribe}>
            Unsubscribe
          </Button>
        </div>
      )}
    </div>
  )
}

export default SubscriptionCard
