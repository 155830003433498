import { v4 as uuidv4 } from 'uuid';
import AWS from "aws-sdk";
import ApiClient, {COGNITO_VARIABLES} from "../submodules/naoo-web-components/Api/client";
import {AWS_REGION, FIREHOSE_VARIABLES} from "../envVariables";

class FirehoseClientApi {
    constructor() {
        const apiClient = new ApiClient()
        const user = apiClient.getCurrentUser()
        AWS.config.region = AWS_REGION
        this.client = new AWS.Firehose({
            region: AWS_REGION,
        })

        if (user) {
            this.checkOrUpdateCredentials()
        }
    }

    checkOrUpdateCredentials = async () => {
        const apiClient = new ApiClient()
        const user = apiClient.getCurrentUser()
        await user.getSession((err,session)=>{
            if (err) return
            if (session.getIdToken().getExpiration() < Date.now()){
                user.refreshSession(session.getRefreshToken(),(err, result)=>{
                    const loginKey = `cognito-idp.${AWS_REGION}.amazonaws.com/${COGNITO_VARIABLES.COGNITO_USERPOOL_ID}`
                    const loginsMap = {}
                    loginsMap[loginKey] = result.getIdToken().getJwtToken()
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: COGNITO_VARIABLES.COGNITO_IDENTITY_POOL_ID,
                        Logins: loginsMap
                    })

                    this.client = new AWS.Firehose()
                    this.userId = apiClient.getCurrentUser().getUsername()
                })
            }
        })
    }

    resetCredentials = () => {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: COGNITO_VARIABLES.COGNITO_IDENTITY_POOL_ID,
        })

        this.client = new AWS.Firehose()
        this.setUserId(null)
    }

    setUserId = (userId) => {
        this.userId = userId
    }

    createRecord = ({eventType, version = "1.0.0"}) => {
        return ({
            version: version,
            eventId: uuidv4(),
            eventType: eventType,
            timestamp: new Date().toISOString(),
            userId: this.userId,
            deviceId: "",
        })
    }

    putRecord = async (record) => {
        await this.checkOrUpdateCredentials()
        //check session
        this.client.putRecordBatch({
            DeliveryStreamName: FIREHOSE_VARIABLES.STREAM_NAME,
            Records:[{
                Data:Buffer.from(JSON.stringify(record))
            }]
        },
            (err, data)=>{
                if (err) console.log(err, err.stack); // an error occurred
                else     console.log(data);           // successful response
            })
    }
}

export default FirehoseClientApi


export const firehoseClient = new FirehoseClientApi()
