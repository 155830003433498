import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { MEDIA_BREAKPOINTS, ROUTES } from '../submodules/naoo-web-components/Shared/constants'
import EditBusiness from '../app/main/Business/EditBusiness'
import PostsContainer from '../app/main/Posts/PostsContainer'
import Offers from '../app/main/Offers/Offers'
import Followers from '../app/main/Followers/Followers'
import CreateBusiness from '../app/main/Business/CreateBusiness'
import Logout from '../submodules/naoo-web-components/Auth/Logout'
import React, { useContext } from 'react'
import { PrivateWrapper } from './PrivateWrapper'
import Auth from '../submodules/naoo-web-components/Auth/Auth'
import logo from '../app/shared/UI/assets/logo-new.svg'
import mobileLogo from '../app/shared/UI/assets/logo-new-mobile.svg'
import SignUp from '../submodules/naoo-web-components/Auth/SignUp/SignUp'
import ForgotPassword from '../submodules/naoo-web-components/Auth/ForgotPassword'
import { firehoseClient } from '../api/firehoseClient'
import { AnalyticsContext } from '../submodules/naoo-web-components/Shared/context/analyticsContext'
import PostWithComments from '../app/main/Posts/PostWithComments/PostWithComments'
import PostStats from '../submodules/naoo-web-components/Posts/PostStats/PostStats'
import QrCode from '../submodules/naoo-web-components/Posts/QrCode/QrCode'
import GoogleSheetModal from '../app/main/Posts/GoogleSheetModal/GoogleSheetModal'
import ImportFromCsvModal from '../app/main/Posts/ImportFromCsvModal/ImportFromCsvModal'
import CreateOrUpdatePost from '../app/main/Posts/Post/CreateOrUpdatePost'
import UpdatePostContainer from '../app/main/Posts/Post/UpdatePostContainer'
import OfferWithComments from '../app/main/Offers/OfferWithComments/OfferWithComments'
import EditOffer from '../app/main/Offers/EditOffer/EditOffer'
import { setMutableOffer } from '../store/reducers/editOfferSlice'
import CreateOffer from '../app/main/Offers/CreateOffer/CreateOffer'
import { discardedNewOffer } from '../store/reducers/newOfferSlice'
import { getOfferStatsThunk, selectOfferStats, updatedDashboardIsObsolete } from '../store/reducers/dashboardSlice'
import { useDispatch, useSelector } from 'react-redux'
import { postAnalyticsEvents } from '../app/main/Posts/postAnalyticsEvents'
import useMediaQuery from '../submodules/naoo-web-components/Shared/hooks/useMediaQueries'
import { mediaBreakpointDown } from '../submodules/naoo-web-components/Shared/utility/utils'
import OfferStats from '../submodules/naoo-web-components/Offer/OfferStats/OfferStats'
import {
  MODULE_FOLLOWERS_ENABLED,
  MODULE_OFFERS_ENABLED,
  MODULE_POSTS_ENABLED,
  MODULE_SUBSCRIPTIONS_ENABLED,
  POSTS_IMPORT_ENABLED,
  RemoteConfigContext,
} from '../app/shared/contexts/remote-config.context'
import { getBoolean } from 'firebase/remote-config'
import SubscriptionContainer from '../app/main/Subscription/SubscriptionContainer'

const handleSetCredentials = () => {
  firehoseClient.checkOrUpdateCredentials()
}

export const useProjectRoutes = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const remoteConfig = useContext(RemoteConfigContext)

  const matches = useMediaQuery(mediaBreakpointDown(MEDIA_BREAKPOINTS.SM))

  const handleDiscardCreatingOffer = () => {
    dispatch(discardedNewOffer())
    navigate(-1)
  }

  const handleOnOfferCreated = () => {
    dispatch(updatedDashboardIsObsolete({ isObsolete: true }))
    dispatch(discardedNewOffer())
    navigate(-1)
  }

  const handleDiscardEditingOffer = () => {
    dispatch(setMutableOffer({}))
    navigate(-1)
  }

  const { offer, stats } = useSelector(selectOfferStats)

  return (
    <Routes>
      <Route path='/' element={<PrivateWrapper />}>
        {/*<Route path={ROUTES.BUSINESS_DASHBOARD.DASHBOARD} element={<DashboardContainer />} />*/}
        <Route path={ROUTES.BUSINESS_DASHBOARD.BUSINESS} element={<EditBusiness />} />
        {getBoolean(remoteConfig, MODULE_POSTS_ENABLED) && (
          <Route path={ROUTES.ADMIN_DASHBOARD.POSTS} element={<PostsContainer />}>
            <Route path={'create'} element={<CreateOrUpdatePost />} />
            <Route path={':id/update'} element={<UpdatePostContainer />} />
            <Route
              path={':id/comments'}
              element={
                <AnalyticsContext.Provider value={postAnalyticsEvents}>
                  <PostWithComments
                    onClose={() => {
                      navigate(-1)
                    }}
                  />
                </AnalyticsContext.Provider>
              }
            />
            <Route
              path={':id/stats'}
              element={
                <PostStats
                  onClose={() => {
                    navigate(-1)
                  }}
                />
              }
            />
            <Route path={':id/qr'} element={<QrCode />} />
            {getBoolean(remoteConfig, POSTS_IMPORT_ENABLED) && (
              <>
                <Route path={'syncGoogleSheet'} element={<GoogleSheetModal />} />
                <Route path={'importFromCsv'} element={<ImportFromCsvModal />} />
              </>
            )}
          </Route>
        )}
        {getBoolean(remoteConfig, MODULE_SUBSCRIPTIONS_ENABLED) && (
          <Route path={ROUTES.BUSINESS_DASHBOARD.SUBSCRIPTION} element={<SubscriptionContainer />} />
        )}
        {getBoolean(remoteConfig, MODULE_OFFERS_ENABLED) && (
          <Route path={ROUTES.ADMIN_DASHBOARD.OFFERS} element={<Offers />}>
            <Route path={`:id/comments`} element={<OfferWithComments onClose={() => navigate(-1)} />} />
            <Route path={`:id/qr`} element={<QrCode />} />
            <Route
              path={`:id/stats`}
              element={
                <OfferStats
                  onClose={() => navigate(-1)}
                  offer={offer}
                  getOfferStatsThunk={getOfferStatsThunk}
                  stats={stats}
                />
              }
            />
            <Route path={`:id/update`} element={<EditOffer discardOfferClicked={handleDiscardEditingOffer} />} />
            <Route
              path={'create'}
              element={
                <CreateOffer discardOfferClicked={handleDiscardCreatingOffer} onOfferCreated={handleOnOfferCreated} />
              }
            />
          </Route>
        )}
        {getBoolean(remoteConfig, MODULE_FOLLOWERS_ENABLED) && <Route path={'/followers'} element={<Followers />} />}
        <Route path={ROUTES.BUSINESS_DASHBOARD.CREATE_BUSINESS} element={<CreateBusiness />} />
        {/*{getBoolean(remoteConfig, MODULE_CHAT_ENABLED) && (*/}
        {/*  <Route path={'/chat'} element={<Chat userId={user?.getUsername()} />} />*/}
        {/*)}*/}
        <Route path={ROUTES.AUTH.LOGOUT} element={<Logout />} />
        <Route path={'*'} element={<Navigate to={ROUTES.ADMIN_DASHBOARD.POSTS} />} />
      </Route>
      <Route
        path={ROUTES.AUTH.AUTH}
        element={
          <Auth
            logo={matches ? mobileLogo : logo}
            setCredentials={handleSetCredentials}
            isDisabledLoginByPhone={true}
          />
        }
      />
      <Route
        path={ROUTES.AUTH.SIGN_UP}
        element={<SignUp logo={matches ? mobileLogo : logo} isDisabledRegisterByPhone={true} />}
      />
      <Route
        path={ROUTES.AUTH.FORGOT_PASSWORD}
        element={<ForgotPassword logo={matches ? mobileLogo : logo} isDisabledLoginByPhone={true} />}
      />
      <Route path={'*'} element={<Navigate to={ROUTES.BUSINESS_DASHBOARD.POSTS} />} />
    </Routes>
  )
}
