import React, {useState} from 'react';
import classes from './ImgAndButtons.module.scss'
import {ReactComponent as Like} from "../../../../../submodules/naoo-web-components/Shared/UI/assets/like.svg";
import {ReactComponent as CommentIcon} from "../../../../../submodules/naoo-web-components/Shared/UI/assets/comment_Icon.svg";
import {ReactComponent as QRIcon} from "../../../../../submodules/naoo-web-components/Shared/UI/assets/qr.svg";
import {ReactComponent as ShareIcon} from "../../../../../submodules/naoo-web-components/Shared/UI/assets/shared.svg";
import {BUTTON_TYPES, Button} from "../../../../../submodules/naoo-web-components/Components/Button/Buttons";
import {ReactComponent as Check} from "../../../../../submodules/naoo-web-components/Shared/UI/assets/check.svg";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../../submodules/naoo-web-components/Shared/constants";
import {createDeepLink, creteOfferLikeThunk, deleteOfferLikeThunk} from "../../../../../store/reducers/offerReducer";
import {withAnalytics} from "../../../../shared/utility/withAnalytics";
import {Preloader} from '../../../../../submodules/naoo-web-components/Components/Preloader/Preloader'

const ImgAndButtons = ({offer}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isCopy, setIsCopy] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleLikeIconClick = withAnalytics(
        'dashboards.business.offer.like_btn_clicked',
        () => {
            if (offer?.userLiked) {
                dispatch(deleteOfferLikeThunk(offer.id))
            } else {
                dispatch(creteOfferLikeThunk(offer.id))
            }
        })

    const handleCommentIconClick = withAnalytics(
        'dashboards.business.offer.post_comment_btn_clicked',
    )


    const handleQrCodeClick =withAnalytics(
        'dashboards.business.offer.show_qr_code_btn_clicked',
        () => {
            navigate(`${ROUTES.ADMIN_DASHBOARD.OFFERS}/${offer.id}/qr`)
        })


    const handleCopyDeepLinkClick = withAnalytics(
        'dashboards.business.offer.copy_link_btn_clicked',
        () => {
            setIsLoading(true)
            dispatch(createDeepLink({
                offerId: offer.id
            }))
                .then(result => {
                    return navigator.clipboard.writeText(result.payload.shortLink)
                })
                .then(() => {
                    setIsCopy(true)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        })

    return (
        <div className={classes.ImgPlusButtons}>
            <img className={classes.Img}
                 src={offer?.media?.[0]?.url}
                 alt=""/>
            <div className={classes.Buttons}>
                <div className={classes.SocialButtons}>
                    <Like
                        className={offer.userLiked ? classes.LikeActive : null}
                        width={20}
                        height={20}
                        onClick={handleLikeIconClick}
                    /> <span>{offer?.likesCount}</span>
                    <CommentIcon width={20} height={20} onClick={handleCommentIconClick}/>
                    <span>{offer?.commentsCount}</span>
                    <QRIcon
                        width={20}
                        height={20}
                        onClick={handleQrCodeClick}
                    />
                    <ShareIcon width={20} height={20}/>
                </div>
                <Button
                    variant={BUTTON_TYPES.NOT_ROUNDED}
                    className={classes.CopyButton}
                    onClick={handleCopyDeepLinkClick}
                >
                    {
                        isLoading
                            ? <Preloader className={classes.LoadingIndicator}/>
                            : isCopy
                                ? <><Check height={18}/> LINK COPIED</>
                                : 'COPY LINK '
                    }
                </Button>
            </div>
        </div>
    );
};

export default ImgAndButtons;