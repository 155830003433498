import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearBusiness, fetchCategories, selectBusiness, selectCategories } from '../../../store/reducers/businessSlice'
import Step1 from './Forms/Step1'
import Step2 from './Forms/Step2'
import Step3 from './Forms/Step3'
import Step4 from './Forms/Step4'
import logo from '../../../submodules/naoo-web-components/Shared/UI/assets/naoo-business-logo.svg'
import mobileLogo from '../../shared/UI/assets/naooMobileLogo.svg'
import classes from './CreateBusines.module.scss'
import { ReactComponent as TimesIcon } from '../../../submodules/naoo-web-components/Shared/UI/assets/closeIcon.svg'
import CoverStep from './Forms/CoverStep'
import AboutStep from './Forms/AboutStep'
import { selectCurrentBusiness } from '../../../store/reducers/managedBusinessesSlice'
import GalleryStep from './Forms/GalleryStep'
import { MEDIA_BREAKPOINTS, ROUTES } from '../../../submodules/naoo-web-components/Shared/constants'
import useMediaQuery from '../../../submodules/naoo-web-components/Shared/hooks/useMediaQueries'
import { mediaBreakpointDown } from '../../../submodules/naoo-web-components/Shared/utility/utils'
import ModalWindow from '../../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow'

export const CREATE_BUSINESS_STEP_NAMES = {
  NAME: 'NAME',
  CONTACTS: 'CONTACTS',
  LOGO: 'LOGO',
  COVER: 'coverStep',
  ABOUT: 'ABOUT',
  LOCATION: 'LOCATION',
  GALLERY: 'GALLERY',
}

const CreateBusiness = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentBusiness = useSelector((state) => selectCurrentBusiness(state))
  const business = useSelector((state) => selectBusiness(state))
  const categories = useSelector((state) => selectCategories(state))

  const [isShowConfirm, setIsShowConfirm] = useState(false)

  useEffect(() => {
    dispatch(fetchCategories())
  }, [dispatch])

  const [step, setStep] = useState(CREATE_BUSINESS_STEP_NAMES.NAME)

  const matches = useMediaQuery(mediaBreakpointDown(MEDIA_BREAKPOINTS.SM))

  let form, title
  switch (step) {
    case CREATE_BUSINESS_STEP_NAMES.NAME:
      form = <Step1 business={business} categories={categories} setStep={setStep} />
      title = 'Let’s get to know your business'
      break
    case CREATE_BUSINESS_STEP_NAMES.CONTACTS:
      form = <Step2 business={business} setStep={setStep} />
      title = 'Add your contacts'
      break
    case CREATE_BUSINESS_STEP_NAMES.LOGO:
      form = <Step3 logo={business.logo} setStep={setStep} />
      title = 'Add your logo'
      break
    case CREATE_BUSINESS_STEP_NAMES.LOCATION:
      form = <Step4 business={business} setStep={setStep} />
      title = 'Add your location'
      break
    case CREATE_BUSINESS_STEP_NAMES.COVER:
      form = <CoverStep cover={business.cover} setStep={setStep} />
      title = 'Add profile cover'
      break
    case CREATE_BUSINESS_STEP_NAMES.ABOUT:
      form = <AboutStep business={business} setStep={setStep} />
      title = 'What is your business about? '
      break
    case CREATE_BUSINESS_STEP_NAMES.GALLERY:
      form = <GalleryStep business={business} setStep={setStep} />
      title = 'Add pictures of your business'
      break
    default:
      form = <Step1 business={business} categories={categories} setStep={setStep} />
  }

  return (
    <>
      <img src={matches ? mobileLogo : logo} alt='' className={classes.NaooBusinessLogo} />
      <div className={classes.FormContainer}>
        {currentBusiness && (
          <TimesIcon width={17} height={17} onClick={() => setIsShowConfirm(true)} className={classes.CloseIcon} />
        )}
        <span className={classes.FormTitle}>{title}</span>
        {form}
      </div>

      <ModalWindow
        isOpen={isShowConfirm}
        onClose={() => setIsShowConfirm(false)}
        title={'Are You Sure?'}
        primaryButton={'Discard changes and leave page'}
        handlePrimaryClick={() => {
          dispatch(clearBusiness())
          navigate(ROUTES.MAIN, { replace: true })
        }}
        isOutsideClosed
      />
    </>
  )
}

export default CreateBusiness
